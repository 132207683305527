import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SupportService } from 'src/app/services/support-tickets.service';

@Component({
  selector: 'app-pending-tickets',
  templateUrl: './pending-tickets.component.html',
  styleUrls: ['./pending-tickets.component.sass']
})
export class PendingTicketsComponent implements OnInit {
  tickets: any[] = [];
  selectedTicket: any = null;

  currentPage: number = 1;
  totalTickets: number = 0;
  ticketsPerPage: number = 6;
  totalPages: number = 0;
  userId: number | null = null;

  constructor(private supportService: SupportService, private authService: AuthService) {}

  ngOnInit(): void {
    this.loadUserId();
  }

  private loadUserId(): void {
    const user = this.authService.getCurrentUser(); 
    if (user && user.id) {
      this.userId = user.id;
      this.loadPendingTickets(); 
    } else {
      console.error('Erro: Usuário não está logado ou ID não encontrado.');
    }
  }

  private loadPendingTickets(): void {
    if (this.userId) {
      this.supportService
        .getPendingTickets(this.userId, this.currentPage, this.ticketsPerPage)
        .subscribe({
          next: (response) => {
            this.tickets = response.content || []; 
            this.totalTickets = response.totalElements || 0; 
            this.totalPages = Math.ceil(this.totalTickets / this.ticketsPerPage);
 
            this.supportService.populateTicketSubjects(this.tickets);
          },
          error: (error) => {
            console.error('Erro ao carregar os tickets finalizados:', error);
          },
        });
    }
  }

  openTicket(ticket: any): void {
    this.selectedTicket = ticket;
  }

  closeModal(): void {
    this.selectedTicket = null;
  }

  getStatusLabel(status: string): string {
    const statusMap: { [key: string]: string } = {
      pending: 'Pendente',
    };
    return statusMap[status] || 'Desconhecido';
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadPendingTickets();
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadPendingTickets();
    }
  }

  onPageChange(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.loadPendingTickets();
    } else if (newPage < 1) {
      this.currentPage = 1;
    } else if (newPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
  }
}
