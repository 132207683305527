import { Component, Input, OnInit } from '@angular/core';
import { INotification } from 'src/app/interfaces';

@Component({
  selector: 'app-notification-line',
  templateUrl: './notification-line.component.html',
  styleUrls: []
})
export class NotificationLineComponent implements OnInit {

  @Input() notification: INotification;

  constructor() { }

  treatHour(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHour = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedHour;
  }
 
  ngOnInit(): void {
  }

}
