import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import { AnalyticalYearToDate } from 'src/app/interfaces/dtos/AnalyticalYearToDate';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { environment } from 'src/environment';

@Component({
  selector: 'app-year-to-date-vision',
  templateUrl: './year-to-date-vision.component.html',
  styleUrls: ['./year-to-date-vision.component.sass']
})
export class YearToDateVisionComponent implements OnInit {

  NAMESPACE: Namespace = 'analytical';
  data: BehaviorSubject<AnalyticalYearToDate.Output> = new BehaviorSubject(new AnalyticalYearToDate.Output);
  loading: boolean = true;
  error: boolean = false;
  onChange: any;
  primaryColor: string = '#ff0000';
  secondaryColor: string = '#000000';

  constructor(
    private filterService: FilterService,
    private httpService: HttpClient
  ) {
    
    this.filterService.getFiltersObservable<AnalyticalYearToDate.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((af) => this.updateData(af));
  }

  ngOnInit(): void {
  }

  updateData(activeFilters) {
    this.loading = true;
    const baseUrl = environment.baseUrl;
    const mappedFilters = {
      "years": activeFilters.year,
      "months": activeFilters.month,
      "days": activeFilters.day,
      "causes": activeFilters.causeGroup,
      "regionals": activeFilters.regional,
      "directors": activeFilters.group,
      "states": activeFilters.state,
      "subClusters": activeFilters.subcluster,
      "clusters": activeFilters.cluster,
      "cities": activeFilters.city,
      "families": activeFilters.family,
      "nets": activeFilters.net
    }
    this.httpService
      .post<AnalyticalYearToDate.Response>(`${baseUrl}/vandalism/analytical/year-to-date`, mappedFilters)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        this.loading = false;
        this.data.next(new AnalyticalYearToDate.Output(response))
      })
  }
}
