import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePasswordErrorPopUpComponent } from './components/create-password-error-pop-up/create-password-error-pop-up.component';
import { UserService } from 'src/app/services/user.service';
import { CreatePasswordSuccessPopUpComponent } from './components/create-password-success-pop-up/create-password-success-pop-up.component';
import { caseValidator, numberValidator, specialCharacterValidator } from 'src/app/utils/inputValidators';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: []
})
export class CreatePasswordComponent implements OnInit {

  form: FormGroup;
  loading: boolean = true;
  submitted: boolean;
  error: boolean = false;

  showNewPassword: boolean = false;
  newPassword: string = '';
  showConfirmationPassword: boolean = false;
  confirmationPassword: string = '';
  userId: number;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        caseValidator,
        specialCharacterValidator,
        numberValidator,
        Validators.minLength(8),
      ])),
      confirmationPassword: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit(): void {
    const state = history.state;
    if (state?.userId) {
      this.userId = state.userId;
    }
  }

  toggleShowPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmationPassword() {
    this.showConfirmationPassword = !this.showConfirmationPassword;
  }

  passwordsMatchValidator(control: AbstractControl) {
    const newPassword = control.get('newPassword').value;
    const confirmationPassword = control.get('confirmationPassword').value;
    if (newPassword !== confirmationPassword) {
      return true
    }
    return false
  }

  getUsername() {
    return this.form.controls.username.value
  }

  getNewPassword() {
    return this.form.controls.newPassword.value
  }

  getConfirmationPassword() {
    return this.form.controls.confirmationPassword.value
  }

  openSuccessPopUp() {
    this.modalService.open(CreatePasswordSuccessPopUpComponent, {
      windowClass: 'test'
    });
  }

  openErrorPopUp() {
    this.modalService.open(CreatePasswordErrorPopUpComponent, {
      windowClass: 'test'
    });
  }

  onSubmit(e): void {
    e.preventDefault();
  
    this.submitted = true;

    if (this.form.invalid) {
      this.error = true;
      return;
    }

    this.loading = true;

    this.userService.createPassword({
      userId: this.userId,
      password: this.getNewPassword(),
    }).subscribe(
        (data) => {
          this.error = false;
          this.loading = false;
          this.openSuccessPopUp();
          this.router.navigate(['/login']);
        },
        (error) => {
          this.error = true;
          this.loading = false;
          this.openErrorPopUp()
        });
  }
}
