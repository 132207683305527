import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpStatusCode,
} from "@angular/common/http";
import { AuthService } from '../services/auth.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from "rxjs";
import { catchError, filter, take, switchMap, finalize, shareReplay } from "rxjs/operators";
import { IToken } from "../interfaces";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private refreshTokenInProgress: Observable<IToken> | null = null;

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
  
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = this.authService.refreshToken().pipe(
          finalize(() => {
            this.isRefreshing = false;
            this.refreshTokenInProgress = null;
          }),
          shareReplay(1)
        );
      }
  
      return this.refreshTokenInProgress.pipe(
        switchMap((tokens: IToken) => {
          if (tokens && tokens.accessToken) {
            const { accessToken } = tokens;
            this.refreshTokenSubject.next(accessToken);
            return next.handle(this.addToken(request, accessToken));
          } else {
            this.authService.logout();
            return EMPTY;
          }
        }),
        catchError((error) => {
          this.authService.logout();
          return EMPTY;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};