import { AgmMap, MapTypeStyle } from "@agm/core";
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Marker, Route } from "./types/preventive.type";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import {
  InputPreventive,
  OutputPreventive,
  PreventiveService,
} from "src/app/services/preventive.service";
import { BehaviorSubject, Subject } from "rxjs";
import { FilterService, Namespace } from "src/app/services/filter.service";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { slideInOutAnimation } from "src/app/animations/animations";
import { trigger, transition, style, animate, state } from "@angular/animations";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";

const initialState: OutputPreventive = {
  filters: {
    families: [],
    classification: [],
    states: [],
    clusters: [],
    type: [],
    routeIds: [],
    stationIds: []
  },
  markers: [],
  routes: [],
  segments: []
};

interface RoutePolyline {
  routeId: string;
  polyline: google.maps.Polyline;
}

// Define as interfaces para os nós da árvore
interface TreeNode {
  name: string;
  children?: TreeNode[];
  route?: Route;
  count?: number; // Contar rotas filhas
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  route?: Route;
  count?: number;
}

export const defaultActiveFilters: InputPreventive = {
  families: "",
  classifications: "",
  states: "SP",
  clusters: "",
  routeIds: "",
  stationIds: "",
  segments: ""
};

export interface DropdownFilters {
  families: string[];
  classifications: string[];
  states: string[];
  regionals: string[];
  directors: string[];
  segmentTypes: string[];
  clusters: string[];
  types: string[];
  routeIds: string[];
  stationIds: string[];
  segments: string[];
  routeTypes: string[],
  stationTypes: string[],
  ownerships: string[],
  maintenances: string[]
}

@Component({
  selector: "app-preventive-map",
  templateUrl: "./preventive-map.component.html",
  styles: [],
  animations: [
    slideInOutAnimation,
    trigger("fadeInOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("250ms ease-in", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate("250ms ease-out", style({ opacity: 0 }))]),
    ]),
    trigger('dropdownAnimation', [
      state('closed', style({
        maxHeight: '0px',
        opacity: 0,
        transform: 'translateY(10px)',
        visibility: 'hidden'
      })),
      state('open', style({
        maxHeight: '400px',
        opacity: 1,
        transform: 'translateY(0)',
        visibility: 'visible'
      })),
      transition('closed => open', [
        animate('300ms ease-in-out')
      ]),
      transition('open => closed', [
        animate('200ms ease-in-out')
      ])
    ])
  ],  
})
export class PreventiveMapComponent implements OnInit {
  
  private static ANALITICO_URL = "/home";
  private NAMESPACE: Namespace = "preventive";
  private currentMapInstance: google.maps.Map | null = null;
  private mapMarkers: google.maps.Marker[] = [];
  private routePolylines: RoutePolyline[] = [];
  private unsubscribe$ = new Subject<void>();
  private mapRoutes: google.maps.Polyline[] = [];

  data: BehaviorSubject<OutputPreventive> = new BehaviorSubject(initialState);
  @Input() activeFilters: BehaviorSubject<InputPreventive> = new BehaviorSubject(defaultActiveFilters);
  @Input() returnUrl: string = null;

  dropdownFilters: BehaviorSubject<DropdownFilters> =
    new BehaviorSubject<DropdownFilters>({
      families: [],
      classifications: [],
      states: [],
      clusters: [],
      types: [],
      routeIds: [],
      stationIds: [],
      segments: [],
      regionals: [],
      directors: [],
      segmentTypes: [],
      routeTypes: [],
      stationTypes: [],
      ownerships: [],
      maintenances: []
    });
  legendItems: BehaviorSubject<{ name: string; icon: string }[]> =
    new BehaviorSubject([]);

  // activeFilters = {};
  isDivVisible: boolean = false;
  iconBackHome = "/assets/icons/back-home.png";
  iconBackHomeWhite = "/assets/icons/back-home-white.png";
  iconPlus = "/assets/icons/plus.png";
  iconRoute = "/assets/icons/rota.png";
  iconStation = "/assets/icons/marker.png";

  // Coordenadas Selecionadas
  startPoint: google.maps.LatLngLiteral | null = null;
  endPoint: google.maps.LatLngLiteral | null = null;
  mode: 'station' | 'route' = 'station';

  // Trecho Selecionado para InfoBox
  selectedSection: { title: string; details: { key: string; value: string }[] } = {
    title: '',
    details: []
  };
  selectedRoute: Route | null = null;
  isDropdownOpen:boolean = false;

  // Controle de Modo Editor
  isEditorEnabled = false; // Desabilitado por padrão
  iconMap = {
    backboneDefault: "/assets/maps/backbone.png",
    backboneBronze: "/assets/maps/backbone-bronze.png",
    backbonePrata: "/assets/maps/backbone-prata.png",
    backboneOuro: "/assets/maps/backbone-ouro.png",
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    (node: TreeNode, level: number) => ({
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      route: node.route,
    }),
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  isLeaf = (_: number, node: FlatNode) => !node.expandable;
  isExpandable = (_: number, node: FlatNode) => node.expandable && !node.route;

  @Input() mapConfig = {
    zoom: 8,
    maxZoom: 19,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
    optimized: false,
    center: { lat: -23.55052, lng: -46.633308 }, // Centro de São Paulo
    mapTypeControl: false,
    styles: <MapTypeStyle[]>[ // Define como MapTypeStyle[]
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'simplified' }]
      }
    ]
  };

  loading: boolean = true;
  error: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private preventiveService: PreventiveService,
    private filterService: FilterService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.filterService.setDefaultFilters({
      namespace: this.NAMESPACE,
      defaultFilters: initialState,
    });

    this.filterService
      .getFiltersObservable({
        namespace: this.NAMESPACE,
      })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.fetchData(activeFilters);
      });
  }

  ngOnInit(): void {}

  // Fecha o Modal
  closeModal() {
    this.activeModal.close();
    if (this.returnUrl !== null) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate([PreventiveMapComponent.ANALITICO_URL]);
    }
  }

  onMapClosed() {
    this.isEditorEnabled = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // Abre o filter header
  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  // Exibe a InfoBox
  showInfoBox() {
    const infoBox = document.getElementById("infoBox");
    if (infoBox) {
      infoBox.classList.remove("hidden");
    }
  }

  // Fecha a InfoBox
  closeInfoBox() {
    const infoBox = document.getElementById("infoBox");
    if (infoBox) {
      infoBox.classList.add("hidden");
    }
  }

  fetchData(activeFilters: InputPreventive): void {
    this.loading = true;

    this.activeFilters
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        takeUntil(this.unsubscribe$),
        switchMap((filters) => this.preventiveService.getPreventives(filters))
      )
      .subscribe({
        next: (response) => {
          this.data.next(response);

          this.updateLegend(response.markers); // Atualiza a legenda dinamicamente
          this.loading = false;
          this.updateActiveFilters(response.filters);
          this.onMapReady(this.currentMapInstance);
        },
        error: (err) => {
          console.error("Erro ao buscar dados:", err);
          this.error = true;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }  

  mapInstance!: google.maps.Map | null; // Armazena a referência do mapa

  onMapReady(map: google.maps.Map): void {    
    console.log("Mapa pronto:", map);
    
    // Aguarda o primeiro carregamento do mapa antes de processar os dados
    if (map) {
      console.log("Mapa pronto:", map);
      this.mapInstance = map;
      this.cdr.detectChanges(); // Força a atualização do Angular
      this.subscribeToData();
    } else {
      console.warn("onMapReady recebeu um mapa nulo!");
    }
  }
  
  subscribeToData(): void {
    this.data.pipe(takeUntil(this.unsubscribe$)).subscribe((response: OutputPreventive) => {
      if (this.mapInstance) {
        this.clearRoutes(); // Certifica que as rotas são limpas antes
        this.loadRoutes(this.mapInstance, response.routes, response.segments);
        this.loadMarkers(this.mapInstance, response.markers);
      } else {
        console.warn("Mapa inválido ao tentar carregar rotas e marcadores.");
      }
    });
  }

  loadMarkers(map: google.maps.Map, markers: Marker[]): void {
    if (!map || !markers || markers.length === 0) {
      return;
    }

    const iconMap: { [key: string]: string } = {
      "backbone - BRONZE": this.iconMap.backboneBronze,
      "backbone - OURO": this.iconMap.backboneOuro,
      "backbone - PRATA": this.iconMap.backbonePrata,
      "backbone - null": this.iconMap.backboneDefault, // Ícone padrão caso seja null ou não encontrado
    };

    this.clearMarkers();

    const validMarkers = markers.filter(
      (marker) =>
        marker &&
        marker.coordinate &&
        marker.coordinate.lat !== null &&
        marker.coordinate.lng !== null
    );

    validMarkers.forEach((markerData) => {
      let iconKey = markerData.icon?.trim() || "backbone - null";

      // Se o ícone não existir no mapa, usar o ícone padrão
      if (!iconMap[iconKey]) {
        iconKey = "backbone - null";
      }

      const marker = new google.maps.Marker({
        position: {
          lat: markerData.coordinate.lat,
          lng: markerData.coordinate.lng,
        },
        map: map,
        title: markerData.building,
        icon: {
          url: iconMap[iconKey], // Define o ícone correto para o marcador
          scaledSize: new google.maps.Size(20, 20),
        },
      });

      const infoWindow = new google.maps.InfoWindow();
      marker.addListener("click", () => {
        infoWindow.setContent(this.formatMarkerTooltip(markerData));
        infoWindow.open(map, marker);
      });

      this.mapMarkers.push(marker);
    });
  }

  loadRoutes(map: google.maps.Map, routes: Route[], segments: Route[]): void {
    if (!map) {
      console.log('mapa invalido')
      return;
    }

    this.clearRoutes();

    const r = routes === null ? [] : routes;

    [...r, ...segments].forEach((route, index) => {
      const validPoints = route.points
      .map((point) => ({
        lat: point.lat ?? -23.55052, // Padrão: centro de SP
        lng: point.lng ?? -46.633308,
      }))

      if (validPoints.length === 0) {
        return;
      }

      // Mapeia os pontos para LatLng do Google Maps
      const path = validPoints.map(
        (point) => new google.maps.LatLng(point.lat, point.lng)
      );

      // Cria a linha da rota (Polyline)
      const polyline = new google.maps.Polyline({
        path: path,
        geodesic: true,
        strokeColor: route.color,
        strokeOpacity: 1.0,
        zIndex: route.segment ? 510 : 500,
        strokeWeight: route.segment ? 8 : 5,
      });

      polyline.setMap(map);

      // Evento de clique na rota
      polyline.addListener("click", () => {        
        this.routeClick(route);
      });

      // Evento de "mouseover" para exibir informações
      polyline.addListener("mouseover", (event) => {
        // const formattedTitle = this.formatTooltipName(route.tooltip.title);
        const description = this.formatRouteDetails(route.tooltip.description);
        const eventCount = route.tooltip?.value?.[0]?.eventCount || 'N/A';
        const causeGroup = route.tooltip?.value?.[0]?.causeGroup || 'Não Informado';
        const contentString = `
        <div class="tooltip-route">
          <div class="title-tooltip-route">
            <strong>${route.name}</strong>
            <span>Id da Rota: ${route.id}</span>
          </div>          

          <div class="content-tooltip-route">
            <span>Tipo: ${route.tooltip.type}</span>
            <table style="border-collapse: collapse; width: 100%;">
              ${description.map(detail => `
                <tr>
                  <td><strong>${detail.key}:</strong></td>
                  <td>${detail.value}</td>
                </tr>
                `).join('')}
                <tr>
                  <td><strong>Total de Casos:</strong></td>                  
                  <td>${eventCount}</td>                  
                </tr>
                <tr>                  
                  <td><strong>Tipos de Casos:</strong></td>
                  <td>${causeGroup}</td>
                </tr>
         
            </table> 
            <div>
            </div>        
          </div>          
        </div>`

        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
          position: event.latLng,
        });        

        infoWindow.open(map);

        // Adicionar evento de clique ao botão dinamicamente
        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
          const button = document.getElementById("routeClickButton");
          if (button) {
            // button.addEventListener("click", () => {
            //   this.routeClick(route);
            // });
          }
        });
        polyline.addListener("mouseout", () => infoWindow.close());
      });

      this.mapRoutes.push(polyline);
    });
  }
  
  public getFirstRouteCenter(): google.maps.LatLngLiteral | undefined {

    if (this.mapRoutes.length === 0) {
      console.warn('Nenhuma rota disponível para definir o centro. Usando valor padrão.');
      return { lat: -23.55052, lng: -46.633308 }; // Fallback padrão (São Paulo)
    }
  
    // Obter o caminho da primeira rota
    const firstRoute = this.mapRoutes[0];
    const path = firstRoute.getPath();
  
    if (path.getLength() === 0) {
      console.warn('A primeira rota não possui pontos válidos. Usando valor padrão.');
      return { lat: -23.55052, lng: -46.633308 }; // Fallback padrão
    }
  
    // Retorna o primeiro ponto como o centro
    const firstPoint = path.getAt(0);
    return { lat: firstPoint.lat() ?? -23.55052, lng: firstPoint.lng() ?? -46.633308 };
  }

  routeClick(route:Route): void {
    if (!route.tooltip) return;
    
    const formattedTitle = this.cleanTitle(route.tooltip.title);
  
    this.selectedSection = {
      title: formattedTitle,
      details: this.formatRouteDetails(route.tooltip.description)
    };
    this.isEditorEnabled = true;    
  }

  // Função para limpar título removendo prefixo e extensão
  cleanTitle(title: string): string {
    if (!title) return '';
    
    // Remove a parte antes do '>' e a extensão .KML, além de substituir underlines
    return title.split('>')[1]?.trim().replace(/_/g, ' ').replace(/\.kml$/i, '') || title;
  }

  // Formata a descrição da rota removendo as quebras de linha e separando em chave-valor
  formatRouteDetails(description: string): { key: string, value: string }[] {
    if (!description) return [];
  
    return description.split('\n')
      .map(line => {
        const cleanLine = line.replace(/<[^>]*>/g, '').trim(); // Remove tags HTML, se houver
        const [key, value] = cleanLine.split(':');
  
        return key && value ? { key: key.trim(), value: value.trim() } : null;
      })
      .filter(item => item !== null && item.key.toLowerCase() !== 'id'); // Remove linha que contém "ID"
  }
  

  closeRouteInfoBox(): void {
    this.selectedSection = null;
    this.isEditorEnabled = false;
  }

  // Método para criar uma atividade
  createActivity(): void {
    if (!this.selectedRoute) return;

    const activityData = {
      routeId: this.selectedRoute.id,
      // name: this.selectedRoute.name,
      // color: this.selectedRoute.color,
    };

    // this.preventiveService.create(activityData).subscribe({
    //   next: () => {
    //     this.openPopUpResponseReport("Atividade criada com sucesso!", true);
    //     this.closeRouteInfoBox();
    //   },
    //   error: () => {
    //     this.openPopUpResponseReport("Erro ao criar atividade.", false);
    //   },
    // });
  }

  toggleRouteVisibility(
    routeId: string,
    isVisible: boolean,
    map: google.maps.Map
  ): void {
    const routePolyline = this.routePolylines.find(
      (rp) => rp.routeId === routeId
    );

    if (routePolyline) {
      if (isVisible) {
        routePolyline.polyline.setMap(map); // Exibe a rota
      } else {
        routePolyline.polyline.setMap(null); // Oculta a rota
      }
    }
  }

  updateLegend(markers: Marker[]): void {
    // Extrai e filtra as classificações únicas
    const uniqueClassifications = Array.from(
      new Set(markers.map((marker) => marker.icon.split(" - ")[1]))
    );

    // Mapeia para o formato necessário
    const updatedLegend = uniqueClassifications.map((classification) => {
      return {
        name: classification,
        icon:
          markers.find((marker) => marker.icon.includes(`- ${classification}`))
            ?.icon || "",
      };
    });

    // Atualiza a legenda no BehaviorSubject
    this.legendItems.next(updatedLegend);
    // console.log(this.legendItems.value);
  }

  getIconKey(icon: string): string {
    if (!icon || icon.includes("null")) {
      return "backboneDefault";
    }

    const parts = icon.split("-");
    if (parts.length > 1) {
      const iconType = parts[1].trim().toLowerCase();
      if (
        iconType === "ouro" ||
        iconType === "prata" ||
        iconType === "bronze"
      ) {
        return `backbone${
          iconType.charAt(0).toUpperCase() + iconType.slice(1)
        }`;
      }
    }
    return "backboneDefault";
  }

  // toggleRouteVisibility(routeId: string, isVisible: boolean, map: google.maps.Map): void {
  //   const routePolyline = this.routePolylines.find(rp => rp.routeId === routeId);

  //   if (routePolyline) {
  //     if (isVisible) {
  //       routePolyline.polyline.setMap(map); // Exibe a rota
  //     } else {
  //       routePolyline.polyline.setMap(null); // Oculta a rota
  //     }
  //   }
  // }

  // FORMATA O TITLE DO TOOLTIP ROUTE
  formatTooltipName(name: string): string {
    if (!name) return "";
    let formattedName = name.replace(/\.kml$/i, "");
    formattedName = formattedName.replace(/>/g, "-");
    formattedName = formattedName.replace(/_/g, " ");
    const parts = formattedName.split("-");
    if (parts.length >= 2) {
      return `${parts[0].trim()} ${parts[1].trim()}`;
    }

    return formattedName;
  }

  // FORMATA O TITLE DO TOOLTIP MARKER
  formatMarkerTooltip(marker: Marker): string {
    if (!marker || !marker.tooltip) return "<p>Informações indisponíveis</p>";

    const formattedTitle = marker.tooltip.title
      .split(",")
      .map((item) => {
        const parts = item.split(":");
        return `<tr><td><strong>${parts[0].trim()}:</strong></td><td>${
          parts[1]?.trim() || "N/A"
        }</td></tr>`;
      })
      .join("");

    const formattedDescription = marker.tooltip.description
      .split("\n")
      .map(
        (line) => `
        <tr>
          <td class="marker-key">
            <strong>${line.split(":")[0].trim()}:</strong>
          </td>
          <td class="marker-value">
            ${line.split(":")[1]?.trim() || "N/A"}
          </td>
        </tr>`
      )
      .join("");

    return `
      <div class="tooltip-marker">
        <strong class="title-marker">${formattedTitle}</strong>        
        <table style="border-collapse: collapse; width: 100%;">
          ${formattedDescription}
        </table>
        <hr style="border: 0.5px solid #ccc;">
        <p class="marker-value"><strong>Tipo:</strong> ${marker.tooltip.type}</p>
      </div>
    `;
  }

  // Método para abrir notificações
  openPopUpResponseReport(message: string, isSuccess: boolean): void {
    const snackBarSettings: MatSnackBarConfig = {
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: isSuccess ? "success-bar-container" : "failure-bar-container",
      duration: 3000,
    };

    this.snackBar.open(message, "Fechar", snackBarSettings);
  }

  // Método para criar rota e exibir notificações
  // createRoute({
  //   // routeId,
  //   // points,
  // }: {
  //   // routeId: any;
  //   // points: Array<{ lat: number; lng: number }>;
  // }): void {
  //   const routeId = '413214';
  //   this.isEditorEnabled = true;
  //   this.preventiveService.createRoute({ routeId }).subscribe({
  //     next: () => {
  //       this.openPopUpResponseReport("Rota criada com sucesso!", true);
  //     },
  //     error: (err) => {
  //       console.error("Erro ao criar rota:", err);
  //       this.openPopUpResponseReport(
  //         "Erro ao criar rota. Por favor, tente novamente.",
  //         false
  //       );
  //     },
  //   });
  // }

  openCreateRoute(): void {    
    this.isEditorEnabled = true;  
    this.mode = 'route';  
  }

  openCreateStation(): void {    
    this.isEditorEnabled = true;  
    this.mode = 'station';  
  }  

  addNewStation(stationData: any): void {
    // this.preventiveService.creatStation(stationData).subscribe({
    //   next: () => {
    //     alert('Estação criada com sucesso!');
    //     this.onMapClosed(); // Fecha o modal
    //     this.loadMarkers(this.map!, [...this.data.value.markers, {
    //       coordinate: {
    //         lat: stationData.latitude,
    //         lng: stationData.longitude,
    //       },
    //       building: stationData.name,
    //     }]); // Atualiza os marcadores
    //   },
    //   error: (err) => {
    //     console.error('Erro ao criar estação:', err);
    //   }
    // });
  }
  
  addNewRoute(routeData: any): void {
    // this.preventiveService.createRoute(routeData).subscribe({
    //   next: () => {
    //     alert('Rota criada com sucesso!');
    //     this.onMapClosed(); // Fecha o modal
    //     this.loadRoutes(this.map!, [...this.data.value.routes, {
    //       id: Math.random().toString(), // Gerar um ID temporário
    //       points: routeData.points,
    //       name: routeData.name,
    //       tooltip: {
    //         title: routeData.name,
    //         type: 'Nova Rota',
    //       },
    //     }]); // Atualiza as rotas
    //   },
    //   error: (err) => {
    //     console.error('Erro ao criar a rota:', err);
    //   }
    // });
  }

  // Atualiza dropdowns dos filtros
  updateActiveFilters(newFilters: DropdownFilters): void {
    const updatedDropdownFilters: DropdownFilters = {
      families: Array.isArray(newFilters.families) ? newFilters.families : [],
      classifications: Array.isArray(newFilters.classifications) ? newFilters.classifications : [],
      regionals: Array.isArray(newFilters.regionals) ? newFilters.regionals : [],
      directors: Array.isArray(newFilters.directors) ? newFilters.directors : [],
      states: Array.isArray(newFilters.states) ? newFilters.states : [],
      clusters: Array.isArray(newFilters.clusters) ? newFilters.clusters : [],
      types: Array.isArray(newFilters.types) ? newFilters.types : [],
      routeIds: Array.isArray(newFilters.routeIds) ? newFilters.routeIds : [],
      stationIds: Array.isArray(newFilters.stationIds) ? newFilters.stationIds : [],
      segments: Array.isArray(newFilters.segments) ? newFilters.segments : [],
      segmentTypes: Array.isArray(newFilters.segmentTypes) ? newFilters.segmentTypes : [],
      routeTypes: Array.isArray(newFilters.routeTypes) ? newFilters.routeTypes : [],
      stationTypes: Array.isArray(newFilters.stationTypes) ? newFilters.stationTypes : [],
      ownerships: Array.isArray(newFilters.ownerships) ? newFilters.ownerships : [],
      maintenances: Array.isArray(newFilters.routeIds) ? newFilters.maintenances : [],
    };
    this.dropdownFilters.next(updatedDropdownFilters);
  }

  onChange(event: string): void {
    const [key, value] = event.split(":");

    const updatedFilters: InputPreventive = {
      ...this.activeFilters.value,
      [key]: value,
    };

    // Evita disparar atualização se não houver mudança real
    if (
      JSON.stringify(updatedFilters) !==
      JSON.stringify(this.activeFilters.value)
    ) {
      this.activeFilters.next(updatedFilters);
      this.fetchData(updatedFilters);
    }
  }

  // processRoutesToTree(routes: Route[]): TreeNode[] {
  //   const tree: { [key: string]: any } = {};

  //   routes.forEach((route) => {
  //     const parts = route.tooltip.title.split(">"); // Divide o título pelo delimitador
  //     const projectName = parts[0].trim(); // Nome do projeto
  //     const routeGroup =
  //       parts[1]?.trim().replace(/\.kml$/i, "") || "Desconhecido"; // Grupo de rotas

  //     if (!tree[projectName]) {
  //       tree[projectName] = {}; // Inicializa o projeto
  //     }

  //     if (!tree[projectName][routeGroup]) {
  //       tree[projectName][routeGroup] = []; // Inicializa o grupo de rotas
  //     }

  //     tree[projectName][routeGroup].push({
  //       name: route.name,
  //       route,
  //     });
  //   });

  //   return Object.keys(tree).map((projectName) => ({
  //     name: projectName,
  //     children: Object.keys(tree[projectName]).map((groupName) => ({
  //       name: groupName,
  //       count: tree[projectName][groupName].length, // Conta o número de rotas no grupo
  //       children: tree[projectName][groupName], // Adiciona as rotas como filhos
  //     })),
  //   }));
  // }

  // convertTreeToArray(tree: any): any[] {
  //   return Object.keys(tree).map((projectName) => {
  //     return {
  //       name: projectName,
  //       children: Object.keys(tree[projectName]).map((routeName) => ({
  //         name: routeName,
  //         route: tree[projectName][routeName],
  //       })),
  //     };
  //   });
  // }

  clearMarkers(): void {
    this.mapMarkers.forEach((marker) => marker.setMap(null));
    this.mapMarkers = [];

    if (this.data.value.markers.length <= 0) {
      this.mapMarkers.forEach((marker) => marker.setMap(null));
      this.mapMarkers = [];
      console.log("teste de logica");
    }
  }

  clearRoutes() {
    if (this.mapRoutes.length > 0) {
      this.mapRoutes.forEach((route) => {
        if (route) {
          route.setMap(null); // Remove a polyline do mapa
        }
      });
      this.mapRoutes = []; // Esvazia o array após remover do mapa
    }
  
    // Garante que não há referências antigas de rotas
    if (!this.data.value.routes || this.data.value.routes.length === 0) {
      this.mapRoutes = [];
    }
  }
  
  ngOnDestroy(): void {
    // Emite um sinal para todos os observables se desinscreverem
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
