<div class="container-locations-map">
  <div *ngIf="isLoading" class="loading-screen" [@fadeInOutAnimation]>    
    <div  class="loading-animation">
      <div class="spinner-container">    
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <div *ngIf="isSearch" class="container-technicians" [@fadeInOutAnimation]>
    <div class="container-input-technicians">
      <span class="title-container">
        <i class="icon fa fa-users"></i>        
        Monitoramento
      </span>
      <label class="input-technicians">
        <input 
          class="input-technicians__field" 
          type="text" 
          placeholder=" "
          [(ngModel)]="resourceId"
          (keyup.enter)="onSendData(resourceId)"
          (input)="formatUppercase()"
        />
        <span class="input-technicians__label">Código técnico</span>
      </label>
      <div class="button-group">
        <button (click)="onSendData(resourceId)">Buscar</button>        
      </div>
    </div>      
  </div>

  <div *ngIf="isInfoTechnicians" class="container-info-technicians" [@fadeInOutAnimation]>
    <h3>Status do tecnico</h3>
    <span>{{ this.data.value.onStop ? 'Técnico ativo' : 'Técnico parado' }}</span>
    <button class="button-group" (click)="newSearch()">Novo Técnico</button>
  </div>

  <div class="container-button-close-map">
    <button class="button-close-map" (click)="closeModal()">
      <i class="fa fa-compress"></i>
    </button>
  </div>

  <agm-map
    #agmMap  
    style="width: 100%; height: 100%;"
    [latitude]="mapConfig.latitude"
    [longitude]="mapConfig.longitude"  
    [zoom]="mapConfig.zoom"
    [streetViewControl]="false"
    (mapReady)="onMapReady($event)"
  >    
    <!-- <agm-marker
      *ngFor="let point of this.data.value.position; let i = index"
      [latitude]="point.lat"
      [longitude]="point.lng"
      [iconUrl]="lastPosition"
      (mapClick)="toggleInfoWindow(infoWindow, i)"
    >
      <agm-info-window
       *ngIf="activeInfoWindowIndex === i"
       #infoWindow
      >
        <div class="container-technicians-position">
          <h3>Informações</h3>
          <span>Horario: {{point.time}}</span>
        </div>
      </agm-info-window>
    </agm-marker>

    <agm-polyline [strokeColor]="'#00A8FF'" [strokeWeight]="8" [strokeOpacity]="1">
      <agm-polyline-point          
        *ngFor="let point of this.data.value.position"
        [latitude]="point.lat"
        [longitude]="point.lng"
      >
      </agm-polyline-point>
    </agm-polyline> -->
  </agm-map>
</div>