import { Component, Input, OnInit } from '@angular/core';
import { IAlert } from 'src/app/interfaces';

@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: []
})
export class AlertTableComponent implements OnInit {

  @Input() title: string;
  @Input() data: IAlert[] = [];

  constructor() { }

  ngOnInit(): void {
  }
}
