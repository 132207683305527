import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// BOOTSTRAP COMPONENTS
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxLoadingModule } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ChartsModule } from 'ng2-charts';

// ANGULAR MATERIAL COMPONENTS
import { MatCheckboxModule } from '@angular/material/checkbox';

// LAYOUT
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { ThemeOptions } from './theme-options';
import { OptionsDrawerComponent } from './ThemeOptions/options-drawer/options-drawer.component';

// HEADER
import { HeaderComponent } from './Layout/Components/header/header.component';
import { DotsComponent } from './Layout/Components/header/elements/dots/dots.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { MegamenuComponent } from './Layout/Components/header/elements/mega-menu/mega-menu.component';
import { MegapopoverComponent } from './Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';

// SIDEBAR
import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// PAGES USER
import { HomeComponent } from './pages/common/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';

// Gauges Charts
import { tokenInterceptor } from './interceptors/token.interceptor';
import { errorInterceptor } from './interceptors/error.interceptor';

// Pages VADALISMO
import { DetalhamentoComponent } from './pages/vandalismo/detalhamento/detalhamento.component';

// Components
import { CardDefaultComponent } from './components/card-default/card-default.component';
import { SelectDefaultComponent } from './components/select-default/select-default.component';
import { PopupRowComponent } from './maps/ticket-pop-up/components/ticket-row-pop-up.component';
import { NotFoundComponent } from './pages/common/not-found/not-found.component';
import { ErrorPopUpComponent } from './pages/auth/login/components/error-pop-up/error-pop-up.component';
import { TimelineDefaultComponent } from './components/timeline-default/timeline-default.component';
import { NotificationLineComponent } from './components/timeline-default/components/notification-line/notification-line.component';
import { DataPickerDefaultComponent } from './components/data-picker-default/data-picker-default.component';
import { TitleDefaultComponent } from './components/title-default/title-default.component';

// Maps
import { environment } from 'src/environments/environment';
import { TicketPopUpComponent } from './maps/ticket-pop-up/ticket-pop-up.component';
import { ZoomImagePopUpComponent } from './maps/zoom-image-pop-up/zoom-image-pop-up.component';

import { IncidentDoughnutChartComponent } from './charts/incident-doughnut-chart/incident-doughnut-chart.component';
import { OccurrencesBarChartComponent } from './charts/occurrences-bar-chart/occurrences-bar-chart.component';
import { CallQuantityBarChartComponent } from './charts/call-quantity-bar-chart/call-quantity-bar-chart.component';
import { CallComparisonByStateBarChartComponent } from './charts/call-comparison-by-state-bar-chart/call-comparision-by-state-bar-chart.component';
import { CallComparisionBarLineChartComponent } from './charts/call-comparision-bar-line-chart/call-comparision-bar-line-chart.component';
import { RequestWrapperComponent } from './components/request-wrapper/request-wrapper.component';
import { QtdeCallQtdePatrolComponent } from './charts/qtde-call-qtde-patrol/qtde-call-qtde-patrol.component';
import { CallControlPerDayComponent } from './charts/call-control-per-day/call-control-per-day.component';
import { AnalyticBarDefaultComponent } from './charts/analytic-bar-default/analytic-bar-default.component';
import { AnalyticBarHorizontalComponent } from './charts/analytic-bar-horizontal/analytic-bar-horizontal.component';
import { OcurrencesComparisionByYearsBarComponent } from './charts/ocurrences-comparision-by-years-bar/ocurrences-comparision-by-years-bar.component';
import { AnalyticalBarLineStackedComponent } from './charts/analytical-bar-line-stacked/analytical-bar-line-stacked.component';
import { AnalyticalCascateBarComponent } from './charts/analytical-cascate-bar/analytical-cascate-bar.component';
import { AccessControlDirectoryBarChartComponent } from './charts/access-control-directory-bar-chart/access-control-directory-bar-chart.component';

// Angular Material

// ZOOM
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { AlertaChamadosComponent } from './pages/vandalismo/alerta-chamados/alerta-chamados.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { RotaComponent } from './pages/vandalismo/rota/rota.component';
import { CardScoreComponent } from './components/card-score/card-score.component';
import { UserManagementComponent } from './pages/user/user-management/user-management.component';
import { CreateUserModalComponent } from './pages/user/user-management/components/create-user-modal/create-user-modal.component';
import { UpdateUserModalComponent } from './pages/user/user-management/components/update-user-modal/update-user-modal.component';
import { DeletePopUpModalComponent } from './pages/user/user-management/components/delete-pop-up-modal/delete-pop-up-modal.component';
import { AnaliticoComponent } from './pages/vandalismo/analitico/analitico.component';
import { FilterDefaultComponent } from './components/filter-default/filter-default.component';
import { RegionalComparisonVisionComponent } from './pages/vandalismo/analitico/components/regional-comparison-vision/regional-comparison-vision.component';
import { HistogramVisionComponent } from './pages/vandalismo/analitico/components/histogram-vision/histogram-vision.component';
import { AnalyticalFullscreenMapComponent } from './maps/analytical-fullscreen-map/analytical-fullscreen-map.component';
import { TicketPreviewComponent } from './maps/ticket-preview/ticket-preview.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PreferencesComponent } from './pages/user/preferences/preferences.component';
import { CardThemeComponent } from './pages/user/preferences/components/card-theme/card-theme.component';
import { CreateThemePopUpComponent } from './pages/user/preferences/components/create-theme-pop-up/create-theme-pop-up.component';
import { CardAddThemeComponent } from './pages/user/preferences/components/card-add-theme/card-add-theme.component';
import { CreatePasswordComponent } from './pages/auth/create-password/create-password.component';
import { CreatePasswordErrorPopUpComponent } from './pages/auth/create-password/components/create-password-error-pop-up/create-password-error-pop-up.component';
import { CreatePasswordSuccessPopUpComponent } from './pages/auth/create-password/components/create-password-success-pop-up/create-password-success-pop-up.component';
import { ResponsePopUpComponent } from './pages/auth/reset-password/components/response-pop-up/response-pop-up.component';
import { ResponseForgotPasswordPopUpComponent } from './pages/auth/forgot-password/components/response-forgot-password-pop-up/response-forgot-password-pop-up.component';
import { AnalyticalFullscreenProxyComponent } from './maps/analytical-fullscreen-map/components/analytical-fullscreen-proxy.component';
import { LegendItemComponent } from './components/legend-item/legend-item.component';
import { VerificacaoImagesComponent } from './pages/images/verificacao-images/verificacao-images.component';
import { MapaOperacoesComponent } from './pages/vandalismo/mapa-operacoes/mapa-operacoes.component';
import { VerificationSpanComponent } from './pages/images/verificacao-images/components/verification-span/verification-span.component';
import { AnaliticoImagesComponent } from './pages/images/analitico-images/analitico-images.component';
import { GeneralVisionComponent } from './pages/vandalismo/analitico/components/general-vision/general-vision.component';
import { YearToDateVisionComponent } from './pages/vandalismo/analitico/components/year-to-date-vision/year-to-date-vision.component';
import { RequestResponsePopUpComponent } from './components/request-response-pop-up/request-response-pop-up.component';
import { MapaInventarioComponent } from './pages/vandalismo/mapa-inventario/mapa-inventario.component';
import { HeaderFilterComponent } from './components/header-filter/header-filter.component';
import { ModalZoomImageComponent } from './components/modal-zoom-image/modal-zoom-image.component';
import { AccessControlComponent } from './pages/user/access-control/access-control.component';
import { LogsPopupComponent } from './pages/user/access-control/components/logs-popup.component';
import { UserInfoPopUpComponent } from './components/user-info-pop-up/user-info-pop-up.component';
import { ImportUsersModalComponent } from './pages/user/user-management/components/import-users-modal/import-users-modal.component';
import { ExportUsersModalComponent } from './pages/user/user-management/components/export-users-modal/export-users-modal.component';
import { ConfirmationPasswordComponent } from './pages/auth/confirmation-password/confirmation-password.component';
import { ReportImagesModalComponent } from './pages/images/analitico-images/components/report-images-modal/report-images-modal.component';
import { ImageVerificationMapComponent } from './maps/image-verification-map/image-verification-map.component';
import { EfetividadeComponent } from './pages/vandalismo/efetividade/efetividade.component';
import { FiltersComponent } from './pages/vandalismo/efetividade/components/filters/filters.component';
import { AccessControlDirectoryComponent } from './pages/user/access-control-directory/access-control-directory.component';
import { SugestaoComponent } from './pages/vandalismo/sugestao/sugestao.component';
import { UserSupportComponent } from './pages/user/user-support/user-support.component';
import { SupportTicketsComponent } from './pages/user/support-tickets/support-tickets.component';
import { PendingTicketsComponent } from './pages/user/user-support/pending-tickets/pending-tickets.component';
import { CompleteTicketsComponent } from './pages/user/user-support/completed-tickets/completed-tickets.component';
import { RecurrenceComponent } from './pages/user/recurrence/recurrence.component';
import { ImportDataModalComponent } from './components/import-data-modal/import-data-modal.component';
import { RecurrenceTransformStrategy } from './pages/user/recurrence/strategy/RecurrenceTransformStrategy';
import { RouteMapComponent } from './maps/route-map/route-map.component';
import { DataClassificationComponent } from './pages/user/data-classification/data-classification.component';

// TABLES
import { TableComponent } from './pages/vandalismo/efetividade/components/table/table.component';
import { GenericTableComponent } from './tables/generic-table/generic-table.component';
import { SuggestionTableComponent } from './tables/suggestion-table/suggestion-table.component';
import { SmartTableComponent } from './components/smart-table/smart-table.component';
import { TableDefaultComponent } from './pages/vandalismo/alerta-chamados/components/table-default/table-default.component';
import { RouteTableComponent } from './tables/route-table/route-table.component';
import { AlertTableComponent } from './tables/alert-table/alert-table.component';
import { UserTableComponent } from './pages/user/user-management/components/user-table/user-table.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    // LAYOUT
    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    MapaOperacoesComponent,
    RecurrenceComponent,
    ImportDataModalComponent,

    // HEADER
    HeaderComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,

    // SIDEBAR
    SidebarComponent,
    LogoComponent,

    // User Pages
    ForgotPasswordComponent,
    LoginComponent,    
    ResetPasswordComponent,

    // Vandalismo Pages    
    AnaliticoComponent,    
    // PAGES ANALITICO
    GeneralVisionComponent,
    HistogramVisionComponent,
    RegionalComparisonVisionComponent,
    YearToDateVisionComponent,
    HistogramVisionComponent,
    
    AnalyticalFullscreenMapComponent,
    AnalyticalFullscreenProxyComponent,
    DetalhamentoComponent,
    AlertaChamadosComponent,
    RotaComponent,
    EfetividadeComponent,
    SugestaoComponent,
    
    // IMAGENS
    VerificacaoImagesComponent,
    VerificationSpanComponent,
    AnaliticoImagesComponent,
    
    // Components Default
    CardDefaultComponent,
    SelectDefaultComponent,
    TicketPopUpComponent,
    ZoomImagePopUpComponent,
    DataPickerDefaultComponent,
    ErrorPopUpComponent,    
    TimelineDefaultComponent,
    NotificationLineComponent,
    TitleDefaultComponent,
    RequestWrapperComponent,
    RequestWrapperComponent,
    CardScoreComponent,
    FilterDefaultComponent,
    
    TicketPreviewComponent,
    
    // PAGES DEFAULT
    NotFoundComponent,
    HomeComponent,
    
    // CHARTS
    IncidentDoughnutChartComponent,
    OccurrencesBarChartComponent,
    CallQuantityBarChartComponent,
    CallComparisonByStateBarChartComponent,
    CallComparisionBarLineChartComponent,
    QtdeCallQtdePatrolComponent,
    CallControlPerDayComponent,
    OcurrencesComparisionByYearsBarComponent,
    AnalyticBarHorizontalComponent,
    AnalyticBarDefaultComponent,
    AccessControlDirectoryBarChartComponent,
    AnalyticalBarLineStackedComponent,
    AnalyticalCascateBarComponent,
    
    // TABLES
    GenericTableComponent,
    SmartTableComponent,
    TableDefaultComponent,
    AlertTableComponent,
    UserTableComponent,
    RouteTableComponent,
    TableComponent,
    SuggestionTableComponent,    
    
    RouteMapComponent,
    UserManagementComponent,
    CreateUserModalComponent,
    UpdateUserModalComponent,
    PopupRowComponent,
    LoadingComponent,
    PreferencesComponent,
    CardThemeComponent,
    CreateThemePopUpComponent,
    CardAddThemeComponent,
    CreatePasswordComponent,
    CreatePasswordErrorPopUpComponent,
    CreatePasswordSuccessPopUpComponent,
    DeletePopUpModalComponent,
    ResponsePopUpComponent,
    ResponseForgotPasswordPopUpComponent,
    LegendItemComponent,
    RequestResponsePopUpComponent,
    MapaInventarioComponent,
    HeaderFilterComponent,
    ModalZoomImageComponent,
    RequestResponsePopUpComponent,
    AccessControlComponent,
    LogsPopupComponent,
    UserInfoPopUpComponent,
    ImportUsersModalComponent,
    ExportUsersModalComponent,
    ConfirmationPasswordComponent,
    ReportImagesModalComponent,
    FiltersComponent,
    AccessControlDirectoryComponent,
    ImageVerificationMapComponent,
    AccessControlDirectoryBarChartComponent,
    SugestaoComponent,
    GenericTableComponent,
    UserSupportComponent,
    SupportTicketsComponent,
    PendingTicketsComponent,
    CompleteTicketsComponent,
    SuggestionTableComponent,    
    SmartTableComponent, DataClassificationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatTooltipModule,
    DragAndDropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ChartsModule,
    NgReduxModule,
    CommonModule,
    MatSnackBarModule,
    LoadingBarRouterModule,
    NzDatePickerModule,
    BsDatepickerModule.forRoot(),
    // Angular Bootstrap Components
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    SlickCarouselModule,
    // CountUpModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    AgmDirectionModule,
    ImageCropperModule,
    NgSelectModule,
    // SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    TextareaAutosizeModule,

    // Angular Material Components
    MatCheckboxModule,
    // ZOOM
    NgxImgZoomModule
  ],
  providers: [
    RecurrenceTransformStrategy,
    tokenInterceptor,
    errorInterceptor,
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ConfigActions,
    ThemeOptions,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]
    );
  }
}
