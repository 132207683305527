<div style="background-color: #fff; padding: 20px; width: 100%; border-radius: 5px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); text-align: center;">
  <div style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: center; text-align: center;">
    <div style="background-color: #f00; color: #fff; width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
      <i class="fa fa-exclamation-circle"></i>
    </div>				
    <h4 style="margin-top: 10px; font-size: 1.2rem;">Confirmação de Exclusão</h4> 
  </div>
  <div style="text-align: left; margin-bottom: 20px; padding-left: 10%; padding-right: 10%;">
    <p>Tem certeza de que deseja excluir este usuário? Esta ação não pode ser desfeita.</p>
  </div>
  <div style="display: flex; justify-content: space-between; padding-left: 10%; padding-right: 10%;">
    <button 
      style="background-color: #f00; color: #fff; border: none; border-radius: 5px; padding: 5px 10px; cursor: pointer; width: 45%;"
      (click)="deleteUser()">
      Excluir
    </button>
    <button 
      style="background-color: #ccc; border: none; border-radius: 5px; padding: 5px 10px; cursor: pointer; width: 45%;"
      (click)="close('cancel')">
      Cancelar
    </button>
  </div>
</div>
