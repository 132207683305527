<div class="container-map-create-route-station">
  <div class="map-create-container">
    <button type="button" class="btn-close-default" [style.color]="'white'" (click)="closeMap()">
      <span class="fa fa-times"></span>
    </button>
    <agm-map    
      [latitude]="mapConfig.center?.lat"
      [longitude]="mapConfig.center?.lng"  
      [streetViewControl]="false"
      [zoom]="mapConfig.zoom"
      [disableDefaultUI]="mapConfig.disableDefaultUI"
      [maxZoom]="mapConfig.maxZoom"
      (mapReady)="onMapReady($event)"
      class="map-container"
    >
    </agm-map>

    <div class="container-information">
      <span class="information-title">{{ mode === 'station' ? 'Adicionar Nova Estação' : 'Adicionar Nova Rota' }}</span>
      <!-- *ESTAÇÃO -->      
      <div class="container-inputs" *ngIf="mode === 'station'">
        <!-- TODO: fazer a implementação de input-default -->
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.nome"
            [(ngModel)]="dataForm.acronym"
          />
          <label for="input" class="input-label">{{placeholders.nome}}</label>
        </div> 
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.type"
            [(ngModel)]="dataForm.type"
          />
          <label for="input" class="input-label">{{placeholders.type}}</label>
        </div> 
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.classification"
            [(ngModel)]="dataForm.classification"
          />
          <label for="input" class="input-label">{{placeholders.classification}}</label>
        </div> 
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.family"
            [(ngModel)]="dataForm.family"
          />
          <label for="input" class="input-label">{{placeholders.family}}</label>
        </div>         
        <div class="input-coordinates">
          <span>Coordenada</span>
          <button             
            class="new-btn-default"
            (click)="enableMarkerSelection()"
          >{{ selectedCoordinates ? 'Alterar Coordenadas' : 'Adicionar Coordenadas' }}</button>          
        </div>
      </div>    
      
      <!-- *ROTA -->
      <div class="container-inputs" *ngIf="mode === 'route'">
        <!-- TODO: fazer a implementação de input-default -->
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.nome"
            [(ngModel)]="dataRoute.name"
          />
          <label for="input" class="input-label">{{placeholders.nome}}</label>
        </div> 
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.description"
            [(ngModel)]="dataRoute.description"
          />
          <label for="input" class="input-label">{{placeholders.description}}</label>
        </div> 
        <div class="container-input-dafault">
          <input
            type="text"
            id="input"
            class="input-content"
            [placeholder]="placeholders.type"
            [(ngModel)]="dataRoute.folderPathAtt"
          />
          <label for="input" class="input-label">{{placeholders.type}}</label>
        </div>  
        <div class="input-coordinates">
          <span>Rota</span>
          <button             
            class="new-btn-default"
            (click)="toggleDrawingMode()"
          >{{ isDrawing ? 'Parar de Desenhar' : 'Desenhar Rota' }}</button>          
        </div>               
      </div>      
      <button 
        class="new-btn-default" 
        (click)="create()"
      >{{ mode === 'station' ? 'Adicionar nova estação' : 'Salvar nova rota' }}</button>
    </div>
  </div>    
</div>
