import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideInOutAnimation = trigger('slideInOut', [
  state('in', style({
    'max-height': '650px',
    opacity: '1',
    visibility: 'visible'
  })),
  state('out', style({
    'max-height': '0',
    opacity: '0',
    visibility: 'hidden'
  })),
  transition('in => out', [animate('800ms ease-in-out')]),
  transition('out => in', [animate('800ms ease-in-out')])
]);

export const fadeInOutAnimation = trigger('fade-inout', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('400ms ease-in-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms ease-in-out', style({ opacity: 0 }))
  ])
]);