<div *ngIf="isFullscreen()" class="full-image">
  <div class="full-header">
    <button type="button" class="btn-close-default" (click)="closeModal()">
      <span class=" fa fa-times-circle"></span>
    </button>
  </div>
  <div class="full-image-containaer mx-auto">
    <img [src]="modalData.src"/>
  </div>
  <!-- <div class="teste">
    <ngx-img-zoom
      [zoomImageSrc]="myThumbnail"
      [previewImageSrc]="modalData.src"
      [imgStyle]="
        'width:600px; 
        height:600px;
        top:0;
        left:0;
        position:absolute;'"
      [resultStyle]="
        'width:300px; 
        height:300px; 
        background-repeat: no-repeat; 
        z-index: 2; 
        position:absolute;
        right:0;'"
      [enableZoom]="true"
    >
    </ngx-img-zoom>
  </div> -->
</div>
<!-- <div *ngIf="!isFullscreen()" class="modal-body">
  <div  class="row">
    <div class="col-md-6">
      <div class="card main-card mb-3">
        <div class="card-body">
          <image-cropper 
            [imageChangedEvent]="modalData.src"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            [resizeToWidth]="128" 
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            format="png"
            outputType="base64"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            [alignImage]="'left'"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [style.display]="showCropper ? null : 'none'"
            [canvasRotation]="canvasRotation"
            [containWithinAspectRatio]="containWithinAspectRatio"
            [transform]="transform">
          </image-cropper>
          <div class="divider"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card main-card mb-3">
        <div class="card-header">
          Crop Result
        </div>
        <div class="card-body">
          <img *ngIf="croppedImage" class="img-fluid w-100" [src]="croppedImage" />
        </div>
      </div>
    </div>
  </div>
</div> -->
