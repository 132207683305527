<div class="card-chart chart-small-alert col-12">
  <div class="card-chart-header chart-small-header-alert">
    <app-title-default [content]="title"></app-title-default>
  </div>
  <hr>
  <div class="card-chart-body chart-small-body-alert container-chart-call-per-day">
    <div class="legend-per-day">
      <span class="legend-icon">
        <div class="line-legend"
          [ngStyle]="{ 'background-color': this.colors.primaryColor }"></div>
        Qtd de chamados
      </span>
      <span class="legend-icon">
        <div class="line-legend" 
          [ngStyle]="{ 'background-color': '#ffd200' }"></div>
        Média movel
      </span>
      <span class="legend-icon">
        <div class="line-legend"
          [ngStyle]="{ 'background-color': this.colors.secondaryColor }"></div>
        Volatidade IC 95%
      </span>
    </div>
    <div style="width: 2000px;height: 100%;">
      <canvas
        baseChart        
        height="100%"        
        [datasets]="barlineData.datasets"
        [labels]="barlineData.labels"
        [options]="lineOptions"        
        [legend]="lineChartLegend"
        [chartType]="type"        
      ></canvas>
    </div>
  </div>
</div>
