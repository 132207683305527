<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <div 
    class="logo-src"
    [style.background]="getBackgroundStyle()"
    (click)="redirectHome()"
  >
  </div>
  <div class="app-header__content">
    <div class="app-header-left">
    </div>

    <!-- <div style="display: flex; align-items: center; justify-content: center;">
      <p *ngIf="isProduction()" style="margin: 10%; font-size: large; font-weight: 500;">HOMOLOGAÇÃO</p>
    </div> -->

    <div class="app-header-right">
      <app-dots></app-dots>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <!-- <div class="app-header__menu">
    <span>
      <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
        (click)="toggleHeaderMobile()">
        <div class="btn-icon-wrapper">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </div>
      </button>
    </span>
  </div> -->
</div>