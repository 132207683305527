import { trigger, state, style, transition, animate } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ImportDataModalComponent } from "src/app/components/import-data-modal/import-data-modal.component";
import { SmartTableRow } from "src/app/components/smart-table/types/SmartTableRow";
import { TransformStrategyResult } from "src/app/interfaces";
import { RequestResult } from "src/app/interfaces/RequestContext";
import { AuthService } from "src/app/services/auth.service";
import { ExcelProcessorService } from "src/app/services/excel-processor.service";
import { FilterService, Namespace } from "src/app/services/filter.service";
import { GenerateDate } from "src/app/utils/generateDate.util";
import { icons } from "src/app/utils/icons";
import { RecurrenceTransformStrategy } from "../../user/recurrence/strategy/RecurrenceTransformStrategy";
import { InputCriticalSegment, PreventiveService } from "src/app/services/preventive.service";
import { SmartTableCard } from "src/app/components/smart-table/types/SmartTableCard";
import { SmartTableDataProcessing } from "src/app/components/smart-table/utils/DataProcessing";
import { PreventiveMapComponent } from "src/app/maps/preventive-map/preventive-map.component";
import { MapTypeStyle } from "@agm/core";


const mapConfig = {
    zoom: 15,
    maxZoom: 19,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
    optimized: false,
    // center: { lat: -23.55052, lng: -46.633308 }, // Centro de São Paulo
    mapTypeControl: false,
    styles: <MapTypeStyle[]>[ // Define como MapTypeStyle[]
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'simplified' }]
      }
    ]
  }

@Component({
  selector: 'app-critical-route',
  templateUrl: './critical-route.component.html',
  styleUrls: ['./critical-route.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('in => out', [animate('800ms ease-in-out')]),
      transition('out => in', [animate('800ms ease-in-out')])
    ])
  ]
})
export class CriticalRouteComponent implements OnInit {

  NAMESPACE: Namespace = "critical-preventive";
  data = new BehaviorSubject({ headers: [], rows: [] });

  cards: BehaviorSubject<SmartTableCard[]> = new BehaviorSubject([]);
  activeFilters: InputCriticalSegment = {};
  currentPage = new BehaviorSubject<number>(1);
  totalPages = new BehaviorSubject<number>(1);
  loading = new BehaviorSubject<boolean>(false);
  error = new BehaviorSubject<boolean>(false);

  itemsPerPage = 11;
  dropdownFilters = new BehaviorSubject<Record<string, string[]>>({
  type: [], status: [], families: [], regionals: [], directors: [], states: [], clusters: [] });
  icons = icons;
  recurrenceTableSettings = new BehaviorSubject({
    importable: true,
    exportable: true,
  });

  userDirector: string;
  userId: number;
  isUserAdmin: boolean = false;
  isDivVisible: boolean = false;

  constructor(
    private filterService: FilterService,
    private authService: AuthService,
    private modalService: NgbModal,
    private excelProcessorService: ExcelProcessorService,
    private snackBar: MatSnackBar,
    public preventiveService: PreventiveService,
    public recurrenceTransformStrategy: RecurrenceTransformStrategy
  ) {

    const defaultActiveFilters: InputCriticalSegment = {
      status: "",
      types: "",
      families: ""
    };

    this.filterService.setDefaultFilters<InputCriticalSegment>({
      namespace: this.NAMESPACE,
      defaultFilters: {},
    });

    this.filterService
      .getFiltersObservable<InputCriticalSegment>({
        namespace: this.NAMESPACE,
      })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.activeFilters = activeFilters;
        this.fetchData(
          activeFilters,
          this.currentPage.value,
          this.itemsPerPage,
          null,
          null,
          null
        );
      });

    const user = this.authService.getCurrentUser();
    this.userId = user.id;
  }

  ngOnInit(): void {}

  handleChanges = (results: {
    isPreview: boolean;
    data: SmartTableRow[];
  }): void => {
    const data: Record<string, string>[] = results.data.map(({ id, values }) =>
      values.reduce((acc, { field, value }) => {
        acc[field] = value;
        return acc;
      }, { segmentId: id })
    );

    this.preventiveService.updateSegment(data)
    .subscribe({
      next: () => this.handleSuccess("Segmentos atualizados com sucesso!", results.isPreview),
      error: () => this.handleError("Erro ao atualizar os segmentos."),
    });
  };

  private handleSuccess(message: string, isPreview: boolean): void {
    this.openPopUpResponse({ status: 200, message, isError: false });

    if (isPreview) {
      this.retryFetchData(); // Apenas no modo de preview
    }
  }

  private handleError(message: string): void {
    this.openPopUpResponse({ status: 404, message, isError: true });
  }

  openImportDataModal = () => {
    const modalRef = this.modalService.open(ImportDataModalComponent, { size: "xl" });
    modalRef.componentInstance.downloadTemplateCallback = this.downloadTemplateCallback;
    modalRef.componentInstance.processFileCallback = this.processFileCallback;
    modalRef.componentInstance.handleChanges = this.handleChanges;
  };

  handlePageChange(event: {
    page: number;
    size: number;
    search: string;
    isPreview: boolean;
    sortBy: string[];
    sortDirection: string[];
  }): void {
    const page = event.page || 1;
    this.fetchData(
      this.activeFilters,
      page,
      event.size,
      event.search,
      event.sortBy,
      event.sortDirection
    );
  }

  fetchData(
    activeFilters: InputCriticalSegment,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[]
  ) {

    this.preventiveService
      .getCriticalSegments(
        activeFilters,
        page,
        size,
        search,
        sortBy,
        sortDirection,
        this.userId
      )
      .subscribe({
        next: ({ data, filters, settings, totalPages, cards }) => {
          this.data.next(data);
          this.totalPages.next(totalPages);
          this.recurrenceTableSettings.next(settings);
          this.dropdownFilters.next(filters);
          this.cards.next(SmartTableDataProcessing.processCards(cards));
          this.loading.next(false);
          this.error.next(false);
        },
        error: (err) => {
          this.loading.next(false);
          this.error.next(true);
        },
      });
  }

  downloadTemplateCallback(): void {
    const filename = "MODELO_PAINEL_DE_REINCIDENCIA.xlsx";
    const fileUrl = `assets/excel/${filename}`;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = "modelo_painel_de_reincidencia.xlsx";
    anchor.click();
  }

  processFileCallback = async (
    file: File
  ): Promise<TransformStrategyResult> => {
    try {
      const data = await this.excelProcessorService.processExcel(
        this.recurrenceTransformStrategy,
        file
      );
      return { headers: data.headers, rows: data.rows };
    } catch (error) {
      throw error;
    }
  };

  openPreventiveMapPopup = ({ rowId, value, field }: { rowId: string, field: string, value: any }) => {
    const modalRef = this.modalService.open(PreventiveMapComponent);
    modalRef.componentInstance.activeFilters = new BehaviorSubject({ routeIds: rowId, families: '', classification: '', states: '', clusters: '', type: '' });
    modalRef.componentInstance.returnUrl = 'preventiva/rotas-criticas';
    modalRef.componentInstance.mapConfig = mapConfig;
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = "Fechar";
    const styleResult = !requestResult.isError
      ? "success-bar-container"
      : "failure-bar-container";
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [styleResult],
    };
    this.snackBar.open(
      requestResult.message,
      closeButtonMessage,
      snackBarSettings
    );
  }

  // Exporta os dados reutilizando as funções auxiliares
  exportData = ({
    search,
    sortBy,
    sortDirection,
  }: {
    search: string;
    sortBy: string[];
    sortDirection: string[];
  }): void => {
    // const user = this.authService.getCurrentUser();
    // this.preventiveService
    //   .sendRecurrences(
    //     this.activeFilters,
    //     user.id,
    //     search,
    //     sortBy,
    //     sortDirection
    //   )
    //   .subscribe({
    //     next: (response) => this.onServiceSuccess(response.message, false),
    //     error: (err) => this.onServiceError(err.message),
    //   });
  };

  // Reutilizando os métodos auxiliares
  private onServiceSuccess(message: string, isPreview: boolean): void {
    this.openPopUpResponse({
      status: 200,
      message,
      isError: false,
    });

    if (isPreview) {
      this.retryFetchData(); // Apenas no modo de preview
    }
  }

  private onServiceError(message: string): void {
    this.openPopUpResponse({
      status: 404,
      message,
      isError: true,
    });
  }

  getButtonsFilterDefault(filters: string[]) {
    return filters && filters.map((value) => ({ show: value, value }));
  }

  retryFetchData(): void {
    this.fetchData(
      this.activeFilters,
      this.currentPage.value,
      this.itemsPerPage,
      null,
      null,
      null
    );
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDateMinusDays(90);
    return { startDate, endDate };
  }

  // Função de onChange simplificada, agora lidando apenas com activeFilters
  onChange(event: string) {
    const [key, values] = event.split(":");
    this.filterService.updateFilters<InputCriticalSegment>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }  

  
}
