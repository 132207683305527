import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-default-chart',
  templateUrl: './default-chart.component.html',
  styleUrls: ['./default-chart.component.sass']
})
export class DefaultChartComponent implements AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
  private chart!: Chart;

  private labels = [
    'Jan-12', 'Feb-12', 'Mar-12', 'Apr-12', 'May-12', 'Jun-12', 'Jul-12', 'Aug-12', 'Sep-12', 'Oct-12',
    'Nov-12', 'Dec-12', 'Jan-13', 'Feb-13', 'Mar-13', 'Apr-13', 'May-13', 'Jun-13', 'Jul-13', 'Aug-13',
    'Sep-13', 'Oct-13', 'Nov-13', 'Dec-13', 'Jan-14', 'Feb-14', 'Mar-14', 'Apr-14', 'May-14', 'Jun-14',
    'Jul-14', 'Aug-14', 'Sep-14', 'Oct-14', 'Nov-14'
  ];

  private data = [
    [0, 0, 0, 0, 10.800, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.793, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.793, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.790, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.781, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.791, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.799, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.798, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.791, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.794, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.795, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.791, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.794, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.789, 0, 0, 0, 0],
    [0, 0, 0, 0, 10.784, 0, 0, 0, 0],
    [10.789, 10.789, 10.789, 10.789, 10.789, 10.789, 10.789, 10.789, 10.789],
    [10.778, 10.779, 10.780, 10.782, 10.783, 10.784, 10.786, 10.787, 10.788],
    [10.772, 10.775, 10.778, 10.780, 10.783, 10.786, 10.788, 10.791, 10.794],
    [10.771, 10.775, 10.779, 10.783, 10.787, 10.791, 10.795, 10.799, 10.803],
    [10.760, 10.766, 10.771, 10.777, 10.782, 10.787, 10.793, 10.798, 10.804],
    [10.764, 10.771, 10.778, 10.784, 10.791, 10.798, 10.804, 10.811, 10.818],
    [10.755, 10.763, 10.771, 10.779, 10.787, 10.795, 10.803, 10.811, 10.819],
    [10.744, 10.754, 10.763, 10.773, 10.782, 10.791, 10.801, 10.810, 10.820],
    [10.733, 10.744, 10.754, 10.765, 10.776, 10.787, 10.798, 10.808, 10.819],
    [10.732, 10.745, 10.757, 10.769, 10.781, 10.793, 10.805, 10.817, 10.830],
    [10.732, 10.746, 10.759, 10.773, 10.786, 10.799, 10.813, 10.826, 10.840],
    [10.718, 10.733, 10.747, 10.762, 10.777, 10.792, 10.807, 10.821, 10.836],
    [10.707, 10.724, 10.740, 10.756, 10.772, 10.788, 10.804, 10.820, 10.837],
    [10.707, 10.724, 10.742, 10.759, 10.777, 10.795, 10.812, 10.830, 10.847],
    [10.708, 10.726, 10.745, 10.764, 10.783, 10.802, 10.821, 10.840, 10.858],
    [10.701, 10.721, 10.742, 10.762, 10.782, 10.802, 10.822, 10.843, 10.863],
    [10.703, 10.724, 10.746, 10.767, 10.789, 10.811, 10.832, 10.854, 10.875],
    [10.702, 10.725, 10.748, 10.771, 10.794, 10.817, 10.840, 10.863, 10.886],
    [10.701, 10.725, 10.749, 10.774, 10.798, 10.822, 10.847, 10.871, 10.895],
    [10.688, 10.714, 10.740, 10.765, 10.791, 10.817, 10.842, 10.868, 10.894]
  ];

  private colors = [
    'rgba(100, 100, 100, 0.2)', // Mínimo
    'rgba(100, 100, 100, 0.3)', // -3Δ
    'rgba(100, 100, 100, 0.4)', // -2Δ
    'rgba(100, 100, 100, 0.5)', // -1Δ
    '#3a3a3a',                  // Tendência (linha escura)
    'rgba(100, 100, 100, 0.5)', // +1Δ
    'rgba(100, 100, 100, 0.4)', // +2Δ
    'rgba(100, 100, 100, 0.3)', // +3Δ
    'rgba(100, 100, 100, 0.2)'  // Máximo
  ];

  private seriesNames = ["Mínimo", "-3Δ", "-2Δ", "-1Δ", "Tendência", "+1Δ", "+2Δ", "+3Δ", "Máximo"];

  ngAfterViewInit(): void {
    setTimeout(() => this.createChart(), 100);
  }

  private createChart() {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    if (!ctx) return;

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.seriesNames.map((label, index) => ({
          label,
          data: this.data.map(d => (d[index] === 0 ? null : d[index])),
          borderColor: index === 4 ? '#3a3a3a' : 'rgba(0,0,0,0)',
          backgroundColor: this.colors[index],
          borderWidth: index === 4 ? 2 : 0,
          pointRadius: 0, // 🔥 Remove os pontos das linhas
          fill: (() => {
            if (index === 0) return "+1"; // Mínimo preenche até -3Δ
            if (index === 1) return "+1"; // -3Δ preenche até -2Δ
            if (index === 2) return "+1"; // -2Δ preenche até -1Δ
            if (index === 3) return "+1"; // -1Δ preenche até Tendência
            if (index === 4) return false; // Tendência não tem preenchimento
            if (index === 5) return "-1"; // +1Δ preenche até Tendência
            if (index === 6) return "-1"; // +2Δ preenche até +1Δ
            if (index === 7) return "-1"; // +3Δ preenche até +2Δ
            if (index === 8) return "-1"; // Máximo preenche até +3Δ
            return false;
          })(),
          tension: 0
        }))
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 20  // 🔥 Adiciona espaço extra para os rótulos do eixo X
          }
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              gridLines: { display: false },
              ticks: { maxRotation: 45, minRotation: 45 } // 🔥 Ajusta rotação dos labels do eixo X
            }
          ],
          yAxes: [
            {
              stacked: false, // 🔥 Mantemos `stacked: false` para não afetar os valores
              gridLines: { display: true, color: 'rgba(200, 200, 200, 0.2)' }, // 🔥 Linhas suaves no eixo Y
              ticks: {
                min: 10.6, // 🔥 Define um mínimo adequado para evitar o efeito de salto
                max: 10.9, // 🔥 Define um máximo para manter a escala correta
                stepSize: 0.05 // 🔥 Melhora a escala do eixo Y
              }
            }
          ]
        },
        legend: { display: false }, // 🔥 Remove a legenda para manter limpo
        tooltips: {
          enabled: true,
          mode: 'nearest',
          intersect: false
        },
        plugins: {
          datalabels: { // 🔥 🔥 🔥 REMOVER OS NÚMEROS DENTRO DO GRÁFICO 🔥 🔥 🔥
            display: false
          }
        }
      }
    });
  }
}
