<div class="container">
  <div class="support-layout">
    <div class="image-display">
      <div *ngIf="isLoading; else imageBlock">
        <p>Carregando imagens...</p>
      </div>
      <ng-template #imageBlock>
        <div class="container-carousel" *ngIf="!loading">
          <ngx-slick-carousel class="slick-slider" [config]="slideConfig3" (afterChange)="onSlideChange($event)">
            <div ngxSlickItem *ngFor="let photo of photos; let i = index">
              <div class="img-popup">
                <img [src]="photo" (click)="selectImage(photo)" alt="Imagem {{i+1}}" class="carousel-image" />
              </div>
            </div>
          </ngx-slick-carousel>
          <div class="carousel-indicators">
            <span *ngFor="let photo of photos; let i = index"
              [class.active]="i === currentSlide"
              (click)="selectImageFromIndicator(i)"></span>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="sidebar">
      <h5>Tipos de Incidente</h5>
      <div class="ticket-row" style="display: flex; align-content: center; gap: 10px;">
        <strong>Ticket:</strong>
        <p>{{ ticketId }}</p>
      </div>
      <div class="incident-type">
        <label>
          <input type="radio" name="incidentType" value="Furto" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Furto')" />
          <strong style="margin-left: 10px;">Furto</strong>
          <span class="info-icon" title="Furto: Roubo de bens sem violência ou ameaça.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Sabotagem" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Sabotagem')" />
          <strong style="margin-left: 10px;">Sabotagem</strong>
          <span class="info-icon" title="Sabotagem: Ato intencional de danificar ou prejudicar o sistema.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Vandalismo" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Vandalismo')" />
          <strong style="margin-left: 10px;">Vandalismo</strong>
          <span class="info-icon" title="Vandalismo: Destruição ou dano proposital a propriedades.">?</span>
        </label>
        <label>
          <input type="radio" name="incidentType" value="Inconclusivo" [(ngModel)]="selectedIncidentType"
            (change)="selectIncidentType('Inconclusivo ')" />
          <strong style="margin-left: 10px;">Inconclusivo </strong>
          <span class="info-icon" title="Inconclusivo: Caso em que não há informações suficientes para determinar o tipo de incidente.">?</span>
        </label>
      </div>
      <button (click)="updateTicket()" class="update-button">
        <i class="fa fa-paper-plane"></i> Enviar
      </button>
      <button (click)="openHistoric()" class="update-button" style="background-color: rgb(134, 134, 134);">
        <i class="fa fa-table"></i> Histórico
      </button>

      <div *ngIf="showHistoric" class="historic-card">
        <div class="header-container">
          <h4>Histórico de Tickets</h4>
          <button class="close-button" (click)="closeHistoric()">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div *ngFor="let ticket of historicTickets">
          <div class="ticket-row">
            <p>Ticket ID: {{ ticket.referenceId }}</p>
            <button (click)="selectTicket(ticket.referenceId)">Selecionar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>