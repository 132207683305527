<div class="card-chart chart-small-geral col-12 col-md-12 col-xxl-12">
  <div class="card-chart-header-3 chart-small-header-geral">
    <div class="row" style="width: 100%;">
      <div 
        class="col-9" 
        style="
          display: flex;
          align-items: center;
          justify-items: center;
          justify-content: center;
          padding-left: 10vw;
        "
      >
        <app-title-default [content]="titles[selectedView]"></app-title-default>
      </div>

      <div class="btns-vision col-3 d-none d-lg-flex">
        <button
          class="btn-icon fa fa-arrow-up"
          (click)="setView('dayOfWeek')"
        >
        </button>
        <button
          class="btn-icon fa fa-clock-o"
          (click)="setView('hourOfDay')"
        >
        </button>
        <button
          class="btn-icon fa fa-calendar-o"
          (click)="setView('dayOfWeekAndHour')"
        >
        </button>
        <button
          class="btn-icon fa fa-arrow-left"
          [disabled]="!isDayOfWeekView()"
          (click)="decreaseDay()"
        >
        </button>
        <button
          class="btn-icon fa fa-arrow-right"
          [disabled]="!isDayOfWeekView()"
          (click)="increaseDay()"
        >
        </button>
      </div>
      <div class="btns-vision col-3 d-sm-none">
        <div ngbDropdown placement="right">
          <button 
            type="button" 
            ngbDropdownToggle 
            class="btn-icon fa fa-ellipsis-h"
          ></button>

          <!-- ! AJUSTAR DROPDOWN MENU ICONS PARA NÃO OCUPAR A TELA INTEIRA -->

          <div ngbDropdownMenu>            
            <div class="btns-vision-mobile">
              <button
                class="btn-icon fa fa-arrow-up"
                
              >
              </button>
              <button
                class="btn-icon fa fa-clock-o"
                (click)="setView('hourOfDay')"
              >
              </button>
              <button
                class="btn-icon fa fa-calendar-o"
                (click)="setView('dayOfWeekAndHour')"
              >
              </button>
              <button
                class="btn-icon fa fa-arrow-left"
                [disabled]="!isDayOfWeekView()"
                (click)="decreaseDay()"
              >
              </button>
              <button
                class="btn-icon fa fa-arrow-right"
                [disabled]="!isDayOfWeekView()"
                (click)="increaseDay()"
              >
              </button>
            </div>                       
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="card-chart-body chart-small-body-geral">
    <!-- TODO: Passar o style. -->
    <div 
      class="d-flex"
      style="width: 100%; height: 90%; position: relative;"
    >
      <canvas
        baseChart
        height="100"
        width="100"
        [data]="selectedDataView.dataset"
        [labels]="selectedDataView.labels"
        [options]="options"
        [legend]="false"
        [chartType]="type"
        [colors]="backgroundColors"
      >
      </canvas>
    </div>

    <div *ngIf="isDayOfWeekView()" style="display: flex; align-content: center; justify-content: center;">
      <span>{{ selectedDataView.sublabels[selectedDayOfWeek] }}</span>
    </div>
  </div>
</div>