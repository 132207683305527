<div>
  <div class="row">
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 2vh; display: flex; justify-content: flex-end;">
        <div style="margin: 0.5em;">
          <button	type="button" class="btn btn-primary" (click)="addUser()">Adicionar</button>
        </div>

        <div style="margin: 0.5em;">
          <button type="button" class="btn btn-import" (click)="openImportUsersModal($event)">Importar</button>
        </div>

        <div style="margin: 0.5em;">
          <button type="button" class="btn btn-export" (click)="openExportUsersModal($event)">Exportar</button>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  
    <div *ngIf="error" class="alert alert-danger" role="alert">
      Ocorreu um erro ao carregar os usuários.
    </div>
  
    <div class="row" *ngIf="!loading && !error">
      <div class="col-md-12" style="height: 80vh;">
        <app-users-table
          [data]="data"
          [filteredData]="filteredData"
          [loading]="loading"
          [error]="error"
          [fetchData]="fetchData"
        >
        </app-users-table>
      </div>
    </div>
  </div>
</div>