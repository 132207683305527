<div class="image-verification-map-container">
  <div class="image-verification-map-content">    

    <!-- Table Section -->
    <div class="container-img-table">      
      <div class="container-button">
        <button type="button" class="btn-close-default" (click)="closeTable()" [style.color]="'white'" *ngIf="isTable">
          <span class="fa fa-times"></span>
        </button>
        <button type="button" class="btn-close-default" (click)="closeTable()" [style.color]="'black'" *ngIf="!isTable">
          <span class="fa fa-arrows-alt"></span>
        </button>
      </div>
      <table class="table" *ngIf="isTable">
        <thead class="table-header">
          <tr>
            <th class="table-header-cell">Coordenadas</th>
            <th class="table-header-cell">Fotos</th>
            <th class="table-header-cell">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            *ngFor="let group of photoGroups" 
            class="table-row"
            (click)="centerMapOnCoordinates(group.coordinates)"
          >
            <td class="table-cell">{{ group.coordinates }}</td>
            <td class="table-cell">Foto(s): {{ group.photos.join(', ') }}</td>
            <td class="table-cell">
              <span 
                [ngClass]="{'status-duplicada': group.status === 'Mesmo Local', 'status-unica': group.status === 'Local Diferente'}">
                {{ group.status }}
              </span>
            </td>            
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Close Button -->
    <button type="button" class="btn-close-default" (click)="closeMap()">
      <span class="fa fa-times-circle"></span>
    </button>

    <!-- Map Section -->
    <agm-map 
      #agmMap
      style="width: 100%; height: 97%;" 
      [latitude]="centerMap.lat"
      [longitude]="centerMap.lng"
      [zoom]="mapSettings.zoom"
      [disableDefaultUI]="false"
      [zoomControl]="mapSettings.config.zoomControl"
      [streetViewControl]="mapSettings.config.streetViewControl"
      [fullscreenControl]="mapSettings.config.fullscreenControl"
    >      

      <!-- Main Marker -->
      <agm-marker
        [latitude]="_locations.lat"
        [longitude]="_locations.lng"
        [iconUrl]="mapSettings.icons.iconInventory"
        [zIndex]="2"
      >      
        <agm-info-window>
          <div class="container-carousel">                
            <ngx-slick-carousel
              class="slick-slider slick-dotted"
              #slickModal="slick-carousel" 
              [config]="slideConfig"              
            >                  
              <div ngxSlickItem *ngFor="let photo of _imagesUrls; let i = index" class="img-container">
                <img 
                  [src]="photo"
                  alt="Image {{ i }}"                   
                  class="carousel-image"
                >
              </div>                  
            </ngx-slick-carousel>                 
          </div> 
        </agm-info-window>
      </agm-marker>

      <!-- Grouped Markers -->
      <agm-marker
        *ngFor="let location of groupedPhotos | keyvalue"
        [latitude]="location.value.lat"
        [longitude]="location.value.lng"
        [iconUrl]="mapSettings.icons.iconPhotoInventory"
        [zIndex]="1"
      > 
        <agm-info-window>
          <div class="container-carousel">
            <!-- Carousel for multiple images -->
            <ngx-slick-carousel
              *ngIf="location.value.images.length > 1"
              class="slick-slider slick-dotted"
              [config]="slideConfig"
            >
              <div ngxSlickItem *ngFor="let photo of location.value.images" class="img-container">
                <img 
                  [src]="photo"
                  alt="Image"
                  class="carousel-image"
                >
              </div>
            </ngx-slick-carousel>
            
            <!-- Display single image if only one exists -->
            <div *ngIf="location.value.images.length === 1" class="img-container">
              <img
                [src]="location.value.images[0]"
                alt="Image"
                class="carousel-image"
              >
            </div>
          </div> 
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
