import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-default',
  templateUrl: './input-default.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDefaultComponent),
      multi: true
    }
  ]
})
export class InputDefaultComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() labelSpan = '';
  value: string = '';
  
  constructor() { }

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Opcional: gerencia quando o campo está desabilitado
  }

  onInputChange(value: string): void {
    this.value = value;
    this.onChange(value); // Notifica o Angular que o valor mudou
  }

}
