import { AgmMap, MapsAPILoader } from '@agm/core';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { icons } from 'src/app/utils/icons';
import { technicalIcons, activityIcons } from 'src/app/utils/map-icons';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IActivity } from 'src/app/interfaces/models/IActivity';
import { ActivityFilters, DropdownFilters, family, OperationsService, ResourcePosition, states } from 'src/app/services/operations.service';
import { mapConfigStyle } from './map-config';
import { fadeInOutAnimation, slideInOutAnimation } from 'src/app/animations/animations';

const defaultActiveFilters: ActivityFilters = {
  family: '',
  state: '',
  activityId: ''
};

@Component({
  selector: 'app-mapa-operacoes',
  templateUrl: './mapa-operacoes.component.html',
  styles: [],
  animations: [ slideInOutAnimation, fadeInOutAnimation ]
})
export class MapaOperacoesComponent implements OnInit {
  private static OPERACOES_URL = '/vandalismo/analitico';

  NAMESPACE: Namespace = 'operationFullscreen';

  dropdownFilters: BehaviorSubject<DropdownFilters> = new BehaviorSubject({
    family: [],
    net: [],
    states: []
  });
  
  activities: BehaviorSubject<IActivity[]> = new BehaviorSubject([]);
  resourceOperations: BehaviorSubject<ResourcePosition[]> = new BehaviorSubject([]);
  activeFilters: BehaviorSubject<ActivityFilters> = new BehaviorSubject(defaultActiveFilters);

  @ViewChild(AgmMap) agmMap: AgmMap;
  map: google.maps.Map;

  showInfoWindow: boolean[] = [];
  showInfoWindowAssistance: boolean[] = [];

  resourcePositions: ResourcePosition[] = [];
  selectedActivity: IActivity | null = null;
  selectedActivityIndex: number | null = null;
  selectedActivities: { activity: any, index: number }[] = [];
  activityLines: any[] = [];
  activityStatus: string = '';

  activitiesPending: number = 0;
  activitiesStarted: number = 0;
  statusCounts: { [key: string]: number } = {};

  itens: any[] = [];

  searchTerm: string = '';

  loading: boolean = true;
  initialLoad: boolean = true;

  alertPosition: boolean = false;

  isDivVisible: boolean = false;
  isLegendVisible: boolean = false;
  technician: IActivity[] = [];
  techniciansAlert: string = '';
  technicianPositionMarkers: { latitude: number, longitude: number }[] = [];
  technicians: IActivity[] = [];

  //  ROTAS DIFERENTES
  historyDirectionRender: google.maps.DirectionsRenderer;
  activitieDirectionRender: google.maps.DirectionsRenderer;

  currentTooltipIndex: number = null;
  currentAssistanceTooltipIndex: number = null;

  directionsDisplay: google.maps.DirectionsRenderer;
  directionsService: any;
  directionsRenderer: any;
  customMarkers: google.maps.Marker[] = [];
  routeMarkers: google.maps.Marker[] = [];
  infoWindow: google.maps.InfoWindow;
  pontosRepresentativos: ResourcePosition[] = [];

  isErrorModalVisible = false;
  errorMessage = '';
  icons = icons;
  allConenct = '/assets/icons/cenect-all.png';
  iconBackHome = '/assets/icons/back-home.png';
  iconBackHomeWhite = '/assets/icons/back-home-white.png';
  mapStyles: any[] = mapConfigStyle;
  activityIcons = activityIcons;
  technicalIcons = technicalIcons;
  mapConfig = {
    zoom: 4, // Google Maps zoom
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,    
    iconFullScreen: '/assets/maps/fullscreen_desativado.svg',
    latitude: -23.5505199, // Latitude
    longitude: -46.6333094 // Longitude
  };

  private markersLoaded: number = 0;
  private totalMarkers: number = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private operationsService: OperationsService,
    private filterService: FilterService,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.dropdownFilters.next({ family, states, net: [] });
  
    this.filterService.setDefaultFilters<ActivityFilters>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });
  
    this.filterService.getFiltersObservable<ActivityFilters>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((af) => this.updateData(af));
  
    // Subscription to filtered activities
    this.operationsService.filteredActivities.subscribe((result) => {
      // console.log('Atividades filtradas:', result);
      this.activities.next(result);
      this.updateTechnicians(result);
      this.checkLoadingStatus();
    });
  
    // this.operationsService.loadActivities().subscribe((activities) => {
    //   console.log('Activities by component:', activities); // Log activities loaded by component
    //   this.activities.next(activities);
    // });
  
    this.operationsService.filteredResourcePositions.subscribe((result) => {
      this.resourceOperations.next(result);
      this.checkLoadingStatus();
    });
  
    this.operationsService.isLoaded.asObservable().subscribe((isLoaded) => {
      this.loading = !isLoaded;
      if (isLoaded && this.initialLoad) {
        this.initialLoad = false; // Definir initialLoad como false após o primeiro carregamento
      }
    });
  }
  
  ngOnInit(): void {
    this.activities.subscribe((activities) => {
      this.countStatus(activities);
      this.totalMarkers = activities.length;
      this.checkLoadingStatus();
      this.showInfoWindow = new Array(activities.length).fill(false);      
    });   
  
    this.updateViewportSize();
  
    this.mapsAPILoader.load().then(() => {
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
      });
  
      this.historyDirectionRender = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#F44E3F',
          strokeOpacity: 1.0,
          strokeWeight: 5
        }
      });
  
      this.activitieDirectionRender = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#FF9C4A',
          strokeOpacity: 0.95,
          strokeWeight: 7
        }
      });
  
      this.agmMap.mapReady.subscribe(map => {
        this.directionsRenderer.setMap(map);
        this.activitieDirectionRender.setMap(map);
        this.historyDirectionRender.setMap(map);
        google.maps.event.addListener(this.directionsRenderer, 'directions_changed', () => { });
      });
    });
  
    const savedFilters = this.operationsService.loadFiltersFromStorage();
    if (savedFilters) {
      this.activeFilters.next(savedFilters);
      this.operationsService.filterActivities(savedFilters);
    }
  }  

  onChange(event: string) {
    const [key, values] = event.split(':');
    const currentFilters = this.activeFilters.value;
    currentFilters[key] = values;
    this.filterService.updateFilters<ActivityFilters>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
    this.operationsService.filterActivities(currentFilters);
    this.activeFilters.next(currentFilters);
  }  

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateViewportSize();
  }

  viewportWidth: number;
  viewportHeight: number;

  updateViewportSize() {
    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;
  }

  private updateTechnicians(activities: IActivity[]) {
    this.technicians = activities.map(activity => ({
      ...activity,
      lat: activity.position?.latitude,
      lng: activity.position?.longitude
    }));
  }

  countStatus(activities: IActivity[]) {
    this.activitiesPending = activities.filter(item => item.status === 'pending').length;
    this.activitiesStarted = activities.filter(item => item.status === 'started').length;
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  closeModal() {
    this.activeModal.close();
    this.router.navigate([MapaOperacoesComponent.OPERACOES_URL]);
  }

  showTooltip(index: number) {
    if (this.currentTooltipIndex !== null) {
      this.showInfoWindow[this.currentTooltipIndex] = false;
    }
    this.showInfoWindow[index] = true;
    this.currentTooltipIndex = index;
  }  

  showTooltipAssistance(index: number) {
    if (this.currentAssistanceTooltipIndex !== null) {
      this.showInfoWindowAssistance[this.currentAssistanceTooltipIndex] = false;
    }
    this.showInfoWindowAssistance[index] = true;
    this.currentAssistanceTooltipIndex = index;
  }

  onMapReady(map) {
    this.map = map;
  }

  updateData(activeFilters: ActivityFilters) {
    this.operationsService.filterActivities(activeFilters);
  }

  showLegend() {
    this.isLegendVisible = !this.isLegendVisible;
  }

  showErrorModal(message: string): void {
    this.errorMessage = message;
    this.isErrorModalVisible = true;
  }

  closeErrorModal(): void {
    this.isErrorModalVisible = false;
  }

  getIconActivities(marker: any) {
    const defaultIcon = this.activityIcons.iconActivitiesNot;

    const mapIconUrlWithType: Record<string, string> = {
      'RESIDENCIAL_started': this.activityIcons.iconResStart,
      'RESIDENCIAL_pending': this.activityIcons.iconResStop,
      'RESIDENCIAL_enroute': this.activityIcons.iconResRoute,
      'EMPRESARIAL_started': this.activityIcons.iconEmpStart,
      'EMPRESARIAL_pending': this.activityIcons.iconEmpStop,
      'EMPRESARIAL_enroute': this.activityIcons.iconEmpRoute      
    };
    const key = `${marker.xaProdCat1}_${marker.status}`;
    return mapIconUrlWithType[key] || defaultIcon;
  }

  getIconTechnicals(marker: any) {
    const iconDefault = this.technicalIcons.iconTecInservice;

    const bitSheetArray = marker.bitSheet.split(',').map(Number);

    const iconConditions = {
      semGps: this.technicalIcons.iconSemGps,
      semGpsAssistance: this.technicalIcons.iconSemGpsAssistance,
      semGpsRef: this.technicalIcons.iconSemGpsRef,
      tecnicoAlerta: this.technicalIcons.iconTecAlerta,
      tecnicoAlertaInroute: this.technicalIcons.iconTecAlertInroute,
      tecnicoAlertaAssistance: this.technicalIcons.iconTecAlertaAssistance,
      tecnicoAlertaRef: this.technicalIcons.iconAlertRef,
      default: iconDefault
    };

    if (bitSheetArray[0] === 1) {
      this.techniciansAlert = 'Técnico a mais de 1 km de distância da atividade.';

      if (marker.activityType === 'assistance') {
        return iconConditions.tecnicoAlertaAssistance;
      }      
      return iconConditions.tecnicoAlerta;
    }

    if (bitSheetArray[1] === 1) {
      this.techniciansAlert = 'Técnico com atraso em incio da janela de SLA até a última posição.';
      if (marker.activityType === 'assistance') {
        return iconConditions.tecnicoAlertaAssistance;
      }
      return iconConditions.tecnicoAlerta;
    }

    if (bitSheetArray[2] === 1) {
      this.techniciansAlert = 'Técnico não está se movendo na direção correta da atividade.';
      if (marker.activityType === 'assistance') {
        return iconConditions.tecnicoAlertaAssistance;
      }
      return iconConditions.tecnicoAlertaInroute;
    }

    if (bitSheetArray[3] === 1) {
      this.techniciansAlert = 'Técnico com atividade em andamento por mais de 6 horas desde o início da janela de SLA.';
      if (marker.activityType === 'assistance') {
        return iconConditions.tecnicoAlertaAssistance;
      }
      if (marker.activityType === 'RE_REFE') {
        return iconConditions.tecnicoAlertaRef;
      }
      return iconConditions.tecnicoAlerta;
    }

    if (bitSheetArray[4] === 1) {
      this.techniciansAlert = 'Técnico com GPS desigado há mais de 15 minutos.';
      if (marker.activityType === 'assistance') {
        return iconConditions.semGpsAssistance;
      }
      if (marker.activityType === 'RE_REFE') {
        return iconConditions.semGpsRef;
      }
      return iconConditions.semGps;
    }

    if (marker.status === 'enroute') {
      if (marker.resourceId) {
        return this.technicalIcons.iconTecRoute;
      }
    }

    if (marker.activityType === 'RE_REFE') {
      return this.technicalIcons.iconTecRefe;
    }

    if (marker.activityType === 'assistance') {
      return this.technicalIcons.iconTecAssistence;
    }

    return iconDefault; // ICON DEFAULT
  }

  selectActivity(activity: any, index: number) {
    if (activity.status !== '') {
      this.selectedActivityIndex = index;
      // console.log('Activity Selecionada:', activity);
  
      // Encontrar todas as atividades de suporte que correspondem ao activityId da atividade selecionada
      const supportTechniciansWithIndices = this.activities.value
        .map((act, idx) => ({ activity: act, index: idx }))
        .filter(({ activity: act }) => act.xaMainActivity === String(activity.activityId));
  
      const supportTechnicians = supportTechniciansWithIndices.map(({ activity }) => activity);
      const supportTechniciansIndices = supportTechniciansWithIndices.map(({ index }) => index);
  
      if (supportTechnicians.length >= 0) {
        const waypoints = supportTechnicians.map(tech => ({
          location: new google.maps.LatLng(tech.lat, tech.lng),
          stopover: true
        }));
  
        if (activity.lat && activity.lng && activity.latitude && activity.longitude) {
          waypoints.unshift({ location: new google.maps.LatLng(activity.lat, activity.lng), stopover: true });
  
          this.calculateRouteAssistance(
            { lat: activity.latitude, lng: activity.longitude },
            { lat: activity.lat, lng: activity.lng },
            waypoints,
            this.historyDirectionRender,
            this.customizeDrawRouteActivitie
          );
  
          supportTechniciansIndices.forEach(supportTechIndex => {
            if (supportTechIndex !== -1) {
              this.showTooltipAssistance(supportTechIndex);
            }
          });
  
        } else {
          console.error('Atividade selecionada não possui coordenadas válidas');
        }
      } else {
        console.error('Nenhum técnico de suporte encontrado para o activityId:', activity.activityId);
      }
    } else {
      this.deselectActivity();
    }
    this.showTooltip(index);
  }

  trackByActivityId(index: number, activity: any): any {
    return activity.activityId;
  }

  isValidCoordinate(coord: any): boolean {
    return typeof coord === 'number' && isFinite(coord);
  }

  loadTechnicianPositions(resourceId: string, index: number): void {
    this.operationsService.getPositions(resourceId).subscribe({
      next: response => {
        this.resourcePositions = Array.isArray(response.items) ? response.items : [];
        if (this.resourcePositions.length === 0) {
          this.showErrorModal('Nenhuma posição encontrada.');
          return;
        }
        const pontosRepresentativos = this.getRepresentativePoints(this.resourcePositions);
        this.drawRoute(pontosRepresentativos);
        this.customizeDrawHistoryRoute(response.items);
      },
      error: err => {
        this.showErrorModal('Técnico não possui histórico de posições.');
      }
    });
    this.showTooltip(index);
  }

  getMainActivity(mainActivityId: string) {
    const mainActivity = this.activities.value.find(act => act.activityId === Number(mainActivityId));
    if (mainActivity) {
      return mainActivity;
    } else {
      this.showErrorModal('Técnico de apoio não possui atividade vinculada.');
      return null;
    }
  }

  getRepresentativePoints(items: ResourcePosition[], numPontos: number = 25): ResourcePosition[] {
    const totalResults = items.length;
    const intervalo = Math.floor(totalResults / numPontos);

    const pontosSelecionados: ResourcePosition[] = [];

    for (let i = 0; i < numPontos; i++) {
      const index = i * intervalo;
      pontosSelecionados.push(items[index]);
    }

    if (!pontosSelecionados.includes(items[totalResults - 1])) {
      pontosSelecionados.push(items[totalResults - 1]);
    }

    return pontosSelecionados;
  }

  drawRoute(pontos: ResourcePosition[]): void {
    if (pontos.length < 2) return;

    const origem = { lat: pontos[0].lat, lng: pontos[0].lng };
    const destino = { lat: pontos[pontos.length - 1].lat, lng: pontos[pontos.length - 1].lng };
    const waypoints = pontos.slice(1, -1).map(ponto => ({
      location: { lat: ponto.lat, lng: ponto.lng },
      stopover: true
    }));

    this.directionsService.route(
      {
        origin: origem,
        destination: destino,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          this.directionsRenderer.setDirections(response);
          this.customizeDrawHistoryRoute(pontos);
        } else {
          console.error('Erro ao buscar direções:', status);
        }
      }
    );
  }

  calculateRouteAssistance(
    origin: { lat: number, lng: number },
    destination: { lat: number, lng: number },
    waypoints: google.maps.DirectionsWaypoint[],
    renderer: google.maps.DirectionsRenderer,
    customizeFn: (response: any) => void
  ) {
    this.directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          renderer.setDirections(response);
          customizeFn.call(this, response);
        } else {
          console.error('Erro ao buscar direções:', status);
        }
      }
    );
  }

  customizeDrawRouteActivitie(response: any): void {
    const directionsRendererInstance = this.directionsRenderer.getDirections();
    const legs = directionsRendererInstance.routes[0].legs;

    this.infoWindow = new google.maps.InfoWindow();

    legs.forEach((leg) => {
      const startMarker = new google.maps.Marker({
        position: leg.start_location,
        map: this.map,
        icon: {
          url: this.technicalIcons.iconTecPosition,
          scaledSize: new google.maps.Size(12, 12)
        }
      });

      this.customMarkers.push(startMarker);

      const endMarker = new google.maps.Marker({
        position: leg.end_location,
        map: this.map,
        icon: {
          url: this.technicalIcons.iconTecPosition,
          scaledSize: new google.maps.Size(10, 10)
        }
      });

      this.customMarkers.push(endMarker);

      if (leg.via_waypoints) {
        leg.via_waypoints.forEach((waypoint, index) => {
          const waypointMarker = new google.maps.Marker({
            position: waypoint.location,
            map: this.map,
            icon: {
              url: this.activityIcons.iconResStop,
              scaledSize: new google.maps.Size(50, 50)
            }
          });

          waypointMarker.addListener('click', () => {
            this.infoWindow.setContent(`Informação do waypoint ${index + 1}`);
            this.infoWindow.open(this.map, waypointMarker);
          });

          this.customMarkers.push(waypointMarker);
        });
      }
    });

    this.activitieDirectionRender.setOptions({
      suppressMarkers: true
    });
  }

  customizeDrawRouteAssitance(response: any): void {
    const directionsRendererInstance = this.directionsRenderer.getDirections();
    const legs = directionsRendererInstance.routes[0].legs;

    this.infoWindow = new google.maps.InfoWindow();

    legs.forEach((leg) => {
      const startMarker = new google.maps.Marker({
        position: leg.start_location,
        map: this.map,
        icon: {
          url: this.technicalIcons.iconTecAssistance,
          scaledSize: new google.maps.Size(10, 10)
        }
      });

      this.customMarkers.push(startMarker);

      const endMarker = new google.maps.Marker({
        position: leg.end_location,
        map: this.map,
        icon: {
          url: this.technicalIcons.iconTecAssistance,
          scaledSize: new google.maps.Size(12, 12)
        }
      });

      this.customMarkers.push(endMarker);

      if (leg.via_waypoints) {
        leg.via_waypoints.forEach((waypoint, index) => {
          const waypointMarker = new google.maps.Marker({
            position: waypoint.location,
            map: this.map,
            icon: {
              url: this.technicalIcons.iconTecAssistance,
              scaledSize: new google.maps.Size(50, 50)
            }
          });

          waypointMarker.addListener('click', () => {
            this.infoWindow.setContent(`Informação do waypoint ${index + 1}`);
            this.infoWindow.open(this.map, waypointMarker);
          });

          this.customMarkers.push(waypointMarker);
        });
      }
    });

    this.directionsRenderer.setOptions({
      suppressMarkers: true
    });
  }

  customizeDrawHistoryRoute(positions: ResourcePosition[]): void {
    this.routeMarkers.forEach(marker => marker.setMap(null));
    this.routeMarkers = [];

    const iconDefault = this.technicalIcons.iconTecPosition;

    this.infoWindow = new google.maps.InfoWindow();

    positions.forEach((position, index) => {
      let iconUrl = iconDefault;
      const marker = new google.maps.Marker({
        position: { lat: position.lat, lng: position.lng },
        map: this.map,
        icon: {
          url: iconUrl,
          scaledSize: new google.maps.Size(15, 15)
        }
      });

      const formattedTime = new Date(position.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      marker.addListener('click', () => {
        const contentString = `
          <div>        
          <div><strong>Posição: ${index}</strong></div>
            <strong>Horário: ${formattedTime}<strong>
          </div>
        `;
        this.infoWindow.setContent(contentString);
        this.infoWindow.open(this.map, marker);
      });

      this.routeMarkers.push(marker);
    });
  }

  onMarkerClickAssistence(resourceId: string, mainActivityId: string, index: number) {
    const mainActivityIndex = this.activities.value.findIndex(act => String(act.activityId) === String(mainActivityId));
    const mainActivity = this.activities.value[mainActivityIndex];

    const selectedActivityIndex = this.activities.value.findIndex(act => act.resourceId === resourceId);
    const selectedActivity = this.activities.value[selectedActivityIndex];

    // console.log('Atividade:', selectedActivityIndex);
    // console.log('Atividade:', mainActivityIndex);

    if (mainActivity && selectedActivity) {
      // console.log('Atividade principal encontrada:', mainActivity);
      // console.log('Atividade selecionada:', selectedActivity);

      const supportTechnicians = this.activities.value.filter(act => String(act.xaMainActivity) === String(mainActivityId));      

      const waypoints = supportTechnicians.map(tech => ({
        location: new google.maps.LatLng(tech.lat, tech.lng),
        stopover: true
      }));

      waypoints.unshift({ location: new google.maps.LatLng(selectedActivity.lat, selectedActivity.lng), stopover: true });

      this.calculateRouteAssistance(
        { lat: mainActivity.latitude, lng: mainActivity.longitude },
        { lat: supportTechnicians.length > 0 ? supportTechnicians[supportTechnicians.length - 1].lat : mainActivity.lat, lng: supportTechnicians.length > 0 ? supportTechnicians[supportTechnicians.length - 1].lng : mainActivity.lng },
        waypoints,        
        this.historyDirectionRender,
        this.customizeDrawRouteAssitance
      );

      this.showTooltip(mainActivityIndex);
      this.showTooltipAssistance(selectedActivityIndex);
    } else {
      console.error('Atividade principal ou selecionada não encontrada');
    }
  }

  onMarkerClick(resourceId: string, index: number) {        
    this.loadTechnicianPositions(resourceId, index);

    const selectedActivityIndex = this.activities.value.findIndex(act => act.resourceId === resourceId);
    const selectedActivity = this.activities.value[selectedActivityIndex];

    if (selectedActivity) {
      const supportTechnicians = this.activities.value.filter(act => String(act.xaMainActivity) === String(selectedActivity.activityId));

      const mainActivityIndex = this.activities.value.findIndex(act => String(act.xaMainActivity) === String(selectedActivity.activityId));
      const mainActivity = this.activities.value[mainActivityIndex];

      const waypoints = supportTechnicians.map(tech => ({
        location: new google.maps.LatLng(tech.lat, tech.lng),
        stopover: true
      }));

      waypoints.unshift({ location: new google.maps.LatLng(selectedActivity.lat, selectedActivity.lng), stopover: true });

      this.calculateRouteAssistance(
        { lat: selectedActivity.latitude, lng: selectedActivity.longitude },
        { lat: selectedActivity.lat, lng: selectedActivity.lng },
        waypoints,
        this.historyDirectionRender,
        this.customizeDrawRouteAssitance
      );
      this.showTooltipAssistance(mainActivityIndex);
    } else {
      console.error('Atividade selecionada não encontrada');
    }
  }

  chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  combineRoutes(directionsResults) {
    const combinedRoute = directionsResults[0];
    for (let i = 1; i < directionsResults.length; i++) {
      combinedRoute.routes[0].legs.push(...directionsResults[i].routes[0].legs);
    }
    return combinedRoute;
  }

  onMarkerReady() {
    this.markersLoaded++;
    this.checkLoadingStatus();
  }

  checkLoadingStatus() {
    const activities = this.activities.value;
    if (this.markersLoaded >= activities.length) {
      this.loading = false;
    }
  }

  clearTechnicianMarkers() {
    this.technicianPositionMarkers = [];
  }

  removeCustomMarkers(): void {
    this.customMarkers.forEach(marker => marker.setMap(null));
    this.routeMarkers.forEach(marker => marker.setMap(null));
    this.routeMarkers = [];
    this.customMarkers = [];

    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  deselectActivity() {
    this.selectedActivity = null;
    this.selectedActivityIndex = null;
    this.activityLines = null;
    this.removeCustomMarkers();
    this.showInfoWindowAssistance = this.showInfoWindowAssistance.map(() => false);
    this.showInfoWindow = this.showInfoWindow.map(() => false);
    this.clearTechnicianMarkers();
    this.techniciansAlert = '';
    this.historyDirectionRender.setDirections({
      routes: [],
      geocoded_waypoints: []
    });
    this.activitieDirectionRender.setDirections({
      routes: [],
      geocoded_waypoints: []
    });
    if (this.directionsRenderer) {
      this.directionsRenderer.setDirections({ routes: [] });
    }
  }

  getStatusActivities(activity: any): string {
    switch (activity.status) {
      case 'enroute':
        return 'Em Rota';
      case 'started':
        return 'Iniciada';
      case 'pending':
        return 'Pendente';
      default:
        return 'Desconhecido';
    }
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (term && term.length >= 8) { // Verifique se o termo de busca tem pelo menos 8 caracteres
      const filters = this.activeFilters.value;
      filters.activityId = term;
      this.operationsService.filterActivities(filters);
      this.activeFilters.next(filters);
    } else {
      this.cleanInput(); // Limpa a entrada se o termo de busca for muito curto
    }
  }

  cleanInput() {
    this.searchTerm = '';
    const filters = this.activeFilters.value;
    filters.activityId = '';
    this.operationsService.filterActivities(filters);
    this.activeFilters.next(filters);
  }
 
}
