import { AgmInfoWindow, AgmMap } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { mapConfig } from "../config/map-config";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environment';
import { error } from 'console';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { technicalIcons } from 'src/app/utils/map-icons';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { slideInOutAnimation } from 'src/app/animations/animations';

declare var google: any;

export interface ResourcePosition{
  onStop: boolean;
  position: Positions[];
}

const ResourcePositions:ResourcePosition = {
  onStop: false,
  position: []
}

export interface Positions{    
  lat: number;
  lng: number;
  time: string;
}

@Component({
  selector: 'app-locations-technicians',
  templateUrl: './locations-technicians.component.html',
  styles: [
  ],
    animations: [
      slideInOutAnimation,
      trigger("fadeInOutAnimation", [
        transition(":enter", [
          style({ opacity: 0 }),
          animate("250ms ease-in", style({ opacity: 1 })),
        ]),
        transition(":leave", [animate("250ms ease-out", style({ opacity: 0 }))]),
      ]),
    ]
})
export class LocationsTechniciansComponent implements OnInit, AfterViewInit, OnDestroy {
  private static HOME_URL = "/home";
  private timeoutId: any; 

  @Input() returnUrl: string = null;

  data: BehaviorSubject<ResourcePosition> = new BehaviorSubject(ResourcePositions); 
  @ViewChild(AgmMap, { static: false }) agmMap: AgmMap;  
  map!: google.maps.Map;
  mapConfig = mapConfig;
  markers: google.maps.Marker[] = [];
  infoWindows: google.maps.InfoWindow[] = [];
  activeInfoWindow: google.maps.InfoWindow | null = null;
  polyline!: google.maps.Polyline; // Variável para armazenar a linha

  isLoading:boolean = false;
  isSearch:boolean = true;
  isInfoTechnicians:boolean = false;
  resourceId:string = '';  
  lastPosition = {
    url: technicalIcons.iconTecPosition,
    scaledSize: {
      width: 25,
      height: 25
    }
  };
  positions:ResourcePosition;
  activeInfoWindowIndex: number | null = null;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private activeModal: NgbActiveModal
  ) { 
    this.mapConfig.zoom = 4;
    this.mapConfig.latitude = -23.5505199;
    this.mapConfig.longitude = -46.6333094;  
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {    
    this.agmMap.mapReady.subscribe((map: google.maps.Map) => {
      this.map = map;
      this.initPolyline();
    });
  }
  
  onMapReady(map: google.maps.Map) {
    this.map = map;
    this.initPolyline();
    this.map.addListener("click", () => {
      if (this.activeInfoWindow) {
        this.activeInfoWindow.close(); // Fecha o InfoWindow aberto
        this.activeInfoWindow = null;  // Reseta a variável
      }
    });
  }
  
  initPolyline(): void {
    this.polyline = new google.maps.Polyline({
      path: [],
      geodesic: true,
      strokeColor: "#00A8FF",
      strokeOpacity: 1.0,
      strokeWeight: 8,
      map: this.map
    });
  }

   
  addMarkers(positions: Positions[]): void {
    positions.forEach((position, index) => {
      const marker = new google.maps.Marker({
        position: { lat: position.lat, lng: position.lng },
        map: this.map,
        icon: {
          url: this.lastPosition.url,
          scaledSize: new google.maps.Size(22, 22)
        }
      });

      const infoWindow = new google.maps.InfoWindow({
        content: 
        `<div class="container-technicians-position">
          <h3>Informações</h3>
          <span>Horário: ${position.time}</span>          
        </div>`
      });
      
      marker.addListener("click", () => {
        if (this.activeInfoWindow) {
          this.activeInfoWindow.close();
        }
        infoWindow.open(this.map, marker);
        this.activeInfoWindow = infoWindow;
      });

      this.markers.push(marker);
      this.infoWindows.push(infoWindow);
    });
  }
  
  updatePolyline(positions: Positions[]): void {
    const path = positions.map(pos => new google.maps.LatLng(pos.lat, pos.lng));
    this.polyline.setPath(path);
  }
  
  clearMarkers(): void {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];
    this.infoWindows = [];
    this.polyline.setPath([]); // Limpa a linha
  }
  
  getPosition(resourceId:string): Observable<any>{
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/toa/geolocation/${resourceId}`;

    return this.http.get<ResourcePosition>(url);
  }
  
  onSendData(resourceId:string, isAutoCall: boolean = false):void{
    if (!isAutoCall) {
      this.isSearch = !this.isSearch;
      this.isLoading = !this.isLoading;      
      this.isInfoTechnicians = !this.isInfoTechnicians;
    }    
    this.getPosition(resourceId).subscribe(
      response =>{ 
        this.data.next(response);        
        if (isAutoCall === false) {
          this.openPopUpResponseReport('Técnico encontrado', true, false); 
        }
        this.isLoading = false;

        this.clearMarkers();
        this.addMarkers(response.position);
        this.updatePolyline(response.position);        

        const lastPosition = response.position[response.position.length - 1];
        this.mapConfig.zoom = 22;             
        this.map.setCenter(new google.maps.LatLng(lastPosition.lat, lastPosition.lng));
        this.map.setZoom(22);
        this.updatePosition(resourceId);
      },
      error => {
        this.openPopUpResponseReport('Técnico não encontrado', false, false); 
        this.updatePosition(resourceId);
      }
    )
  }
  
  updatePosition(resourceId:string):void{        
    this.timeoutId = setTimeout(() => {
      this.onSendData(resourceId, true);
      this.openPopUpResponseReport('Posição do Técnico atualizada', false, true); 
    }, 90000);
  }
  
  openPopUpResponseReport(message: string, isSuccess: boolean, isLoading: boolean = false): void {
    const panelClass = isLoading 
      ? 'loading-bar-container' 
      : (isSuccess ? 'success-bar-container' : 'failure-bar-container');
  
    const snackBarSettings: MatSnackBarConfig = {
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: panelClass,
      duration: isLoading ? undefined : 6000, // Sem duração fixa para o loading
    };
  
    this.snackBar.open(message, "Fechar", snackBarSettings);
  }

  newSearch():void{
    this.data.next({ onStop: false, position: [] });
    this.isInfoTechnicians = false;
    this.isSearch = true;
    this.isLoading = false;    
  }

  formatUppercase(): void {
    this.resourceId = this.resourceId.toUpperCase();
  }

  closeModal() {
    this.activeModal.close();
    this.cancelAllServices();
    if (this.returnUrl !== null) {
      this.router.navigate([this.returnUrl]);      
    } else {
      this.router.navigate([LocationsTechniciansComponent.HOME_URL]);
    }
  }    

  cancelAllServices(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      console.log('setTimeout cancelado');
    }
  }

  ngOnDestroy(): void {
    this.cancelAllServices();
  }

}
