import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { slideInOutAnimation, fadeInOutAnimation } from 'src/app/animations/animations';
import { defaultActiveFilters, VandalismAnalytical } from 'src/app/interfaces';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { ActivityFilters } from 'src/app/services/operations.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.sass'],
  animations: [ slideInOutAnimation, fadeInOutAnimation ]
})
export class FiltersComponent implements OnInit {
  NAMESPACE: Namespace = 'analytical';
  
  activeFilters: BehaviorSubject<ActivityFilters> = new BehaviorSubject(defaultActiveFilters);

  // PROPRIEDADES FILTRO
  isFilter:boolean = false;

  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
  }

  openFilters() {
    this.isFilter = !this.isFilter;
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    const currentFilters = this.activeFilters.value;
    currentFilters[key] = values;
    this.filterService.updateFilters<ActivityFilters>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
    // this.operationsService.filterActivities(currentFilters);
    this.activeFilters.next(currentFilters);
  }

}
