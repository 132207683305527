import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-chart',
  templateUrl: './modal-chart.component.html',
  styles: [
  ]
})
export class ModalChartComponent implements OnInit {

  // data = new BehaviorSubject({ datasets: [], labels: []});
  data:[
    [120, 150, 180, 210, 260, 300, 310, 370, 400, 450, 480, 500], // Categoria 1
    [80, 130, 160, 190, 220, 270, 280, 340, 380, 430, 460, 490],  // Categoria 2
    [100, 140, 170, 200, 240, 290, 300, 350, 390, 440, 470, 510]  // Categoria 3
  ];
  
  labels:["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

  chartConfigs = {
    type: 'bar',
    smoothLines: 'false',
  }

  constructor() { }

  ngOnInit(): void {
  }

}
