import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Permission } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const currentUser = this.authService.getCurrentUser();

    if (
      currentUser 
      && route.data.permission
      && currentUser.permissions?.includes(route.data.permission)
    ) {
      return true;
    }

    if (currentUser.permissions?.includes(Permission.RECURRENCE_VIEW)) {
      this.router.navigate(['/geral/reincidencia'], { queryParams: { returnUrl: state.url } });
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
