import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ISuggestion } from 'src/app/interfaces/models/IEffectiveness';

@Component({
  selector: 'app-suggestion-table',
  templateUrl: './suggestion-table.component.html',
  styleUrls: []
})
export class SuggestionTableComponent implements OnInit {

  @Input() suggestionPeads = [];
  @Input() isLoading: boolean = true;
  @Input() isTable: boolean = false;
  @Output() peadSuggestion = new EventEmitter<ISuggestion>();

  isMinimized = true;
  position = { x: 0, y: 275 };
  private dragging = false;
  private dragStart = { x: 0, y: 0 };

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updatePositionForRight();
  }

  constructor() { }

  ngOnInit(): void {
    this.updatePositionForRight();
  }

  private updatePositionForRight(): void {
    const elementWidth = 250;
    const elementHeight = 280;
  
    const percentageFromBottom = 30;
    const percentageFromRight = 5;  
  
    this.position.y = window.innerHeight - elementHeight - (window.innerHeight * (percentageFromBottom / 100));
    this.position.x = window.innerWidth - elementWidth - (window.innerWidth * (percentageFromRight / 100));
  }
  

  closeTable() {
    this.isTable = !this.isTable;
  }

  onMouseDown(event: MouseEvent): void {
    this.dragging = true;
    this.dragStart.x = event.clientX - this.position.x;
    this.dragStart.y = event.clientY - this.position.y;
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.dragging) return;
    this.position.x = event.clientX - this.dragStart.x;
    this.position.y = event.clientY - this.dragStart.y;
    event.preventDefault();
  }

  onMouseUp(): void {
    this.dragging = false;
  }

  togglePreview() {
    this.isMinimized = !this.isMinimized;
  }

  peadSuggestionClick(suggestion: ISuggestion): void {
    this.peadSuggestion.emit({
      lat: suggestion.lat,
      lng: suggestion.lng,
      icon: suggestion.icon || 'default-icon',
      suggestionId: suggestion.suggestionId,
      score: suggestion.score
    });
  }
}
