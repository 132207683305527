<div class="container-forgot-password h-100 col-12 col-md-12 col-lg-12">
  <div class="forgot-password m-auto col-10 col-md-8 col-lg-4">
    <div class="icon-back">
      <a href="/forgot-password" class=" pe-7s-angle-left-circle"></a>
    </div>
    <div class="forgot-text m-auto col-10 col-lg-10 col-xxl-10">
      <div class="forgot-icon pe-7s-lock"></div>
      <h1 style="font-size: 1rem">Redefinição de senha</h1>
      <p style="font-size: 0.8rem;">
        Por favor, insira no campo abaixo o código de 
        ativação que você recebeu no e-mail e redefina sua senha.
      </p>
    </div>
    <div class="form-default col-12 col-md-10 col-xxl-8 mx-auto">
      <form method="post" [formGroup]="form" (submit)="onSubmit($event)">
        <div class="input-default">
          <div class="input-icon-default lnr-keyboard"></div>
          <input
            id="activationCode"
            name="activationCode"
            type="number"
            placeholder="Código de ativação"
            class="form-control"
            formControlName="activationCode"
          >              
        </div>
        
        <div class="alert" *ngIf="form.get('activationCode')?.errors && form.get('activationCode')?.touched">
          <i class="fa fa-times-circle"></i> Código de ativação é obrigatório.
        </div>
    
        <div class="input-default" style="position: relative;">
          <div class="input-icon-default lnr-lock"></div>
          <input
            id="newPassword"
            name="newPassword"
            [(ngModel)]="newPassword"
            [type]="showNewPassword ? 'text' : 'password'"
            type="password"
            placeholder="Nova Senha"
            class="form-control"
            formControlName="newPassword"
          >
          <i 
            *ngIf="newPassword"
            [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
            (click)="toggleShowPassword()"
            style="
              position: absolute;
              right: 30px;
              top: 60%;
              transform: translateY(-50%);
              cursor: pointer;
            "
          >
          </i>
        </div>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.required && form.get('newPassword')?.touched">
          <i class="fa fa-times-circle"></i> A nova senha é obrigatória.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.caseError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos uma letra maiúscula.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.specialCharacterError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos um caractere especial.
        </p>   

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.numberError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError && !form.get('newPassword')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve conter números.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.minlength && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.numberError && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError && !form.get('newPassword')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve ter pelo menos 8 caracteres.
        </p>

        <div class="input-default" style="position: relative;">
          <div class="input-icon-default lnr-lock"></div>
          <input
            id="confirmationPassword"
            name="confirmationPassword"
            [(ngModel)]="confirmationPassword"
            [type]="showConfirmationPassword ? 'text' : 'password'"
            type="password"
            formControlName="confirmationPassword"
            placeholder="Confirme a senha"
            class="form-control"
          >
          <i
            *ngIf="confirmationPassword"
            [ngClass]="showConfirmationPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
            (click)="toggleShowConfirmationPassword()"
            style="
              position: absolute;
              right: 30px;
              top: 60%;
              transform: translateY(-50%);
              cursor: pointer;
            "
          >
          </i>
        </div>
        <p 
          class="alert" 
          *ngIf="form.get('confirmationPassword')?.errors && !passwordsMatchValidator(form) && form.get('confirmationPassword')?.touched"
        >
          <i class="fa fa-times-circle"></i> A Senha de confirmação é obrigatória;
        </p> 
        <p 
          class="alert"
          *ngIf="passwordsMatchValidator(form) && form.get('confirmationPassword')?.touched">
          <i class="fa fa-times-circle"></i> As Senhas não coincidem.
        </p>

        <div style="display: flex; align-items: center;">
          <button
            style="font-size: 0.8rem; justify-content: center;"
            type="submit"
            class="btn-default mx-auto col-lg-6 col-md-6 col-6"
          >
            ENVIAR
          </button>
        </div>      
      </form>
    </div>
  </div>
</div>