import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environment';
import { StorageService } from './storage.service';

enum OperationType {
  LOGIN = 'login',
  REGISTER = 'registro',
  NAVIGATE = 'navegação',
  LOGOUT = 'logout',
  IMAGEFEEDBACK = 'validação de imagem',
  REGISTER_OCURRENCE_BOLETIM = 'registro de boletim de ocorrência',
  CREATE_ROUTE_TOA = 'criação de rota no toa',
  CREATE_USER = 'Criou usuário',
  UPDATE_USER = 'Atualizou usuário',
  DELETE_USER = 'Deletou usuário'
}

enum ServiceType {
  FIDEREAUTH = 'fidereauth',
  FIDEREAPI = 'fidereapi',
  REGISTERAPI = 'registerapi'
}

const logsEndpoints = {
  [ServiceType.FIDEREAPI]: 'vandalism/logs/save',
  [ServiceType.FIDEREAUTH]: 'auth/logs/save',
  [ServiceType.REGISTERAPI]: 'register/logs/save'
};

interface UserInformationLog {
  userId: number,
  companyCode: number
}

interface NavigationInformationLog {
  fromPage: string,
  toPage: string
}

interface ActivityLog extends UserInformationLog, NavigationInformationLog {
  date: string;
  operation: OperationType;
  service: ServiceType
}

@Injectable({
  providedIn: 'root'
})
export class ActivityLogServiceService {

  private readonly BUFFER_LIMIT = 30;
  private readonly INTERVAL_TIME_MS = 300000;
  private readonly STORAGE_KEY = 'activity_logs';
  private readonly ENCRYPTION_KEY = environment.encryptionKey;

  private baseUrl: string = environment.baseUrl;
  private logBuffer: ActivityLog[] = [];
  private intervalSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {
    this.loadLogsFromStorage();
  }

  startLogInterval() {
    this.intervalSubscription = interval(this.INTERVAL_TIME_MS)
      .pipe(
        startWith(0),
        switchMap(() => this.sendLogsIfBufferNotEmpty())
      )
      .subscribe(
        error => console.error('Erro ao enviar logs:', error)
      );
  }

  stopLogInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private addToLogBuffer(log: ActivityLog) {
    this.logBuffer.push(log);
    this.saveLogsToStorage();

    if (this.logBuffer.length >= this.BUFFER_LIMIT) {
      this.sendLogsIfBufferNotEmpty();
    }
  }

  private saveLogsToStorage() {
    this.storageService.saveEncrypted(this.STORAGE_KEY, this.logBuffer, this.ENCRYPTION_KEY);
  }

  private loadLogsFromStorage() {
    const logs = this.storageService.loadEncrypted(this.STORAGE_KEY, this.ENCRYPTION_KEY);
    if (logs) {
      this.logBuffer = logs;
    } else {
      this.logBuffer = [];
    }
  }

  private sendLogsIfBufferNotEmpty() {
    if (this.logBuffer.length === 0) {
      return [];
    }

    const logsToSend = [...this.logBuffer];
    this.logBuffer = [];
    this.storageService.removeItem(this.STORAGE_KEY);
    const logGroups = this.groupLogsByService(logsToSend);
    const logRequests = [];

    for (const service in logGroups) {
      if (logGroups.hasOwnProperty(service)) {
        const endpoint = this.getLogEndpoint(service);
        const data = logGroups[service];
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json');

        logRequests.push(this.httpClient.post(
          endpoint,
          { 
            data: data.map((d) => ({
              userId: d.userId,
              companyCode: d.companyCode,
              date: d.date,
              fromPage: d.fromPage,
              toPage: d.toPage,
              operation: d.operation
            })),
          },
          { headers }).toPromise()
        );
      }
    }

    return Promise.all(logRequests);
  }

  private groupLogsByService(logs: ActivityLog[]) {
    return logs.reduce((groups, log) => {
      const service = log.service;
      if (!groups[service]) {
        groups[service] = [];
      }
      groups[service].push(log);
      return groups;
    }, {});
  }

  private getLogEndpoint(service: string): string {
    return `${this.baseUrl}/${logsEndpoints[service]}` || '';
  }

  private createLog({
    userId,
    companyCode,
    fromPage = null,
    toPage = null,
    operation,
    service
   }, { send }: { send?: boolean } = { send: false }  
  ) {

    const log: ActivityLog = {
      userId,
      companyCode,
      date: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      fromPage,
      toPage,
      operation,
      service
    };

    this.addToLogBuffer(log);

    if (send) {
      this.sendLogsIfBufferNotEmpty();
    }
  }

  public createLoginLog(input: UserInformationLog) {
    const activityLog = { ...input, operation: OperationType.LOGIN, service: ServiceType.FIDEREAUTH };
    this.createLog(activityLog, { send: true });
  }

  public createLogoutLog(input: UserInformationLog) {
    const activityLog = { ...input, operation: OperationType.LOGOUT, service: ServiceType.FIDEREAUTH };
    this.createLog(activityLog, { send: true });
  }

  public createImageFeedbackLog(input: UserInformationLog) {
    const activityLog = { ...input, operation: OperationType.IMAGEFEEDBACK, service: ServiceType.FIDEREAPI }
    this.createLog(activityLog);
  }

  public createNavigateLog(input: UserInformationLog & NavigationInformationLog) {
    const activityLog = { ...input, operation: OperationType.NAVIGATE, service: ServiceType.FIDEREAPI }
    this.createLog(activityLog);
  }

  public createRegisterOccurrenceReportLog(input: UserInformationLog) {
    const activityLog = { ...input, operation: OperationType.REGISTER_OCURRENCE_BOLETIM, service: ServiceType.FIDEREAPI }
    this.createLog(activityLog);
  }

  public createRouteToaLog(input: UserInformationLog) {
    const activityLog = { ...input, operation: OperationType.CREATE_ROUTE_TOA, service: ServiceType.FIDEREAPI }
    this.createLog(activityLog);
  }
}
