<div class="vertical-timeline-item vertical-timeline-element">
  <div>
      <span class="vertical-timeline-element-icon bounce-in">
        <i
          class="badge badge-dot badge-dot-xl"
          [ngClass]="{ 'is-success': notification.isSuccess, 'not-success': !notification.isSuccess }"
        ></i>
      </span>
      <div class="vertical-timeline-element-content bounce-in">
        <h4 class="timeline-title">{{ notification.caption }}</h4>
        <p>{{ notification.content }}</p>
        <span
          class="vertical-timeline-element-date"
        >{{ treatHour(notification.date) }}</span>
      </div>
  </div>
</div>