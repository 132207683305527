<div class="modal-header">
  <h4 class="modal-title">Tente Novamente</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="status-icon">
    <i [ngClass]="{
      'fas fa-check-circle text-success': !requestResult?.isError,
      'fas fa-times-circle text-danger': requestResult?.isError
      }">
    </i>  
  </div>
  <p class="status-message">{{ requestResult.message }}</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="close()"
  >Fechar
  </button>
</div>
