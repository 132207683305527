<div class="modal-error">
  <div class="modal-content">
    <div class="header-modal" style="margin-bottom: 3vh;">
      <div class="icon-box-success">
        <i class="material-icons fa fa-check"></i>
      </div>				
    </div>
    <div class="modal-body">
      {{ messages[statusCode] }}
    </div>
    <div class="footer-modal">
      <button
        class="btn btn-block"
        (click)="close('Save click')"
      >OK
      </button>
    </div>
  </div>
</div>