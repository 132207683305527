import { Injectable } from '@angular/core';
import { IColors, ITheme } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private readonly baseUrl: string = '../../assets/images/';
  private companyCode: string = '111';

  private colors: IColors = {
    primaryColor: '#0770b9',
    secondaryColor: '#f69253',
    hoverPrimaryColor: 'rgba(0, 0, 0)',
    hoverSecondaryColor: 'rgba(0, 0, 0)',
  }

  themes: Record<string, { logoUrl: string, colors: IColors }> = {
    '001': {
      logoUrl: 'logo-virtsel.png',
      colors: {
        primaryColor: '#0770b9',
        secondaryColor: '#f69253',
        hoverPrimaryColor: 'rgba(0, 0, 0)',
        hoverSecondaryColor: 'rgba(0, 0, 0, 0.8)',
      }
    },
    '111': {
      logoUrl: 'logo-claro.png',
      colors: {
        primaryColor: 'rgba(0, 0, 0)',
        secondaryColor: '#ed1c24',
        hoverPrimaryColor: 'rgba(0, 0, 0, 0.8)',
        hoverSecondaryColor: '#cc181e',
      }
    }
  }
 
  getColors() {
    return this.themes[this.companyCode].colors;
  }

  getLogo() {
    return this.baseUrl + this.themes[this.companyCode].logoUrl;
  }

  private hexToRgb(hex: string) {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => r + r + g + g + b + b);
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  private rgbToHex(r: number, g: number, b: number) {
    return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  }

  generateColorGradient(startColor: string, endColor: string, steps: number) {
    let startRgb = this.hexToRgb(startColor);
    let endRgb = this.hexToRgb(endColor);
    let colorArray = [];
  
    for (let i = 0; i < steps; i++) {
      let r = Math.round(startRgb.r + (endRgb.r - startRgb.r) * (i / (steps - 1)));
      let g = Math.round(startRgb.g + (endRgb.g - startRgb.g) * (i / (steps - 1)));
      let b = Math.round(startRgb.b + (endRgb.b - startRgb.b) * (i / (steps - 1)));
      colorArray.push(this.rgbToHex(r, g, b));
    }
  
    return colorArray;
  }
}
