import { Component, Input, OnInit } from '@angular/core';

type Color = 'red' | 'yellow' | 'green';

@Component({
  selector: 'app-card-score',
  templateUrl: './card-score.component.html',
  styleUrls: []
})
export class CardScoreComponent implements OnInit {

  @Input() scoreNumber: number;
  scoreColor: Color;

  constructor() { }

  ngOnInit(): void {
    this.scoreColor = this.scoreNumber > 90 ? 'green' : this.scoreNumber > 50 ? 'yellow' : 'red';
  }
}
