<div class="container mt-4">
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="ticket-title">Tickets de Suporte</h2>
      <button class="btn btn-primary" (click)="toggleResolvedTickets()">
        {{ showResolvedTickets ? 'Tickets Pendentes' : 'Tickets Respondidos' }}
      </button>
    </div>
  </div>
  <div *ngIf="tickets?.length === 0" class="text-center mt-4">
    <p>Nenhum ticket encontrado.</p>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let ticket of tickets" (click)="openModal(ticket)">
      <div class="card mb-4 " style="cursor: pointer;">
        <div class="card-body">
          <h5 class="card-title">ID: {{ ticket.id }}</h5>
          <p class="card-text">
            <strong>Status:</strong> {{ ticket.status }}<br>
            <strong>Data Reportada:</strong> {{ ticket.reportedDate | date:'short' }}<br>
            <strong>Prazo:</strong> {{ ticket.deadline | date:'short' }}<br>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pagination-container" *ngIf="totalPages > 1 && tickets.length > 0">
  <button class="button-pages" (click)="goToPreviousPage()" [disabled]="currentPage === 1">
    <span>Anterior</span>
  </button>
  <div class="page-input-container">
    <span>Página</span>
    <input type="number" [(ngModel)]="currentPage" (ngModelChange)="onPageChange($event)" min="1" [max]="totalPages"
      class="page-input" />
    <span>de {{ totalPages }}</span>
  </div>

  <button class="button-pages" (click)="goToNextPage()" [disabled]="currentPage === totalPages">
    <span>Próximo</span>
  </button>
</div>

<div class="modal fade show" *ngIf="selectedTicket"
  style="display: flex; background-color: rgba(0,0,0,0.5); margin-top: 30px;">
  <div class="modal-dialog modal-lx">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #D01515; padding: 8px 15px;">
        <h5 class="modal-title">Detalhes do Ticket (ID: {{ selectedTicket.id }})</h5>
        <button class="btn btn-secondary" (click)="downloadFile(selectedTicket.id)"
          style="background-color: #dd7805;; color: white; margin-left: 5%; font-size: 17px; padding: 7px 13px;">
          <i class="fa fa-download"></i>
        </button>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <p><strong>Status:</strong> {{ selectedTicket.status }}</p>
        <p><strong>Assunto:</strong> {{ subjectSelected }}</p>
        <p><strong>Data Reportada:</strong> {{ selectedTicket.reportedDate | date:'short' }}</p>
        <p><strong>Prazo:</strong> {{ selectedTicket.deadline | date:'short' }}</p>
        <p *ngIf="selectedTicket?.supportDate"><strong>Data Respondida:</strong> {{ selectedTicket.supportDate |
          date:'short' }}</p>
        <p><strong>Descrição:</strong></p>
        <div class="scrollable">
          <p>{{ selectedTicket.supportDescription }}</p>
        </div>
        <p style="margin-top: 10px;"><strong>Empresa:</strong> {{ selectedTicket.company }}</p>
        <p *ngIf="selectedTicket?.supportID"><strong>Responsável:</strong> {{ selectedTicket.supportID }}</p>
        <p *ngIf="selectedTicket?.answer"><strong>Resposta:</strong></p>
        <div class="scrollable">
          <p>{{ selectedTicket.answer }}</p>
        </div>
        <div *ngIf="hasPermission(7)">
          <label for="teamMembers"><strong>Um membro da equipe:</strong></label>
          <select id="teamMembers" class="form-select">
            <option *ngFor="let member of teamMembers" [value]="member.id">
              {{ member.fullName }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="hasPermission(7)" type="button" class="btn btn-secondary" (click)="updateTicket(selectedTicket)"
          style="background-color: #D01515; padding: 8px 15px;">
          Atualizar
        </button>
        <div *ngIf="displayingResponseForm" class="overlay">
          <div class="form-resposta-container">
            <div class="form-resposta">
              <h5 class="mb-3">Responder ao Ticket (ID: {{ selectedTicket?.id }})</h5>
              <div class="mb-3">
                <label for="response" class="form-label"><strong>Sua Resposta:</strong></label>
                <textarea id="response" [(ngModel)]="response" class="form-control" rows="4"
                  placeholder="Escreva sua resposta aqui...">
                    </textarea>
              </div>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-success me-2" (click)="sendReply(selectedTicket)">
                  Enviar Resposta
                </button>
                <button type="button" class="btn btn-secondary" (click)="cancelReply()">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="!displayingResponseForm" type="button" class="btn btn-secondary" (click)="showReplyForm()"
          style="background-color: #D01515; padding: 8px 15px;">
          Responder
        </button>
      </div>
    </div>
  </div>
</div>