import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { IColors } from '../../interfaces';

@Component({
  selector: 'app-qtde-call-qtde-patrol',
  templateUrl: './qtde-call-qtde-patrol.component.html',
  styleUrls: []
})
export class QtdeCallQtdePatrolComponent implements OnInit, OnChanges {

  title="QTDE CHAMADOS VS QTDE PATRULHA"
  @Input() data: { labels: string[], dataset: number[][] };
  @Input() colors: IColors;

  barChartData;
  backgroundColors;
  type: ChartType = 'bubble';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,  
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'QTDE PREVENTIVOS'
        } 
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'QTDE CHAMADOS'
        },
      }],
    },   
    tooltips:{ 
      mode: 'index',
      intersect: false,
      displayColors:false,     
      callbacks:{
        label: function(tooltipItem, data){            
          let dataPoint = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let xteste = dataPoint;          
          
          return[
            `${dataPoint['label']}`,
            `Qtde de chamados: ${dataPoint['x']}`,
            `Qtde de preventivo: ${dataPoint['y']}`
          ];
        }
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'bottom',
        color: 'bold'
      },
    },
  }
  
  constructor() { }

  ngOnInit(): void { 
  }

  ngOnChanges(changes: SimpleChanges) {
    this.barChartData = [
      {
        data: this.data.dataset.map((point, index) => ({
          x: point[0],
          y: point[1],
          label: this.data.labels[index],
        })),
        backgroundColor: this.colors.primaryColor,
        radius: 5,
      }
    ];
  }
}
