<div #mapContainer class="container-map-preventiva">

  <div *ngIf="loading" class="loading-screen" [@fadeInOutAnimation]>
    <div class="loading-animation">
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <div class="container-button-close-map">
    <button class="button-close-map" (click)="closeModal()">
      <i class="fa fa-compress"></i>
    </button>
  </div>


  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page full-screen" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()" alt="logo da claro">
    </div>
    <div class="container-header-filters col-11" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <div class="container-filters col-12 p-0">
        
        <div class="col-2">
          <app-select-default key="families" title="FAMÍLIA" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.families" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="classifications" title="CLASIFICAÇÃO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.classifications" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="stationTypes" title="TIPO DE ESTAÇÃO" [searchFilter]="true" [dropdownList]="dropdownFilters.value?.stationTypes"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        
        <div class="col-2">
          <app-select-default key="routeTypes" title="TIPO DE ROTA" [searchFilter]="true" [dropdownList]="dropdownFilters.value?.routeTypes"
            (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="segmentTypes" title="TIPO DE TRECHO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.segmentTypes" (event)="onChange($event)">
          </app-select-default>
        </div>
      </div>

      <div class="container-filters col-12 p-0">

        <div class="col-2">
          <app-select-default key="segments" title="TRECHOS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.segments" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="ownerships" title="Proprietários" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.ownerships" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="regionals" title="REGIONAL" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.regionals" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="states" title="ESTADO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.states" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="clusters" title="CLUSTER" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.clusters" (event)="onChange($event)">
          </app-select-default>
        </div>
      </div>
    </div>
  </div>

  <!-- CRIAÇÃO/DETALHES DA ROTA -->
  <div 
    class="container-editor-mode" 
    *ngIf="selectedSection?.details?.length > 0" 
    [@fadeInOutAnimation]
  >    
    <div id="routeInfoBox" class="info-box-route">
      <div class="info-box-buttons">
        <button class="btn-close-default" (click)="closeRouteInfoBox()">
          <i class="fa fa-times"></i>
        </button>
      </div>

      <div class="info-box-content-route">        
        <div class="content-info-title">           
          <span class="content-info-value">{{ selectedSection.title  }}</span>          
        </div>          
        <div class="content-info-route" *ngFor="let detail of selectedSection?.details">
          <span class="content-info-key">{{ detail.key }}</span>
          <span class="content-info-value">{{ detail.value }}</span>          
        </div>         
      </div>        
    </div>
  </div>    
  
  <!-- ARVORE DE PASTAR DE ROTAS -->
  <!-- <div *ngIf="!loading" class="container-list-routes"> 
    <div class="list-routes" [@fadeInOutAnimation]>      
      <mat-tree 
        [dataSource]="dataSource" 
        [treeControl]="treeControl" 
        class="list-routes-content"
      >
        
        <mat-tree-node 
          *matTreeNodeDef="let node" matTreeNodeToggle
          class="route-content-father"
        >
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon [@fadeInOutAnimation]>
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ node.name }}
        </mat-tree-node>
      
        
        <mat-tree-node 
          *matTreeNodeDef="let node; when: isLeaf" matTreeNodePadding
          class="route-content-children"
        >
          <button mat-icon-button disabled>
            <mat-icon>
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ node.name }} {{ node.count }}
          <button mat-icon-button (click)="toggleRouteVisibility(node.route.id, !node.route.enabled, map)">
            <mat-icon class="icon-visibility">{{ node.route.enabled ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-tree-node>

        
        <mat-tree-node *matTreeNodeDef="let node; when: isLeaf" matTreeNodePadding>
          <button mat-icon-button disabled></button>
          {{ node.name }}
          <button mat-icon-button (click)="onRouteClick(node.route)">
            <mat-icon>visibility</mat-icon>
          </button>
        </mat-tree-node>
      </mat-tree>      
    </div>     
  </div> -->

  <!-- * container action buttons create route/stations -->

  <div class="container-route-station-creation" [@fadeInOutAnimation] *ngIf="!loading">    
    <div 
      class="route-station-creation" 
      [@dropdownAnimation]="isDropdownOpen ? 'open' : 'closed'"
    >      
      <img [src]="iconStation" class="route-station-icon" (click)="openCreateStation()">
      <img [src]="iconRoute" class="route-station-icon" (click)="openCreateRoute()">
    </div>
    <div class="container-route-station" (click)="toggleDropdown()">
      <img [src]="iconPlus" class="route-station-icon">
    </div>
  </div>  

  <agm-map    
    [latitude]="mapConfig.center?.lat || getFirstRouteCenter().lat"
    [longitude]="mapConfig.center?.lng || getFirstRouteCenter().lng"  
    [streetViewControl]="false"
    [zoom]="mapConfig.zoom"
    [disableDefaultUI]="mapConfig.disableDefaultUI"
    [maxZoom]="mapConfig.maxZoom"
    (mapReady)="onMapReady($event)"
    #agmMap
    style="width: 100%; height: 100%;"
  ></agm-map>

  <div class="map-legend" *ngIf="!loading && (legendItems | async)?.length > 0" [@fadeInOutAnimation]>
    <div class="legend-content">
      <div class="item-legend" *ngFor="let item of legendItems | async">
        <span class="icon-legend">
          <img [src]="iconMap[getIconKey(item.icon)]">
        </span>
        <span class="name-legend">{{ item.icon.includes('null') ? 'Não Classificado' : item.name }}</span>
      </div>
    </div>
  </div>
</div>

<div [@fadeInOutAnimation] *ngIf="isEditorEnabled">
  <app-map-create-route-station
    [mode]="mode"
    (mapClosed)="onMapClosed()"    
  >  
  </app-map-create-route-station>
</div>