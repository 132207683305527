<div class="smart-table-container">

  <div class="actions-wrapper">

    <!-- Botões de Importação e Exportação -->
    <div class="buttons-container">
      <button *ngIf="importable ?? false" class="btn-import" (click)="onImport()">Import</button>
      <button *ngIf="exportable ?? false" class="btn-export" (click)="onExport()">Export</button>
    </div>

    <!-- Exibição de Spans (cards) -->
    <div *ngIf="cards != null && cards?.length > 0" class="action-items-container">
      <span *ngFor="let item of cards" class="action-item" [attr.data-value]="item.value"
        [matTooltip]="item.tooltip ?? item.description">
        <fa-icon [icon]="item.icon" class="action-icon"></fa-icon>
        <span class="description">{{ item.description }}: </span>
        <span class="value">{{ item.value }}</span>
      </span>
    </div>

    <div *ngIf="title != null" class="title-container">
      <h1 class="title">{{ title }}</h1>
    </div>

    <!-- Linha de Botões -->
    <div *ngIf="searchable" class="search-container">
      <input type="text" class="search-input" [(ngModel)]="searchQuery" placeholder="Digite para buscar..."
        (input)="onSearchInputChange()" />
    </div>
  </div>

  <!-- Tabela -->
  <div class="table-wrapper">
  
    <!-- Verifica se está carregando -->
    <div class="loading-container" *ngIf="loading; else noDataCheck">
      <div class="loading-spinner"></div>
      <p>Carregando dados...</p>
    </div>
  
    <!-- Verifica se há dados ou exibe a mensagem -->
    <ng-template #noDataCheck>
      <ng-container *ngIf="(preview ? paginatedData : data.rows)?.length > 0; else dataMessage">
        
        <!-- Tabela principal -->
        <table [ngStyle]="{ 'max-height': 'calc(' + maxHeight + ' * 2vh)' }">
          <thead>
            <tr>
              <th *ngFor="let header of data.headers" [matTooltip]="header.tooltip ?? header.column" (click)="onSort(header)" [ngClass]="{
                  'sortable': sortable && header.sortable,
                  'highlighted': header.isHighlighted,
                  'active': isCurrentSortField(header.field)
                }">
                <div class="header-content">
                  <span class="column-text">{{ header.column }}</span>
                  <!-- Indicador de ordenação -->
                  <span *ngIf="sortable && header.sortable" class="sort-icon-container">
                    <i class="sort-icon" [ngClass]="{
                        'asc': getSortDirection(header.field) === 'ASC',
                        'desc': getSortDirection(header.field) === 'DESC'
                      }"></i>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          
          <tbody style="overflow-y: auto;">
            <tr *ngFor="let row of (preview ? paginatedData : data.rows); let rowIndex = index; trackBy: trackByRow">
            
              <td *ngFor="let cell of row.values; let colIndex = index; trackBy: trackByCell"
                [attr.data-cell-id]="cell.column + '-' + cell.value" [matTooltip]="cell?.messages" [ngClass]="{
                    'cell-modified': isModified(cell, row.id),
                    'cell-invalid': !validateCell(cell, rowIndex),
                    'highlight-search': cell.isHighlighted
                  }">
                
                <!-- Exibe bolinhas se for um indicador -->
                <div *ngIf="cell.indicator; else normalCell" class="indicator-container"
                  (click)="onCellClick($event, cell, row.id)">
                  <span *ngFor="let indicator of cell.value" class="dot" [ngClass]="indicator" [matTooltip]="indicator">
                  </span>
                </div>
          
                <!-- Exibe o input normal se não for indicador -->
                <ng-template #normalCell>
                  <ng-container *ngIf="cell.badge; else inputCell">
                    <!-- Exibe o badge se a propriedade badge for verdadeira -->
                    <span class="badge" [ngStyle]="{'background-color': cell.badgeColor || '#007bff' }">
                      {{ getModified(cell, row.id) }}
                    </span>
                  </ng-container>

                  <!-- Exibe o input normal se não for badge -->
                  <ng-template #inputCell>
                    <input [value]="getModified(cell, row.id)" (input)="onInputUpdate($event, cell, row.id)"
                      [readonly]="!cell.editable" [placeholder]="(cell.editable ? 'Digite aqui...' : '')" [ngClass]="{
                        'cell-editable': cell.editable, 
                        'cell-readonly': !cell.editable,
                        'cell-clickable':  cell.clickable
                      }" (click)="onCellClick($event, cell, row.id)" />

                    <mat-icon *ngIf="cell.icon" class="input-icon"
                      [ngStyle]="{'color': cell.iconColor || '#007bff' }">{{ cell.icon }}</mat-icon>
                  </ng-template>
                </ng-template>
              </td>
            </tr>
          </tbody>      

        </table>
  
      </ng-container>
  
      <!-- Mensagem "Nenhum dado disponível" -->
      <ng-template #dataMessage>
        <div class="no-data-message">
          <p>Nenhum dado disponível para exibição.</p>
        </div>
      </ng-template>
    </ng-template>
  
  </div>
  
  <!-- Indicador de Erros -->
  <div class="error-indicator" *ngIf="!(invalidMap.size === 0)">

    <!-- Botões de navegação -->
    <button type="button" class="btn-nav" (click)="navigateErrors('prev')" [disabled]="currentErrorIndex === 0"
      aria-label="Erro Anterior">
      &#8592; <!-- Seta para esquerda -->
    </button>

    <span>{{ invalidMap.size }} erro(s) encontrado(s)</span>

    <button type="button" class="btn-nav" (click)="navigateErrors('next')" [disabled]="currentErrorIndex > 0"
      aria-label="Próximo Erro">
      &#8594; <!-- Seta para esquerda -->
    </button>
  </div>
  
  <!-- Controles de paginação e botão de envio -->
  <div class="controls-wrapper" *ngIf="!loading && this.data.rows.length > 0">
    <!-- Controles de paginação -->
    
    <div *ngIf="paginable" class="controls-container">
      <button class="btn-prev" (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1"
        aria-label="Página Anterior">
        Anterior
      </button>
    
      <label for="page-input">
        Página <input id="page-input" type="number" [min]="1" [max]="totalPages" [value]="currentPage"
          (change)="goToPage($event.target.value)" aria-label="Ir para página" /> de {{ totalPages }}
      </label>      
    
      <button class="btn-next" (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages"
        aria-label="Próxima Página">
        Próximo
      </button>
    </div>
  
    <!-- Botão de enviar -->
    <div *ngIf="submitable" class="submit-container">
      <button class="btn-submit" (click)="submitChanges()" [disabled]="invalidMap.size !== 0 || modifiedMap.size === 0"
        aria-label="Enviar Alterações">
        Enviar Alterações
      </button>
    </div>
  </div>  
</div>
