import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ticket-preview',
  templateUrl: './ticket-preview.component.html',
  styleUrls: []
})
export class TicketPreviewComponent implements OnInit, OnChanges {

  @Input() tickets: { reportedDate: string }[];
  @Input() activeYears: string[];

  calculatedTickets: { datasets: number[], legends: string[] } = { datasets: [], legends: [] };
  
  isMinimized = true;

  position = { x: 5, y: 275 };
  private dragging = false;
  private dragStart = { x: 0, y: 0 };

  constructor() {}
  
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateCalculatedTickets();
  }

  onMouseDown(event: MouseEvent): void {
    this.dragging = true;
    this.dragStart.x = event.clientX - this.position.x;
    this.dragStart.y = event.clientY - this.position.y;
    event.preventDefault(); // Evita comportamento padrão do navegador
  }

  // Component TypeScript
  onMouseMove(event: MouseEvent): void {
    if (!this.dragging) return;
    this.position.x = event.clientX - this.dragStart.x;
    this.position.y = event.clientY - this.dragStart.y;
    event.preventDefault(); // Adicione esta linhaj
  }

  onMouseUp(): void {
    this.dragging = false;
  }

  togglePreview() {
    this.updateCalculatedTickets();
    this.isMinimized = !this.isMinimized;
  }

  updateCalculatedTickets() {
    this.calculatedTickets = this.countsTicketsByYears(this.tickets, this.activeYears);
  }

  countsTicketsByYears(tickets: { reportedDate: string }[], activeYears: string[]) {
    const sortYears = (years) => {
      return years.sort((a, b) => a - b);
    }

    const sortedYears = sortYears(activeYears);
  
    const datasets = [];
    const legends = sortedYears;

    for (const year of sortedYears) {
      const monthlyCounts = new Array(12).fill(0);

      tickets.forEach((ticket) => {
        const date = new Date(ticket.reportedDate);
        const ticketYear = date.getFullYear().toString();
        const ticketMonth = date.getMonth();

        if (ticketYear === year) {
          monthlyCounts[ticketMonth]++;
        }
      });

      datasets.push(monthlyCounts);
    }

    return { datasets, legends };
  }
}
