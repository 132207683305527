import { Component, Input, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../../../theme-options';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  email: string = 'virtsel@virtsel.com';
  fullname: string = 'virtsel';
  faCalendar = faCalendar;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    public globals: ThemeOptions,
    private authService: AuthService)
  {
    const currentUser = authService.getCurrentUser();

    this.email = currentUser.email;
    this.fullname = currentUser.fullName;
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }

}
