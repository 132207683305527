<div>
   
  <!-- * HEADER FILTER PAGE -->
  <div 
    class="container-header-page" 
    [class.bg-transition]="isDivVisible" 
    #headerFilter
  >
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="logo da claro"
      >
    </div>

    <div 
      class="container-header-filters col-10" 
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <!-- * TOP FILTER -->
      <div class="container-filters col-12 p-0">        
        <div class="col-2">              
          <app-select-default
            key="regionals"
            title="REGIONAIS"
            [searchFilter]="true"          
            [selectedItems]="selectedFilters.value.regionals"
            [dropdownList]="dropdownFilters.value?.regionals"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
        <div class="col-2">
          <app-select-default
            key="directors"
            title="GRUPO"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.directors"
            [dropdownList]="dropdownFilters.value?.directors"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>

        <div class="col-2">              
          <app-select-default
            key="states"
            title="ESTADO"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.states"
            [dropdownList]="dropdownFilters.value.states"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default
            key="clusters"
            title="CLUSTER"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.clusters"
            [dropdownList]="dropdownFilters.value.clusters"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default
            key="subclusters"
            title="SUBCLUSTER"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.subclusters"
            [dropdownList]="dropdownFilters.value.subclusters"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
        
        <div class="col-2">
          <app-select-default
            key="cities"
            title="CIDADE"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.cities"
            [dropdownList]="dropdownFilters.value.cities"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
      </div> <!-- TOP FILTER -->

      <!-- * BOTTOM FILTER -->
      <div class="container-filters col-12 p-0">
        <div class="col-lg-6 col-md-6">
          <app-data-picker-default
            [dates]="getRangeDate()"
            [backgroundColor]="'#fff'"
            [color]="'#000'"
            (event)="onChange($event)"
          >
          </app-data-picker-default>
        </div>
        <div class="col-2">
          <app-select-default
            key="initiatives"
            title="INICIATIVAS"
            [searchFilter]="true"
            [selectedItems]="dropdownFilters.value.initiatives"
            [dropdownList]="dropdownFilters.value.initiatives"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
        <div class="col-2">
          <app-select-default
            key="companies"
            title="EMPRESA"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.companies"
            [dropdownList]="dropdownFilters.value.companies"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
        <div class="col-2">
          <app-select-default
            key="technicians"
            title="TÉCNICO"
            [searchFilter]="true"
            [selectedItems]="selectedFilters.value.technicians"
            [dropdownList]="dropdownFilters.value.technicians"
            (event)="onChange($event)"
          >
          </app-select-default>
        </div>
      </div> <!-- BOTTOM FILTER -->
    </div>    
  </div> 

  <div class="cards-container">    
    <div class="col-3">
      <app-card-default
        [icon]="icons.percentage"
        [cardTitle]="'Efetividade Média'"
        [cardValue]="averageEffectiveness"
      ></app-card-default>
    </div>
  </div>
  <app-table 
    [effectivenessData]="effectivenessData"
    [isLoading]="loading"
    [currentPage]="currentPage"        
    [totalPages]="totalPages"
    (pageChange)="onPageChange($event)"
  ></app-table>
</div>
