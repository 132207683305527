<div 
  class="preview-container"
  (mousedown)="onMouseDown($event)"
  (document:mousemove)="onMouseMove($event)"
  (document:mouseup)="onMouseUp()"
  [style.top.px]="position.y"
  [style.left.px]="position.x"
  [ngClass]="{'minimized': isMinimized}"
  >
    
  <div class="row-buttons">
    <button (click)="togglePreview()" [ngClass]="{ 'btn-minimized': isMinimized }">
      <span *ngIf="!isMinimized">
        <i class="fa fa-minus"></i>
      </span>  
      <span *ngIf="isMinimized" class="custom-icon-size">
        <i class="fa fa-bar-chart"></i>
      </span>      
    </button>
  </div>

  <div class="preview-container-content" *ngIf="!isMinimized">
    <app-ocurrences-comparision-by-years-bar
      [data]="calculatedTickets"
    >
    </app-ocurrences-comparision-by-years-bar>
  </div>
</div>
