<div>
  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img [src]="
          isDivVisible
            ? '../../../../assets/images/claro_logo_70.png'
            : '../../../../assets/images/claro_logo_50.png'
        " (click)="openHeaderFilter()" alt="logo da claro" />
    </div>

    <div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <!-- * TOP FILTER -->
      <div class="container-filters col-12 p-0">

        <div class="col-2">
          <app-select-default key="segments" title="TRECHOS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.segments" (event)="onChange($event)">
          </app-select-default>
        </div>
        
        <div class="col-2">
          <app-select-default key="projects" title="PROJETOS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.projects" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="status" title="STATUS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.status" (event)="onChange($event)">
          </app-select-default>
        </div>
      </div>
    </div>
    <!-- TOP FILTER -->
  </div>

  <div class="container">
    <!-- Contêiner da Tabela -->
    <div class="table-container" [ngStyle]="isDivVisible ? { height: '73vh' }: { height: '75vh' }">
      <!-- Estado de erro -->
      <div *ngIf="error.value" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
        <button class="retry-button" (click)="retryFetchData()">
          Tentar novamente
        </button>
      </div>

      <!-- Tabela -->
      <app-smart-table 
        *ngIf="!error.value" 
        [data]="data | async" 
        [title]="(recurrenceTableSettings | async).title"
        [loading]="loading | async" 
        [paginable]="true" [searchable]="true" 
        [sortable]="true"
        [submitable]="(recurrenceTableSettings | async).submitable" 
        [preview]="false" 
        [importable]="false"
        [exportable]="(recurrenceTableSettings | async).exportable" 
        [permissions]="userPermissions"
        [currentPage]="currentPage | async" 
        [totalPages]="totalPages | async" 
        [itemsPerPage]="itemsPerPage"
        [importDataCallback]="openImportDataModal" 
        [exportDataCallback]="exportData"
        [openModal]="openPreventiveMapPopup" 
        (onPageChange)="handlePageChange($event)"
        (onSubmitChanges)="handleChanges($event)"
      >
      </app-smart-table>
      <!-- <app-chart-default
        [type]="'line'"  
        [data]="[
          [512],  
          [363], 
          [133],  
          [877]  
        ]"
        [labels]="['JAN', 'FEV', 'MAR', 'ABR']"
        [colors]="['#ffcc00']"
        [seriesLabels]="['Qtde de chamados']"
        [horizontal]="false"
        [showLabels]="true"
        [stackedSeries]="0"
        [isPercentage]="false"
        [showLegend]="false"
        [mergeTooltip]="true"
        [hideDataLabels]="true"
        [showYAxisLabels]="true"
        [padding]="{ top: 0, left: 0, right: 0, bottom: 0 }"
        [smoothLines]="false"
        [showPointMarkers]="false"
        [barWidths]="[0.9]"
      ></app-chart-default> -->
    </div>
  </div>
</div>

<!-- <app-modal-chart></app-modal-chart> -->