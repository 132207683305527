import { Component, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { NgxImgZoomService } from 'ngx-img-zoom';

type ZoomType =  'fullscreen' | 'magnifier';

@Component({
  selector: 'app-zoom-image-pop-up',
  templateUrl: './zoom-image-pop-up.component.html',
  styleUrls: []
})
export class ZoomImagePopUpComponent implements OnInit {
  
  @Input() modalData: {
    src: SafeUrl
  };

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  imageBase64: string = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  zoomActive: ZoomType = 'fullscreen';

  constructor(private ngxImgZoom: NgxImgZoomService, private activeModal: NgbActiveModal) 
  {
    this.ngxImgZoom.setZoomBreakPoints([{w: 100, h: 100}]);
  }

  ngOnInit(): void {
  }
  
  isFullscreen() {
    return this.zoomActive === 'fullscreen';
  }

  changeZoom() {
    this.zoomActive = 'magnifier';
  }

  closeModal() {
    this.activeModal.close();
  }
  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady() {
    console.log('Cropper ready');
  }

  loadImageFailed() {
    console.log('Load failed');
  }
}