import { ILocation, IPointMap } from "../models";

export namespace VandalismRoute {

  export interface Filters {
    routes: Array<{
      routeId: number;
      routeName: string;
    }>,
    directors: string[];
    states: string[];
    cities: string[];
    family: string[];
  }

  export interface Dropdown {
    routes: Array<{
      routeId: number;
      routeName: string;
    }>,
    family: string[],
    directors: string[],
    states: string[],
    cities: string[]
  }

  export interface Selected {
    family: string[],
    directors: string[],
    states: string[],
    cities: string[]
  }

  export interface Params {
    routeId: string;
    directors: string;
    states: string;
    cities: string;
    family: string;
  }

  export interface Route {
    origin: ILocation,
    stops: ILocation[],
    destination: ILocation;
  }

  export interface Response {
    filters: Filters;
    dropdownList: Dropdown;
    selectedList: Selected;
    activeFilters: VandalismRoute.Params;
    routeMap: {
      score: number;
      expected: Route;
      realized: Route;
    }
    routeTable: IPointMap[]
  }

  export const filterInitialState = {
    routes: [],
    directors: [],
    states: [],
    cities: [],
    family: []
  }

  const initialRoute: Route = {
    origin: null,
    stops: [],
    destination: null,
  }

  export class Output {
    filters: Filters;
    dropdownList: Dropdown;
    selectedList: Selected;
    activeFilters: VandalismRoute.Params;
    routeMap = {
      score: 0,
      expected: initialRoute,
      realized: initialRoute,
    };
    routeTable = [];

    constructor(response?: Response) {
      if (response) {
        this.dropdownList = {
          ...response.dropdownList,
          ...response.filters.routes
        };
        this.selectedList = response.selectedList;
        this.routeMap = response.routeMap;
        this.routeTable = response.routeTable;
      }
    }

    getRouteCard(isDirectoryAndRouteSelected: boolean) {

      if (this.routeTable.length === 0 || !isDirectoryAndRouteSelected) {
        return {
          expected: {
            start: 'Informação indisponível',
            end: 'Informação indisponível'
          }
        }
      } 

      const hours = this.routeTable[0].hours.split('-');

      return {
        expected: { start: hours[0], end: hours[1] }
      }
    }

    getRouteMap() {
      return this.routeMap;
    }

    getRouteTable() {
      return this.routeTable;
    }
  }
}
