<div class="card-chart chart-small col-md-12 col-xxl-12">
  <!-- <div class="card-chart-header chart-small-header">
    <app-title-default [content]="title"
    >
    </app-title-default>
  </div>
  <hr> -->
  <div class="card-chart-body chart-small-body">
    <!-- <div 
      class="d-flex"
      style="width: 100%; height: 100%; position: relative;"
    >
      <canvas
      baseChart
      [datasets]="barChartData" 
      [chartType]="type"
      [options]="options"
      [colors]="backgroundColors"    
      >
      </canvas>
    </div> -->
  </div>
</div>
