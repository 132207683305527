import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-delete-pop-up-modal',
  templateUrl: './delete-pop-up-modal.component.html',
  styleUrls: []
})
export class DeletePopUpModalComponent implements OnInit {

  userId: number;
  faClosedCaptioning = icons.closedCaptioning;
  
  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  deleteUser() {
    this.userService.delete({ id: this.userId });
    this.close('deleted');
  }

  close(result: string) {
    this.activeModal.close(result)
  }
}
