<div>
  <!-- * HEADER FILTER PAGE -->
  <div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>
    <div class="header-img-filter col-1">
      <img [src]="
          isDivVisible
            ? '../../../../assets/images/claro_logo_70.png'
            : '../../../../assets/images/claro_logo_50.png'
        " (click)="openHeaderFilter()" alt="logo da claro" />
    </div>

    <div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
      <!-- * TOP FILTER -->
      <div class="container-filters col-12 p-0">

        <div class="col-2">
          <app-select-default key="segments" title="TRECHOS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.segments" (event)="onChange($event)">
          </app-select-default>
        </div>
        
        <div class="col-2">
          <app-select-default key="regionals" title="REGIONAL" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.regionals" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="directors" title="DIRETORIA" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.directors" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="states" title="ESTADO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.states" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="clusters" title="CLUSTER" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.clusters" (event)="onChange($event)">
          </app-select-default>
        </div>

        <div class="col-2">
          <app-select-default key="types" title="TIPO" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.segmentTypes" (event)="onChange($event)">
          </app-select-default>
        </div>

        <!-- <div class="col-2">
          <app-select-default key="families" title="FAMÍLIA" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.families" (event)="onChange($event)">
          </app-select-default>
        </div> -->

        <!-- <div class="col-2">
          <app-select-default key="status" title="STATUS" [searchFilter]="true"
            [dropdownList]="dropdownFilters.value?.status" (event)="onChange($event)">
          </app-select-default>
        </div> -->
      </div>
    </div>
    <!-- TOP FILTER -->
  </div>

  <div class="container">
    <!-- Contêiner da Tabela -->
    <div class="table-container" [ngStyle]="isDivVisible ? { height: '73vh' }: { height: '75vh' }">
      <!-- Estado de erro -->
      <div *ngIf="error.value" class="error-container">
        <p class="error-message">Ocorreu um erro ao carregar os dados.</p>
        <button class="retry-button" (click)="retryFetchData()">
          Tentar novamente
        </button>
      </div>

      <!-- Tabela -->
      <app-smart-table *ngIf="!error.value" [data]="data | async" [title]="(recurrenceTableSettings | async).title"
        [cards]="cards | async" [loading]="loading | async" [paginable]="true" [searchable]="true" [sortable]="true"
        [submitable]="(recurrenceTableSettings | async).submitable" [preview]="false" [importable]="false"
        [exportable]="(recurrenceTableSettings | async).exportable" [permissions]="userPermissions"
        [currentPage]="currentPage | async" [totalPages]="totalPages | async" [itemsPerPage]="itemsPerPage"
        [importDataCallback]="openImportDataModal" [exportDataCallback]="exportData"
        [openModal]="openPreventiveMapPopup" (onPageChange)="handlePageChange($event)"
        (onSubmitChanges)="handleChanges($event)">
      </app-smart-table>
    </div>
  </div>
</div>