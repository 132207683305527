import { Injectable } from '@angular/core';
import { VandalismAnalytical } from 'src/app/interfaces/dtos/VandalismAnalytical';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ITicket, VandalismRoute } from '../interfaces';
import { FieldFilter } from '../interfaces/models/IFieldFilter';
import { formatDate } from '@angular/common';
import { analyticalDropdownList, causeGroups, routesDropdownList } from 'src/app/utils/constants';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AnalyticalService {

  private DATA_PATH = '/assets/data';
  private FILE_EXTENSION = '.json';

  private organizeTickets = {};

  private tickets: ITicket[] = [];
  private ticketsSubject = new BehaviorSubject<ITicket[]>([]);

  isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  baseUrl: string;

  constructor (private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
   
    this.readTicketsData();
  }

  private async fetchCauseData(): Promise<ITicket[]> {
    const filename = 'VANDALISMO'
    const pathname = `${this.DATA_PATH}/${filename}${this.FILE_EXTENSION}`;
    const response = await fetch(pathname);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      throw new Error(`Erro na solicitação: ${response.statusText}`);
    }
  }

  private async loadData(): Promise<ITicket[]> {
    return this.fetchCauseData();
  }

  private updateApplicationState(data: ITicket[]) {
    this.ticketsSubject.next(data);
    this.tickets = data;
    this.isLoaded.next(true);
  }

  private async readTicketsData() {
    this.isLoaded.next(false);
    try {
      const jsonDataArray = await this.loadData();
      this.updateApplicationState(jsonDataArray);
    } catch (error) {
      console.error('Erro ao carregar JSON:', error);
    }
  }

  ticketsAll() {
    return this.tickets;
  }

  getTicketsById(sequenceId: string) {
    const params = new HttpParams().set('sequenceId', sequenceId);
    const url = `${this.baseUrl}/vandalism/analytical/tickets/search`
    return this.http.get(url, { params });
  }

  isStringEmpty(str: string) {
    return !str || str.trim() === ''
  };

  extractValuesByHierarchy<T extends Object>(sourceObject: T, activeFilters: any, keys: string[]): any {
    const values = {};

    keys.forEach((key) => {
      if (sourceObject.hasOwnProperty(key)) {
        values[key] = this.isStringEmpty(activeFilters[key])
          ? sourceObject[key].map(({ value }) => value)
          : activeFilters[key].split(',')
      }
    });

    return values;
  }

  organizeTicketsByHierarchy(tickets: ITicket[]) {
    const hierarchy = {};
  
    tickets.forEach(ticket => {
      const { subcluster, city } = ticket;

      const regional = ticket.regional.toUpperCase();
      const group = ticket.directors.toUpperCase();
      const cluster = ticket.cluster;
      const state = ticket.state;

      let treatGroup = group;
      if (group === 'REGIONAL SÃO PAULO') {
        treatGroup = 'GRUPO SP CAPITAL'
      };

      if (!hierarchy[regional]) hierarchy[regional] = {};
      if (!hierarchy[regional][treatGroup]) hierarchy[regional][treatGroup] = {}
      if (!hierarchy[regional][treatGroup][state]) hierarchy[regional][treatGroup][state] = {};
      if (!hierarchy[regional][treatGroup][state][cluster]) hierarchy[regional][treatGroup][state][cluster] = {};
      if (!hierarchy[regional][treatGroup][state][cluster][subcluster])  hierarchy[regional][treatGroup][state][cluster][subcluster] = new Set();

      hierarchy[regional][treatGroup][state][cluster][subcluster].add(city);
    });

    return hierarchy;
  }

  convertFilterString(filterValue: string, toSet: boolean = true): Set<string> | string[] {
    if (this.isStringEmpty(filterValue)) return toSet ? new Set() : [];
    const values = filterValue.split(',').map(value => value.trim());
    return toSet ? new Set(values) : values;
  }
  
  mergeObjectsInArray(objectsArray: Object[]): Object {
    return objectsArray.reduce((a, c) => _.merge(a, c), {});
  }
  
  getFilteredOrAllItems(activeFilters: any, filterName: any, defaultItems: string[]) {
    if (this.isStringEmpty(activeFilters[filterName])) {
      return defaultItems;
    } else {
      return activeFilters[filterName].split(',').map(item => item.trim());;
    }
  }

  extractDateComponents(dateString: string): { year: string, month: string, day: string } {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    return { year, month, day };
  }

  isFieldIncluded(item: any, fieldName: string, filterValues: string): boolean {
    if (!filterValues.trim()) return true;
    if (!item || !item.hasOwnProperty(fieldName)) return false;
    const itemValue = String(item[fieldName]).toUpperCase();
    const allowedValues = filterValues.toUpperCase().split(',').map(val => val.trim());
    return allowedValues.includes(itemValue);
  }

  isDateIncluded(value: string, filterValues: string): boolean {
    if (!filterValues.trim()) return true;
    const allowedValues = filterValues.split(',').map(val => val.trim());
    return allowedValues.includes(value);
  }

  getDateFromFilters(filters: VandalismAnalytical.InputParams) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const endYear = filters.year ? filters.year.split(',').map(Number).sort((a, b) => b - a)[0] : currentYear;
    const endMonth = filters.month ? filters.month.split(',').map(Number).sort((a, b) => b - a)[0] : currentMonth;

    const daysInMonth = new Date(endYear, endMonth, 0).getDate();
    let endDay;
    if (filters.day && filters.day.trim()) {
      endDay = filters.day.split(',').map(Number).sort((a, b) => b - a).find((day) => day <= daysInMonth) || daysInMonth;
    } else {
      endDay = daysInMonth;
    }
    return new Date(endYear, endMonth - 1, endDay);
  }

  filterTicketsByDate(ticket: ITicket, filters: VandalismAnalytical.InputParams) {
    const cutoffDate = this.getDateFromFilters(filters);
    const reportedDate = new Date(ticket.reportedDate);
    return reportedDate <= cutoffDate;
  }

  getTickets() {
    return this.ticketsSubject.value;
  }

  getFilters(activeFilters: VandalismAnalytical.InputParams, tickets: ITicket[]) {

    const keys: string[] = ['regional', 'group', 'state', 'cluster'];
  
    const _regionals = this.organizeTicketsByHierarchy(tickets);

    const { regional } = this.extractValuesByHierarchy({ ...analyticalDropdownList }, activeFilters, keys);
    const _groups = this.mergeObjectsInArray(Object.values(_regionals));
    const _states = this.mergeObjectsInArray(Object.values(_groups));
    const _clusters = this.mergeObjectsInArray(Object.values(_states));
    const _subclusters = this.mergeObjectsInArray(Object.values(_clusters));

    const groups = this.getFilteredOrAllItems(activeFilters, 'group', Object.keys(_groups));
    const states = this.getFilteredOrAllItems(activeFilters, 'state', Object.keys(_states));
    const clusters = this.getFilteredOrAllItems(activeFilters, 'cluster', Object.keys(_clusters));
    const subclusters = this.getFilteredOrAllItems(activeFilters, 'subcluster', Object.keys(_subclusters))
    const cities = this.getFilteredOrAllItems(activeFilters, 'city', Object.values(_subclusters).reduce((a, c) => [...a, ...c] , []));

    let selectedSubcluster: string[] = subclusters.filter((s) => {
      return cities.some((c) => {
        return _subclusters[s]?.has(c);
      })
    });

    let selectedCluster: string[] = clusters.filter((cluster) => {
      return selectedSubcluster.some((subcluster) => {
        return Object.keys(_clusters[cluster] ?? {})?.includes(subcluster)
      })
    });

    let selectedStates: string[] = states.filter((state) => {
      return selectedCluster.some((cluster) => {
        return Object.keys(_states[state] ?? {}).includes(cluster);
      });
    });
  
    let selectedGroups: string[] = groups.filter((group) => {
      return selectedStates.some((state) => {
        return Object.keys(_groups[group] ?? {}).includes(state)
      })
    });

    let selectedRegionals: string[] = regional.filter((regional) => {
      return selectedGroups.some((group) => {
        return Object.keys(_regionals[regional] ?? {}).includes(group);
      });
    });

    const dropdownGroups = analyticalDropdownList.group.filter((group) => {
      return selectedRegionals.some((regional) => {
        if (_regionals[regional] !== undefined) return Object.keys(_regionals[regional]).includes(group.value)
        return false
      })
    });

    const dropdownStates = analyticalDropdownList.state.filter((state) => {
      return selectedGroups.map((g) => g).some((group) => {
        if (_groups[group] !== undefined) return Object.keys(_groups[group] ?? {}).includes(state)
        return false
      }) 
    });

    const dropdownClusters = analyticalDropdownList.cluster.filter((cluster) => {
      return selectedStates.some((state) => {
        if (_states[state] !== undefined) return Object.keys(_states[state] ?? {}).includes(cluster)
        return false
      });
    });

    const dropdownSubclusters = analyticalDropdownList.subcluster.filter((subcluster) => {
      return selectedCluster.some((cluster) => {
        if (_clusters[cluster] !== undefined) return Object.keys(_clusters[cluster] ?? {})?.includes(subcluster)
        return false;
      });
    });

    const dropdownCities = _.sortBy([...new Set(analyticalDropdownList.city.filter((city) => {
      return selectedSubcluster.some((subcluster) => {
        if (_subclusters[subcluster] !== undefined) return _subclusters[subcluster]?.has(city)
        return false;
      });
    }))]);

    const filterForValidSelections = (
      selectedItems: string[], 
      dropdownList: any[],
      boolean: boolean = false
    ) => {
      const haveEqualLength = (selectedItems.length === dropdownList.length && dropdownList.length !== 1) && (selectedItems.length !== 0 && dropdownList.length !== 0);
      const isSelectionLongerThanDropdown = selectedItems.length > dropdownList.length;
      return haveEqualLength || isSelectionLongerThanDropdown || boolean
        ? [] 
        : selectedItems;
    }

    // O número máximo é importante pois pode causar a seleção de todos os items do dropdown
    selectedRegionals = selectedRegionals.length === 3 ? [] : selectedRegionals;
    selectedGroups = filterForValidSelections(selectedGroups, dropdownGroups);
    selectedStates = filterForValidSelections(selectedStates, dropdownStates);
    selectedCluster = filterForValidSelections(selectedCluster, dropdownClusters);
    selectedSubcluster = filterForValidSelections(selectedSubcluster, dropdownSubclusters);
    
    let dropdownList = {
      ...analyticalDropdownList,
      state: dropdownStates,
      group: dropdownGroups,
      cluster: dropdownClusters,
      subcluster: dropdownSubclusters,
      city: dropdownCities,
      causeGroups: causeGroups
    }

    let selectedList =  { 
      regional: new Set(selectedRegionals),
      group: new Set(selectedGroups),
      state: selectedStates,
      cluster: selectedCluster,
      subcluster: selectedSubcluster,
      city: this.isStringEmpty(activeFilters.city) ? [] : activeFilters.city.split(','),
      causeGroup: this.isStringEmpty(activeFilters.causeGroup) ? [] : activeFilters.causeGroup.split(','),
      net: new Set(this.isStringEmpty(activeFilters.net) ? [] : activeFilters.net.split(',')),
      year: new Set(this.isStringEmpty(activeFilters.year) ? [] : activeFilters.year.split(',')),
      day: new Set(this.isStringEmpty(activeFilters.day) ? [] : activeFilters.day.split(',')),
      month: new Set(this.isStringEmpty(activeFilters.month) ? [] : activeFilters.month.split(',')),
      family: new Set(this.isStringEmpty(activeFilters.family) ? [] : activeFilters.family.split(',')),
      isPead: activeFilters.isPead,
      isAccumulated: activeFilters.isAccumulated
    }

    return {
      dropdownList,
      selectedList: new BehaviorSubject(selectedList),
    }
  }

  getFiltersForRoutes(activeFilters: VandalismRoute.Params, tickets: ITicket[]) {

    const keys: string[] = ['regional', 'group', 'state', 'cluster'];
  
    const _regionals = this.organizeTicketsByHierarchy(tickets);
    const { regional } = this.extractValuesByHierarchy({ ...analyticalDropdownList }, activeFilters, keys);
    const _groups = this.mergeObjectsInArray(Object.values(_regionals));
    const _states = this.mergeObjectsInArray(Object.values(_groups));
    const _clusters = this.mergeObjectsInArray(Object.values(_states));
    const _subclusters = this.mergeObjectsInArray(Object.values(_clusters));
    const groups = this.getFilteredOrAllItems(activeFilters, 'directors', Object.keys(_groups));
    const states = this.getFilteredOrAllItems(activeFilters, 'states', Object.keys(_states));
    const clusters = this.getFilteredOrAllItems(activeFilters, 'cluster', Object.keys(_clusters));
    const subclusters = this.getFilteredOrAllItems(activeFilters, 'subcluster', Object.keys(_subclusters));
    const cities = this.getFilteredOrAllItems(activeFilters, 'cities', Object.values(_subclusters).reduce((a, c) => [...a, ...c] , []));

    let selectedSubcluster: string[] = subclusters.filter((s) => {
      return cities.some((c) => {
        return _subclusters[s]?.has(c);
      })
    });

    let selectedCluster: string[] = clusters.filter((cluster) => {
      return selectedSubcluster.some((subcluster) => {
        return Object.keys(_clusters[cluster] ?? {})?.includes(subcluster)
      })
    });

    let selectedStates: string[] = states.filter((state) => {
      return selectedCluster.some((cluster) => {
        return Object.keys(_states[state] ?? {}).includes(cluster);
      });
    });
  
    let selectedGroups: string[] = groups.filter((group) => {
      return selectedStates.some((state) => {
        return Object.keys(_groups[group] ?? {}).includes(state)
      })
    });

    let selectedRegionals: string[] = regional.filter((regional) => {
      return selectedGroups.some((group) => {
        return Object.keys(_regionals[regional] ?? {}).includes(group);
      });
    });

    let selectedFamily = activeFilters.family.split(',').filter((value) => value !== "");
    const dropdownFamily = ['EMPRESARIAL', 'RESIDENCIAL'];

    const dropdownGroups = routesDropdownList.group.filter((group) => {
      return selectedRegionals.some((regional) => {
        if (_regionals[regional] !== undefined) return Object.keys(_regionals[regional]).includes(group)
        return false
      })
    });

    const dropdownStates = analyticalDropdownList.state.filter((state) => {
      return selectedGroups.map((g) => g).some((group) => {
        if (_groups[group] !== undefined) return Object.keys(_groups[group] ?? {}).includes(state)
        return false
      }) 
    });

    const dropdownClusters = analyticalDropdownList.cluster.filter((cluster) => {
      return selectedStates.some((state) => {
        if (_states[state] !== undefined) return Object.keys(_states[state] ?? {}).includes(cluster)
        return false
      });
    });

    const dropdownSubclusters = analyticalDropdownList.subcluster.filter((subcluster) => {
      return selectedCluster.some((cluster) => {
        if (_clusters[cluster] !== undefined) return Object.keys(_clusters[cluster] ?? {})?.includes(subcluster)
        return false;
      });
    });

    const dropdownCities = _.sortBy([...new Set(analyticalDropdownList.city.filter((city) => {
      return selectedSubcluster.some((subcluster) => {
        if (_subclusters[subcluster] !== undefined) return _subclusters[subcluster]?.has(city)
        return false;
      });
    }))]);

    const filterForValidSelections = (
      selectedItems: string[], 
      dropdownList: any[],
      boolean: boolean = false
    ) => {
      const haveEqualLength = (selectedItems.length === dropdownList.length && dropdownList.length !== 1) && (selectedItems.length !== 0 && dropdownList.length !== 0);
      const isSelectionLongerThanDropdown = selectedItems.length > dropdownList.length;
      return haveEqualLength || isSelectionLongerThanDropdown || boolean
        ? []
        : selectedItems;
    }

    // O  máximo é importante pois pode causar a seleção de todos os items do dropdown
    selectedRegionals = selectedRegionals.length === 3 ? [] : selectedRegionals;
    selectedFamily = selectedFamily.length === 2 ? [] : selectedFamily;
    selectedGroups = filterForValidSelections(selectedGroups, dropdownGroups);
    selectedStates = filterForValidSelections(selectedStates, dropdownStates);
    selectedCluster = filterForValidSelections(selectedCluster, dropdownClusters);
    selectedSubcluster = filterForValidSelections(selectedSubcluster, dropdownSubclusters);
    
    let dropdownList = {
      states: dropdownStates,
      directors: dropdownGroups,
      cities: dropdownCities,
      family: dropdownFamily
    }

    let selectedList =  { 
      family: selectedFamily,
      directors: selectedGroups,
      states: selectedStates,
      cities: this.isStringEmpty(activeFilters.cities) ? [] : activeFilters.cities.split(',')
    }

    return {
      dropdownList,
      selectedList,
    }
  }

  filterTickets(filters: VandalismAnalytical.InputParams): ITicket[] {
    try {
      if (filters === undefined) return [];

      const filteredTickets = this.tickets.filter((ticket: ITicket) => {
        if (ticket?.reportedDate === null || ticket?.reportedDate === undefined) return false;
  
        const fieldFilters: FieldFilter<ITicket>[] = [
          { field: 'productOperational1', filter: filters.family },
          { field: 'productOperational2', filter: filters.net },
          { field: 'state', filter: filters.state },
          { field: 'regional', filter: filters.regional },
          { field: 'directors', filter: filters.group },
          { field: 'cluster', filter: filters.cluster },
          { field: 'subcluster', filter: filters.subcluster },
          { field: 'city', filter: filters.city }
        ]

        for (let { field, filter } of fieldFilters) {
          if (!this.isFieldIncluded(ticket, field, filter)) return false;
        }
        
        return true;
      });
      return filteredTickets;
    } catch (e) {
      return []
    }
  }

  filterTicketsForRoutes(
    filters: VandalismRoute.Params
  ): ITicket[] {
    try {
      if (filters === undefined) return [];

      const filteredTickets = this.tickets.filter((ticket: ITicket) => {
        if (ticket?.reportedDate === null || ticket?.reportedDate === undefined) return false;
  
        const fieldFilters: FieldFilter<ITicket>[] = [
          { field: 'productOperational1', filter: filters.family },
          { field: 'state', filter: filters.states },
          { field: 'directors', filter: filters.directors },
          { field: 'city', filter: filters.cities }
        ]

        for (let { field, filter } of fieldFilters) {
          if (!this.isFieldIncluded(ticket, field, filter)) return false;
        }
        
        return true;
      });

      return filteredTickets;
    } catch (e) {
      return []
    }
  }

  calculateDateRange(year: string): { startDate: string, endDate: string } {
    const endDate = new Date();
    let startDate: Date;
    
    if (!year) {
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
    } else {
      startDate = new Date(Number(year), 0, 1);
    }

    return {
      startDate: formatDate(startDate, 'dd/MM/yyyy', 'en'),
      endDate: formatDate(endDate, 'dd/MM/yyyy', 'en')
    };
  }
}
