import { Component, Input, OnInit } from '@angular/core';
import { callControlByState } from 'src/app/interfaces';
import { Formatter } from 'src/app/utils/formatter.util';

@Component({
  selector: 'app-table-default',
  templateUrl: './table-default.component.html',
  styleUrls: []
})
export class TableDefaultComponent implements OnInit {
  
  title='CARTA DE CONTROLE CHAMADOS POR ESTADO E DIA';
  @Input() data: callControlByState;  

  constructor() { }
  
  ngOnInit(): void {    
  }
}
