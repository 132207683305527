<div>
  <div class="form-header">
    <fa-icon
      [icon]="faUser"
      class="icon-user"
      size="5x"
    >
    </fa-icon>

    <fa-icon
      [icon]="faTimesCircle"
      class="icon-close"
      size="2x"
      (click)="close()"
    >
    </fa-icon>
  </div>
  <div class="form-content">
    <form method="post" [formGroup]="form" (submit)="onSubmit()">   
      <div class="form-content-input">
        <span class="form-content-span">
          Nome
        </span>
        <input
          id="fullname"
          name="Nome"
          class="form-control"
          formControlName="fullname"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('fullname')?.errors && form.get('fullname')?.touched">
          Nome é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Usuário
        </span>
        <input
          id="username"
          name="Nome"
          class="form-control"
          formControlName="username"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('username')?.errors && form.get('username')?.touched">
          Username é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Senha
        </span>
        <input
          id="password"
          [(ngModel)]="password"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          class="form-control"
          formControlName="password"
        >
        <i 
          *ngIf="password"
          [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
          (click)="toggleShowPassword()"
        >
        </i>
      </div>

      <!-- TODO: Componentizar essa lógica -->
      <!-- Verificação escadinha. -->
      <div class="form-content-input-alert">
        <p *ngIf="form.get('password')?.errors?.required && form.get('password')?.touched">
          <i class="fa fa-times-circle"></i> A Senha é obrigatória.
        </p>

        <p *ngIf="form.get('password')?.errors?.caseError && form.get('password')?.touched && !form.get('password')?.errors?.required">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos uma letra maiúscula.
        </p>

        <p *ngIf="form.get('password')?.errors?.specialCharacterError && form.get('password')?.touched && !form.get('password')?.errors?.required && !form.get('password')?.errors?.caseError">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos um caractere especial.
        </p>   

        <p *ngIf="form.get('password')?.errors?.numberError && form.get('password')?.touched && !form.get('password')?.errors?.required && !form.get('password')?.errors?.caseError && !form.get('password')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve conter números.
        </p>

        <p *ngIf="form.get('password')?.errors?.minlength && form.get('password')?.touched && !form.get('password')?.errors?.numberError && !form.get('password')?.errors?.required && !form.get('password')?.errors?.caseError && !form.get('password')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve ter pelo menos 8 caracteres.
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Confirmação de Senha
        </span>
        <input
          id="confirmationPassword"
          [(ngModel)]="confirmationPassword"
          [type]="showConfirmationPassword ? 'text' : 'password'"
          name="confirmationPassword"
          class="form-control"
          formControlName="confirmationPassword"
        >
        <i 
          *ngIf="password"
          [ngClass]="showConfirmationPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
          (click)="toggleShowConfirmationPassword()"
        >
        </i>
      </div>
      <div class="form-content-input-alert">
        <p
          *ngIf="passwordsMatchValidator(form) &&
          form.get('confirmationPassword')?.touched"
        >
          <i class="fa fa-times-circle"></i> As senhas precisam ser iguais
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Email
        </span>
        <input
          id="email"
          name="email"
          class="form-control"
          formControlName="email"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('email')?.errors && form.get('email')?.touched">
          <i class="fa fa-times-circle"></i> Email é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">Estado</span>
        <ng-select
          [items]="stateOptions"
          bindLabel="name"
          bindValue="value"
          [multiple]="false"
          formControlName="state"
        >
        </ng-select>  
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('state')?.errors && form.get('state')?.touched">
          <i class="fa fa-times-circle"></i> Escolha um estado
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">Permissões</span>
        <ng-select
          [items]="permissionOptions"
          bindLabel="name"
          bindValue="value"
          [multiple]="true"
          formControlName="permissions"
        >
        </ng-select>  
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('permissions')?.errors && form.get('permissions')?.touched">
          <i class="fa fa-times-circle"></i> Escolha pelo menos uma permissão
        </p>
      </div>
   
      <div class="form-content-submit">
        <button 
          type="submit"
          class="btn btn-primary mx-auto col-lg-5 col-md-5 col-5"
          [disabled]="!form.valid"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</div>