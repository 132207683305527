import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartInput, IColors } from '../../interfaces';
import 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-occurrences-bar-chart',
  templateUrl: './occurrences-bar-chart.component.html',
  styleUrls: []
})
export class OccurrencesBarChartComponent implements OnInit {
  title = 'COMPARAÇÃO DE OCORRÊNCIAS DE VANDALISMO POR CIDADE'
  @Input() data: ChartInput & { states: string[] };
  @Input() colors: IColors;
  @Output() event = new EventEmitter<string>();

  truncate(value: string, maxLength: number) {
    if (value.length > maxLength) {
      return value.substring(0, maxLength).concat('...');
    }
    return value;
  }

  treatCityName(value: string) {
    return value.replace('_', ' ');
  }

  backgroundColor: string[];  
  type: ChartType = 'bar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, activeElements: Array<any>) => {
      const index = activeElements[0]._index
      this.onRedirect(index);
    },
    aspectRatio: 1,
    layout: {
      padding: {
        top: -5,    // Margem superior
        left: -5,   // Margem esquerda
        right: -5,  // Margem direita
        bottom: 0  // Margem inferior
      }
    },
    scales: 
    { 
      xAxes: [{
        ticks:{
          callback: (value: string) => this.treatCityName(this.truncate(value, 10)),
          fontSize: 8
        },      
        gridLines: {
          display: false,          
        },        
      }], 
      yAxes: [{
        ticks: {
         display:false                    
        },
        gridLines: {
          display: false
        },
      }] 
    },   
    tooltips:{      
      callbacks:{
        label: function(tooltipItem, data){            
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = tooltipItem.value;
          return 'Qtd de chamados: ' + label +  value;
        }
      },
    } ,
    plugins: {
      datalabels: {
        display: false, 
        font:{size:9}       
      },
    }
  }; 

  constructor() { }

  ngOnInit(): void {
    this.backgroundColor = Array(15).fill({ 
      backgroundColor: this.colors.primaryColor, 
      hoverBackgroundColor: this.colors.hoverPrimaryColor 
    });        
  }

  onRedirect(stateIndex) {
    this.event.emit(this.data.states[stateIndex]);
  }
}
