import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { debounceTime } from 'rxjs/operators';
import { VandalismAnalytical } from 'src/app/interfaces';
import { AnalyticalChart } from 'src/app/interfaces/models/IAnalyticalChart';
import { FilterService } from 'src/app/services/filter.service';
import { ThemeService } from 'src/app/services/theme.service';
import { initialStateActiveFilters } from 'src/app/utils/constants';

export const monthNames = {
  '01': 'Jan',
  '02': 'Fev',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'Mai',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Set',
  '10': 'Out',
  '11': 'Nov',
  '12': 'Dez',
};

@Component({
  selector: 'app-analytical-bar-line-stacked',
  templateUrl: './analytical-bar-line-stacked.component.html',
  styleUrls: ['./analytical-bar-line-stacked.component.scss']
})
export class AnalyticalBarLineStackedComponent implements OnInit, OnChanges {

  @Input() data: VandalismAnalytical.Output;
  @Input() loading = true;
  @Input() error = false;
  @Input() barColors: string[];
  @Input() primaryColor = 'ff0000';
  @Input() secondaryColor = '#000000';
  @Input() colors: string[];
  @Input() width = 40;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  currentState: string[] = ['EMPRESARIAL'];
  activeFilters: VandalismAnalytical.InputParams = initialStateActiveFilters;
  barAnalytic = { datasets: [], labels: [], legends: [], colors: [] }
  barWidthProportion = '100%';
  isLessThan100  = false;
  type: ChartType = 'bar';
  key = 'month';
  options: ChartOptions = {};
  requestWrapperConfig = {
    chart: 'chart-small-body-analytic',
  }

  constructor(
    private filterService: FilterService,
    private themeService: ThemeService
  ) {
    this.filterService.getFiltersObservable<VandalismAnalytical.InputParams>({
      namespace: 'analytical'
    })
      .pipe(debounceTime(100))
      .subscribe((af) => {
        this.activeFilters = af;
      });
  }

  getDataByView(value: string) {

    const views = {
      'EMPRESARIAL': 'analyticalActivityByMonthEmp',
      'RESIDENCIAL': 'analyticalActivityByMonthRes',
      'ALL': 'analyticalActivityByMonthAll'
    }

    return views[value];
  }

  ngOnInit() {    
    this.updateColors(4);
  }
  
  ngOnChanges() {
    this.setView();
  }

  setView() {
    const currentStateLength = this.currentState.length;
    const valueCurrentState = currentStateLength > 1 ? 'ALL' : (currentStateLength === 0 ? 'ALL' : this.currentState[0]);
    const view = this.getDataByView(valueCurrentState);
    const data = this.data[view];
    if (data !== undefined) {
      this.createBarAnalytic(data);
      this.updateBarProportion(data.datasets);
    }
  }

  format(str: string) {
    return str
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }   
  
  createBarAnalytic(data: AnalyticalChart) {
    const activeYears = this.activeFilters.year.split(',').filter(v => v !== '').map(v => Number(v)).sort();
    const labels = data?.labels;

    const percentageVandalismAll: number[][] = [];
    const activeCauses = this.activeFilters.causeGroup.split(',').filter((v) => v !== undefined && v !== '');
    const displayCause = activeCauses.length === 1 ? this.format(activeCauses[0]) : 'Causas selecionadas';

    this.updateColors(activeYears.length);

    data.datasets.forEach((monthData) => {
      monthData.forEach((yearData, yearIndex) => {
        if (!percentageVandalismAll[yearIndex]) {
          percentageVandalismAll[yearIndex] = [];
        }
        const ticketsAll = yearData[0];
        const ticketsVandalism = yearData[1];
        const percentageVandalism = (ticketsAll > 0 ? (ticketsVandalism / ticketsAll) * 100 : 0).toFixed(0);
        percentageVandalismAll[yearIndex].push(Number(percentageVandalism));
      });
    });

    const datasets = [];

    activeYears.forEach((year, yearIndex) => {
      const totalDeNotasData: number[] = [];
      const totalVandalismData: number[] = [];
      const percentageVandalismData: number[] = [];

      data.datasets.forEach((monthData) => {
        const yearData = monthData[yearIndex] || [0, 0, 0];
        totalDeNotasData.push(yearData[0]);
        totalVandalismData.push(yearData[1]);
        percentageVandalismData.push(Number((yearData[0] > 0 ? (yearData[1] / yearData[0]) * 100 : 0).toFixed(0)));
      });

      datasets.push({
        originalData: totalDeNotasData,
        data: totalDeNotasData.map((v) => 500),
        label: `Total de Atividades (${year})`,
        backgroundColor: this.colors[0],
        hoverBackgroundColor: this.colors[0],
        type: 'bar',
        stack: `stack${year}`,
        order: 1,
        datalabels: {
          align: 'start',
          anchor: 'start',
          padding: {
            top: -5,
            bottom: 0
          },
          color: this.colors[0],
          font: {
            weight: 'bold',
            size: 8
          },
        }
      });

      datasets.push({
        data: totalVandalismData,
        label: `Atividades por Causa (${year})`,
        backgroundColor: this.barColors[yearIndex],
        hoverBackgroundColor: this.barColors[yearIndex],
        type: 'bar',
        stack: `stack${year}`,
        order: 2,
        datalabels: {
          align: 'end',
          anchor: 'end',
          padding: {
            top: -5,
            bottom: 0
          },
          color: this.barColors[yearIndex],
          font: {
            weight: 'bold',
            size: 8
          },
          formatter: (value, context) => {
            const index = context.dataIndex;
            if (value !== 0 && percentageVandalismData[index] !== 0) {
              return `${value} (${percentageVandalismData[index]}%)`;
            }
            return '';
          }
        },
      });
    });

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, activeElements: Array<any>) => {
        if (activeElements.length > 0) {
          let firstElement = activeElements[0];
          let elementIndex = firstElement._index;
          let label = data.labels[elementIndex];
          let labelUpper = (label as string).toUpperCase();
          let newValue: any = label;
  
          if (this.key === 'month') {
            const inverseMontsNames = {
              'JAN': '1',
              'FEV': '2',
              'MAR': '3',
              'ABR': '4',
              'MAI': '5',
              'JUN': '6',
              'JUL': '7',
              'AGO': '8',
              'SET': '9',
              'OUT': '10',
              'NOV': '11',
              'DEZ': '12',
            };
            newValue = inverseMontsNames[labelUpper];
          }
  
          const filtersActiveByKey = this.activeFilters[this.key].split(',');
  
          if (filtersActiveByKey.includes(newValue)) {
            this.onChange(filtersActiveByKey.filter((v) => v !== '' && v !== newValue));
            return;
          }
  
          this.onChange(newValue);
      }},
      scales: {
        xAxes: [{
          ticks: {
            padding: 10,
          },
          gridLines: { display: false },
        }],
        yAxes: [{
          id: 'y',
          stacked: true,
          gridLines: { display: false },
          ticks: { display: false }
        }, {
          id: 'y1',
          type: 'linear',
          position: 'right',
          gridLines: { display: false },
          ticks: { display: false }
        }]
      },
      layout: {
        padding: { top: 20, bottom: 0 }
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];

            const value = dataset["originalData"] !== undefined
              ? dataset["originalData"][tooltipItem.index]
              : dataset.data[tooltipItem.index];


            if (!(tooltipItem.datasetIndex % 2 === 0)) {
              const mappedIndexsByYearsDataset = { 1: 0, 3: 1, 5: 2, 7: 3 };
              const yearIndex = mappedIndexsByYearsDataset[tooltipItem.datasetIndex];
              const percentage = percentageVandalismAll[yearIndex][tooltipItem.index];
              return `Total de atividade por ${displayCause} - ${value} e % por ${displayCause} - (${percentage}%)`;
            }
            return `Total de atividade por ${displayCause} - ${value}`;
          }
        }
      },
      plugins: {
        datalabels: {
          align: 'end',
          anchor: 'start',
          padding: { top: -5, bottom: 0 },
          color: 'black',
          font: { weight: 'bold', size: 12 },
          formatter: (value, context) => {
            const dataset = context.dataset;
            return dataset["originalData"] !== undefined 
              ? dataset["originalData"][context.dataIndex]
              : dataset["data"][context.dataIndex]
          },
        }
      }
    };

    const colors = [this.colors[0], ...this.barColors, this.colors[1]];

    this.barAnalytic = {
      datasets: datasets,
      labels: labels,
      legends: data.legends,
      colors: colors
    };
  }

  updateColors(barQuantity: number) {
    this.barColors = this.themeService.generateColorGradient(this.primaryColor, this.secondaryColor, barQuantity)
  }
  
  updateBarProportion(datasets: any) {
    const datasetLength = (datasets.reduce((acc, current) => current.length + acc, 0))  || 0;
    const control = 14
    let newWidth = (datasetLength / control) * this.width

    if (newWidth < 20) newWidth = 20;

    this.barWidthProportion = `${newWidth}%`
    this.isLessThan100  = newWidth < 100;
    this.updateChartSize();
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  onChange(labelIndex: string) {      
    this.filterClick.emit(`${this.key}:${labelIndex}`);    
  }
  
  setState(state: string) {
    if (!this.currentState.includes(state)) {
      this.currentState.push(state);
    } else {
      this.currentState = this.currentState.filter((s) => s !== state);
    }

    this.setView();
  }
}
