<div class="container-input-dafault">
  <input
    type="text"
    id="input"
    class="input-content"
    [placeholder]="placeholder"
    [value]="value"
    (ngModelChange)="onInputChange($event.target.value)"
  />
  <label for="input" class="input-label">{{ labelSpan }}</label>
</div> 
