import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { VandalismAnalytical } from 'src/app/interfaces';
import { AnalyticalChart } from 'src/app/interfaces/models/IAnalyticalChart';
import { initialStateActiveFilters } from 'src/app/utils/constants';
import { ThemeService } from 'src/app/services/theme.service';
import { InverseGroupsMapped, inverseMontsNames } from 'src/app/utils/mapped';

@Component({
  selector: 'app-access-control-directory-bar-chart',
  templateUrl: './access-control-directory-bar-chart.component.html',
  styles: []
})
export class AccessControlDirectoryBarChartComponent implements OnInit {
  @Input() data: AnalyticalChart;
  @Input() key: string;
  @Input() isPorcentageDatalabel = false;
  @Input() primaryColor = 'ff0000';
  @Input() secondaryColor = '#000000';
  @Input() width = 75;
  @Input() loading: boolean = true;
  @Input() error: boolean = false;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  
  activeFilters: VandalismAnalytical.InputParams = initialStateActiveFilters;
  barAnalytic = { datasets: [], labels: [], legends: [], title: '' }
  barWidthProportion = '100%';
  colors: string[] = [];
  filterValues = VandalismAnalytical;
  type: ChartType = 'bar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    onClick: (event, activeElements: Array<any>) => {
      if (activeElements.length > 0) {
        let firstElement = activeElements[0];
        let elementIndex = firstElement._index;
        let label = this.barAnalytic.labels[elementIndex];
        let labelUpper = label.toUpperCase();
        let value = labelUpper;
        if (this.key === 'group') {
          value = InverseGroupsMapped[labelUpper].toUpperCase();
        }

        if (this.key === 'month') {
          value = inverseMontsNames[labelUpper];
        }

        const filtersActiveByKey = this.activeFilters[this.key].split(',');

        if (filtersActiveByKey.includes(value)) {
          this.onChange(filtersActiveByKey.filter((v) => v !== '' && v !== value));
          return;
        }
        
        this.onChange(value);
      }
    },    
    scales: { 
      xAxes: [{     
        ticks: {
          callback: (value: string, index, values) => {
            let newValue = value ?? '';
            newValue = newValue.replace('Regional', '');
            newValue = newValue.replace('Grupo', '');
            return newValue;
          },
          fontSize: 13,
        },         
        gridLines: {
          display: false,          
        },        
      }], 
      yAxes: [{        
        ticks: {
          display:false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }, 
      }] 
    },
    layout: {
      padding: {
        top: 20,
        bottom: 10
      },
    },   
    plugins: {
      datalabels: {
        align: 'end',
        padding: 0,
        anchor: 'end',
        color: '#555',
        font: {
          weight: 'bold',
          size: 9
        },      
      },
      formatter: (value) => {
        return this.isPorcentageDatalabel ? `${value}%` : value;
      }
    }
  }; 

  constructor(private themeService: ThemeService) {
  }

  ngOnInit(): void {}

  ngOnChanges(): void {    
    const datasets = this.getTreatedDatasets();
    this.updateColors(datasets);
    this.updateBarProportion(datasets);
    this.createBarAnalytic(datasets);
  }

  mountChartConfig(datasets) {        
    return {
      datasets: datasets.map((d, i) => ({
        data: d,
        backgroundColor: this.colors[i],
        hoverBackgroundColor: this.colors[i], 
        datalabels: {        
          align: 'end',
          padding: 0,
          anchor: 'end',
          color: (this.colors[i] ?? 'black'),
          font: {
            weight: 'bold',
            size: 9
          },
          formatter: (value) => {
            const response = this.isPorcentageDatalabel ? `${value}%` : value;
            return value > 0 ? response : "";
          }
        },
      })),
      labels: this.data.labels,
      legends: this.data.legends,
      title: this.data.title
    }
  }

  createBarAnalytic(datasets) {
    const data = this.mountChartConfig(datasets);
    if (data) {
      this.barAnalytic = {
        datasets: data.datasets,
        labels: data.labels,
        legends: data.legends,
        title: data.title
      }
    }
  };

  getTreatedDatasets() {
    return this.data.datasets.reduce((acc, curr) => {
      curr.forEach((value, index) => {
        if (!acc[index]) {
          acc[index] = [];
        }

        if (value !== null) acc[index].push(value[0]);
      });
      return acc;
    }, [[], []]);
  }

  updateColors(datasets) {
    const barQuantity = datasets.length;
    this.colors = this.themeService.generateColorGradient(this.primaryColor, this.secondaryColor, barQuantity);
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  updateBarProportion(datasets) {
    const datasetLength = datasets.reduce((acc, current) => current.length + acc, 0) || 0;
    const control = 18
    const newWidth = (datasetLength / control) * this.width
    this.barWidthProportion = `${newWidth > 100 ? newWidth : 100}%`
    this.updateChartSize();
  }

  onChange(labelIndex:string) {
    this.filterClick.emit(`${this.key}:${labelIndex}`);    
  }

}
