<div>
	<div class="container-header-page" [class.bg-transition]="isDivVisible" #headerFilter>
		<div class="header-img-filter col-1">
			<img
				[src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
				(click)="openHeaderFilter()" alt="logo da claro">
		</div>

		<div class="container-header-filters col-10" [@slideInOut]="isDivVisible ? 'in' : 'out'">
			<div class="container-filters col-12 p-0">
				<div class="col-lg-2 col-md-2">
					<app-select-default key="family" title="Família" [icon]="icons.building" [dropdownList]="filters?.families"
						[selectedItems]="activeFilters.family" [itemsShowLimit]="2" (event)="updateFilters('family', $event)">
					</app-select-default>
				</div>

				<div class="col-lg-2 col-md-2">
					<app-select-default key="directors" title="Diretorias" [searchFilter]="true"
						[dropdownList]="filters?.directors" [selectedItems]="activeFilters.directors"
						(event)="updateFilters('directors', $event)">
					</app-select-default>
				</div>

				<div class="col-lg-2 col-md-2">
					<app-select-default key="states" title="Estados" [searchFilter]="true" [dropdownList]="filters?.states"
						[selectedItems]="activeFilters.states" (event)="updateFilters('states', $event)">
					</app-select-default>
				</div>

				<div class="col-lg-2 col-md-2">
					<app-select-default key="cities" title="Cidades" [searchFilter]="true" [dropdownList]="filters?.cities"
						[selectedItems]="activeFilters.cities" (event)="updateFilters('cities', $event)">
					</app-select-default>
				</div>

				<div class="col-lg-2 col-md-2">
					<app-select-default key="routeId" title="Rotas" [searchFilter]="true" [dropdownList]="getRouteName(filters)"
						[itemsShowLimit]="2" isSingleSelection="true" (event)="updateFilters('routeId', $event)">
					</app-select-default>
				</div>

			</div>
		</div>
	</div>
	<div class="card-chart-body table-responsive" *ngIf="submit"
		style="background-color: white; border-radius: 5px; border: 1px solid #e9e9e9;">
		<div class="table-header ">
			<p class="table-title">Informações da Rota</p>
			<input type="text" class="form-control search-input" placeholder="Digite para buscar..." [(ngModel)]="searchQuery"
				(input)="filterTable()" />
		</div>

		<table class="align-middle mb-0 table table-borderless table-striped table-hover">
			<thead>
				<tr>
					<th class="text-center text-nowrap">Famílias</th>
					<th class="text-center text-nowrap">Diretorias</th>
					<th class="text-center text-nowrap">Estados</th>
					<th class="text-center text-nowrap">Cidades</th>
					<th class="text-center text-nowrap">Rotas</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let route of paginatedData" (click)="onRouteClick(route.routeId)" class="route-item">
					<td class="text-center">
						{{ route.family }}
					</td>
					<td class="text-center">
						{{ route.directors }}
					</td>
					<td class="text-center">
						{{ route.states }}
					</td>
					<td class="text-center">
						{{ route.cities }}
					</td>
					<td class="text-center">
						{{ route.routeName }}
					</td>
				</tr>
				<tr *ngIf="paginatedData.length === 0">
					<td class="text-center" colspan="5">Nenhuma informação disponível</td>
				</tr>
			</tbody>

			<tfoot>
				<tr>
					<td colspan="5" class="text-center">
						<div class="pagination-controls">
							<button [disabled]="currentPage === 1" (click)="changePage('prev')">Anterior</button>
							<span>Página</span>
							<input type="number" [(ngModel)]="currentPage" (ngModelChange)="onPageChange($event)" min="1"
								[max]="totalPages" class="page-input" />
							<span>de {{ totalPages }}</span>

							<button [disabled]="currentPage === totalPages" (click)="changePage('next')">Próximo</button>
						</div>
					</td>
				</tr>
			</tfoot>
		</table>
	</div>

	<div class="row" *ngIf="!submit">
		<div class="row mx-auto px-0 full-height">
			<div class="row" *ngIf="isMapVisible">
				<div class="col-lg-12 col-md-12">
					<app-request-wrapper [isLoading]="loading" [isError]="error" [isCustomContent]="submit"
						[customContent]="submitContent">

						<div class="col-12 no-padding">
							<div class="card-chart route-map">

								<div class="container-buttons">
									<div class="d-flex margin-right-5">
										<button class="styled-button red-button" (click)="createRouteToa()">
											Enviar para o Toa
										</button>
										<button class="styled-button gray-button" (click)="openMaps()">
											Enviar para o Mapa
										</button>
									</div>
									<div>
										<div class="card-chart-header">
											<app-title-default [content]="'MAPA DE RONDA ESPERADA/REALIZADA'"></app-title-default>
										</div>
										<div class="container-legends">
											<div class="legend-container">
												<span class="symbol-legend" [ngStyle]="{'background-color': options.colors.expected}"></span>
												<span class="label-charts">Esperada</span>
											</div>

											<div class="legend-container">
												<span class="symbol-legend" [ngStyle]="{'background-color': options.colors.realized}"></span>
												<span class="label-charts">Realizada</span>
											</div>
										</div>
									</div>
									<div class="d-flex margin-bottom-5">
										<button class="map-button gray-button" [ngClass]="{'active-button': isMapVisible}"
											(click)="toggleView('map')">
											Mapa
										</button>
										<button class="map-button gray-button" [ngClass]="{'active-button': !isMapVisible}"
											(click)="toggleView('table')">
											Tabela
										</button>
									</div>
								</div>

								<div class="toggle-overlay-icon" (click)="toggleOverlay()">
									<i class="{{ overlayVisible ? 'fa fa-times' : 'fa fa-bars' }}"></i>
								</div>

								<div class="card-chart-body map-responsive">
									<div *ngIf="overlayVisible" class="map-overlay">
										<div class="cards-container">
											<div class="card-item">
												<app-card-default [icon]="icons.clock" [cardTitle]="'Início'" [cardValue]="routeStartTime"
													[isLargeFont]="false">
												</app-card-default>
											</div>
											<div class="card-item">
												<app-card-default [icon]="icons.clock" [cardTitle]="'Final'" [cardValue]="routeEndTime"
													[isLargeFont]="false">
												</app-card-default>
											</div>

											<div class="col-lg-3 col-md-3">
												<div class="wrapper-box">
													<app-request-wrapper [isLoading]="loading" [isError]="error">
														<app-card-score [scoreNumber]="0">
														</app-card-score>
													</app-request-wrapper>
												</div>
											</div>
											<div class="card-item">
												<app-card-default [icon]="icons.clock" [cardTitle]="'Início'"
													[cardValue]="'Informação indisponível'" [isLargeFont]="false">
												</app-card-default>
											</div>
											<div class="card-item">
												<app-card-default [icon]="icons.clock" [cardTitle]="'Final'"
													[cardValue]="'Informação indisponível'" [isLargeFont]="false">
												</app-card-default>
											</div>
										</div>
									</div>
									<agm-map *ngIf="expected?.origin && realized?.origin" class="map-full-height"
										[latitude]="mapConfig.center.lat" [longitude]="mapConfig.center.lng" [zoom]="mapConfig.zoom"
										[zoomControl]="mapConfig.zoomControl" [streetViewControl]="mapConfig.streetViewControl"
										[fullscreenControl]="mapConfig.fullscreenControl" [travelMode]="'DRIVING'">

										<agm-direction *ngIf="expected.origin && expected.destination" [origin]="expected.origin"
											[destination]="expected.destination" [waypoints]="expected.waypoints"
											[renderOptions]="expectedRenderOptions" [visible]="true" [optimizeWaypoints]="false">
										</agm-direction>

										<agm-direction *ngIf="realized.origin && realized.destination" [origin]="realized.origin"
											[destination]="realized.destination" [waypoints]="realized.waypoints"
											[renderOptions]="realizedRenderOptions" [visible]="true" [optimizeWaypoints]="false">
										</agm-direction>
									</agm-map>
								</div>
							</div>
						</div>
					</app-request-wrapper>
				</div>
			</div>

			<div class="row mx-auto px-0">
				<div class="row" *ngIf="!isMapVisible">
					<div class="col-lg-12 col-md-12" *ngIf="!submit && !error">
						<div class="card-chart">
							<div class="table-button">
								<div class="d-flex margin-right-5">
									<button class="styled-button red-button" (click)="createRouteToa()">
										Enviar para o Toa
									</button>
									<button class="styled-button gray-button" (click)="openMaps()">
										Enviar para o Mapa
									</button>
								</div>
								<div class="card-chart-header">
									<h5 class="header-title">TABELA DE RONDA ESPERADA</h5>
								</div>
								<div class="d-flex margin-bottom-5">
									<button class="map-button gray-button" [ngClass]="{'active-button': isMapVisible}"
										(click)="toggleView('map')">
										Mapa
									</button>
									<button class="map-button gray-button" [ngClass]="{'active-button': !isMapVisible}"
										(click)="toggleView('table')">
										Tabela
									</button>
								</div>
							</div>
							<div class="card-chart-body table-responsive">
								<table class="align-middle mb-0 table table-borderless table-striped table-hover">
									<thead>
										<tr>
											<th class="text-center text-nowrap">Ponto</th>
											<th class="text-center text-nowrap">Endereço</th>
											<th class="text-center text-nowrap">Horário Sugerido</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let route of routeData?.routeTable; let i = index">
											<td class="text-center">{{ generateLetterPoint(i + 1) }}</td>
											<td class="text-center text-nowrap">{{ route.address }}</td>
											<td class="text-center">{{ route.hours }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>