import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ILocation, VandalismRoute } from 'src/app/interfaces';

export type Waypoint = Array<{ location: ILocation }>

export type Route = {
  origin: ILocation;
  destination: ILocation;
  waypoints: Waypoint;
}

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: []
})
export class RouteMapComponent implements OnInit, OnChanges {

  @Input() title: string
  @Input() data: { score: number; expected: VandalismRoute.Route; realized: VandalismRoute.Route; };

  expected: Route;
  realized: Route;

  options = {
    colors: {
      expected: 'red',
      realized: 'green'
    }
  }

  expectedRenderOptions = {
    suppressMarkers: false,
    draggable: false,
    polylineOptions: {
      strokeColor: this.options.colors.expected,
      strokeWeight: 4
    }
  }

  realizedRenderOptions = {
    suppressMarkers: false,
    draggable: false,
    polylineOptions: {
      strokeColor: this.options.colors.realized,
      strokeWeight: 3
    }
  }

  mapConfig = {
    zoom: 5,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    optimizeWaypoints: true,
    center: {
      lat: -15.8517,
      lng: -48.5799
    }
  }

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.data?.expected !== undefined && this.data?.realized !== undefined) {
      const { expected, realized } = this.data;
      if (expected !== null && realized !== null) {
        if (expected.stops.length > 24 && realized.stops.length > 24)  return;
        this.expected = {
          origin: expected.origin,
          waypoints: expected.stops.map((l) => ({ location: { lat: l.lat, lng: l.lng } })),
          destination: expected.destination,
        }

        this.realized = {
          origin: realized.origin,
          waypoints: realized.stops.map((l ) => ({ location: { lat: l.lat, lng: l.lng } })),
          destination: realized.destination,
        }
      }
    }
  }
}
