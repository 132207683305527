<div 
  class="row mx-auto"
  style="margin-bottom: 2vh"
>
  <div class="col-6 pb-1">
    <app-analytic-bar-default 
      key="month"
      [data]="data.value.analyticalMonthByYears"
      [colors]="colors"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)"
    >
    </app-analytic-bar-default>
  </div>

  <div class="col-6">
    <app-analytic-bar-default
      [data]="data.value.analyticalWeekdayByYears"   
      [colors]="colors"
      [loading]="loading"
      [error]="error"
    >
    </app-analytic-bar-default>    
  </div>
</div>

<div
  class="row mx-auto"
  style="margin-bottom: 2vh"
>
  <div class="col-12">
    <app-analytic-bar-default      
      [data]="data.value.analyticalHourRangeByYears"
      [colors]="colors"
      [loading]="loading"
      [error]="error"
    >
    </app-analytic-bar-default>
  </div>
</div>

<div
  class="row mx-auto"
  style="margin-bottom: 2vh"
>
  <div class="col-12">
    <app-analytic-bar-default
      [data]="data.value.analyticalHourByYears"      
      [colors]="colors"
      [loading]="loading"
      [error]="error"
    >
    </app-analytic-bar-default>
  </div>
</div>
