<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme">
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: 690px;">
        <div class="v-sidebar-menu">
          <div class="vsm-list">
            <ngb-accordion 
              [destroyOnHide]="false" 
              [closeOthers]="true" 
              #acc="ngbAccordion"
              activeIds="{{extraParameter}}"
            >
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="app-header-logo">
                    <span class="header-title">FIDERE</span>
                    <button
                      type="button"
                      class="hamburger" 
                      (click)="toggleSidebar();"
                      [ngClass]="{'disable_title' : globals.toggleSidebarMobile}"
                    >
                      <span 
                        class="header-icon"
                        [ngClass]="{'disable_title' : globals.toggleSidebar}"
                      >
                        <span class="fa fa-chevron-circle-left"></span>
                      </span>
                      <span 
                        class="header-icon disable_title"
                        [ngClass]="{'active_title' : globals.toggleSidebar}"
                      >
                        <span class="fa fa-chevron-circle-right"></span>
                      </span>
                    </button>                    
                  </div>
                </ng-template>              
              </ngb-panel>

              <!-- *Administrador -->
              <ngb-panel *ngIf="hasPermission(0)" id="admin">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon">
                      <img src="../../../../assets/icons/user-gear.webp" alt="">
                    </i>
                    <span class="vsm-title">Área do Administrador</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/usuarios" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Usuários</span>
                          <span class="fa fa-user vsm-sub-icon"></span>
                        </a>
                      </div>
              
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/controle-acesso" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Controle de Acesso</span>
                          <span class="fa fa-lock vsm-sub-icon"></span>
                        </a>
                      </div>

                      <div class="vsm-item">
                        <a routerLink="/area-administrador/controle-acesso/diretorias" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Acessos por diretoria</span>
                          <span class="fa fa-lock vsm-sub-icon"></span>
                        </a>
                      </div> 
                      
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/suporte" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Tickets</span>
                          <span class="fa fa-list vsm-sub-icon"></span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/extracao-dados" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Exportação dados</span>
                          <span class="fa fa-history vsm-sub-icon"></span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/grafico" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Gráfico</span>
                          <span class="fa fa-line-chart vsm-sub-icon"></span>
                        </a>
                      </div>
                      <div class="vsm-item">
                        <a routerLink="/area-administrador/guia-admin" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Guia do Administrador</span>
                          <span class="vsm-sub-icon fa fa-question"></span> 
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <!-- *Vandalismo -->
              <ngb-panel *ngIf="hasPermission(1)" id="vandalismo">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">                    
                    <i class="vsm-icon">
                      <img src="../../../../assets/icons/icon-sirene.png" alt="">
                    </i>
                    <span class="vsm-title">Vandalismo</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list"> 
                      
                      <!-- Analitico -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/analitico" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Analítico</span>
                          <span class="vsm-sub-icon lnr-chart-bars"></span>
                        </a>
                      </div>
                      
                      <!-- Mapa Analitico -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/mapa" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Mapa Analítico</span>
                          <span class="vsm-sub-icon pe-7s-map"></span>
                        </a>
                      </div>
                      
                      <!-- Detalhamento -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/detalhamento" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Detalhamento</span>
                          <span class="fa fa-list vsm-sub-icon"></span>
                        </a>
                      </div>
                      
                      <!-- Alerta de chamados -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/alerta" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Alerta de Chamado</span>
                          <span class="fa fa-exclamation-circle vsm-sub-icon"></span>
                        </a>
                      </div>
                      
                      <!-- Rotas -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/rota" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Rotas</span>
                          <span class="vsm-sub-icon pe-7s-map-2"></span>
                        </a>
                      </div>                     
                      
                      <!-- Efetividade -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/efetividade" routerLinkActive="active-item" class="vsm-link">
                          <span class="vsm-title">Efetividade</span>
                          <span class="vsm-sub-icon lnr-chart-bars"></span>
                        </a>
                      </div>
                      
                      <!-- Sugestões Iniciativas -->
                      <div class="vsm-item">
                        <a routerLink="/vandalismo/sugestao" routerLinkActive="active-item" class="vsm-link">                          
                          <span class="vsm-title">Sugestões Iniciativas</span>
                          <span class="vsm-sub-icon lnr-chart-bars"></span>
                        </a>
                      </div>           
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <!-- *Imagens -->
              <ngb-panel *ngIf="hasPermission(3)" id="images">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="fa fa-image vsm-icon"></i>
                    <span class="vsm-title">Imagens</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">

                      <!-- Verificação -->
                      <div class="vsm-item">
                        <a routerLink="/image/verification" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Verificação</span>
                          <span class="vsm-sub-icon pe-7s-check"></span>
                        </a>
                      </div>         

                      <!-- Analítico -->
                      <div class="vsm-item">
                        <a routerLink="/image/analytical" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Analítico</span>
                          <span class="vsm-sub-icon lnr-chart-bars"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <!-- * Preventiva -->
              <ngb-panel id="preventiva">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="lnr-chart-bars vsm-icon"></i>
                    <span class="vsm-title">Trechos Críticos</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">                     
                      <div class="vsm-item">
                        <a routerLink="/preventiva/preventiva" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Mapa</span>
                          <span class="pe-7s-map vsm-sub-icon"></span>
                        </a>                                                                              
                      </div>
                      
                      <!-- Controle de Rotas Criticas -->
                      <div class="vsm-item">
                        <a routerLink="/preventiva/rotas-criticas" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Controle de Trechos</span>
                          <span class="vsm-sub-icon pe-7s-map-2"></span>
                        </a>
                      </div>                                              
                      
                      <!-- Projetos -->
                      <div class="vsm-item">
                        <a routerLink="/preventiva/projetos" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Projetos</span>
                          <span class="vsm-sub-icon fa fa-list"></span>
                        </a>
                      </div>                                              
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>  

              <!-- * Geral -->
              <ngb-panel *ngIf="hasPermission(13)" id="reincidencia">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="fa fa-cube vsm-icon"></i>
                    <span class="vsm-title">Geral</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
              
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/geral/reincidencia" *ngIf="hasPermission(8)" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Reincidência</span>
                          <span class="fa fa-history vsm-sub-icon"></span>
                        </a>
                        <a routerLink="/geral/classificacao-imagens" *ngIf="hasPermission(12)" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Classificação Imagens</span>
                          <span class="fa fa-tasks vsm-sub-icon"></span>
                        </a>                        
                      </div>    
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              
              <ngb-panel id="suporte">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="fa fa-headphones vsm-icon"></i>
                    <span class="vsm-title">Suporte</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">                     
                      <div class="vsm-item">
                        <a routerLink="/suporte" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Abrir Ticket</span>
                          <span class="fa fa-envelope vsm-sub-icon"></span>
                        </a>
                        <a routerLink="/suporte/pendentes" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Andamento</span>
                          <span class="fa fa-tasks vsm-sub-icon"></span>
                        </a>
                        <a routerLink="/suporte/respondidos" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Finalizadas</span>
                          <span class="fa fa-check vsm-sub-icon"></span>
                        </a>                                                       
                      </div>   
                      
                      <!-- Manual do usuário -->
                      <div class="vsm-item">
                        <a routerLink="/suporte/guia" routerLinkActive="active-item" class="vsm-link">                          
                          <span class="vsm-title">Guia do Usuário</span>
                          <span class="vsm-sub-icon fa fa-question"></span>                      
                        </a>
                      </div>    
                                                                          
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> 

              <ngb-panel id="monitoramento">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="fa fa-users vsm-icon"></i>
                    <span class="vsm-title">Monitoramento</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">                     
                      <div class="vsm-item">
                        <a routerLink="/monitoramento/mapa" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Tecnicos</span>
                          <span class="pe-7s-map vsm-sub-icon"></span>
                        </a>                                                                            
                      </div>   
                      
                      <!-- Manual do usuário -->
                      <div class="vsm-item">
                        <a routerLink="/suporte/guia" routerLinkActive="active-item" class="vsm-link">                          
                          <span class="vsm-title">Guia do Usuário</span>
                          <span class="vsm-sub-icon fa fa-question"></span>                      
                        </a>
                      </div>    
                                                                          
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>      
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>