<div class="modal-error">
  <div class="modal-content">
    <div class="header-modal" style="margin-bottom: 3vh;">
      <div [ngClass]="!isSuccess ? success.class : error.class">
        <i class="material-icons" [ngClass]="!isSuccess ? success.icon : error.icon"></i>
      </div>				
    </div>
    <div class="modal-body">
      {{ !isSuccess ? success.title : error.title }}
    </div>
    <div class="footer-modal">
      <button
        class="btn btn-secondary btn-block"
        (click)="close('Save click')"
      >
        OK
      </button>
    </div>
  </div>
</div>