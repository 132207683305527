import { AgmMap } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { mapConfig } from '../config/map-config';
import { VandalismTicketPopup } from 'src/app/interfaces';
import { inventoryIcons } from 'src/app/utils/map-icons';

@Component({
  selector: 'app-image-verification-map',
  templateUrl: './image-verification-map.component.html',
  styles: []
})
export class ImageVerificationMapComponent implements OnInit {
  @Input() set locations(value: VandalismTicketPopup.Location) {
    if (value) {
      this._locations = value;
      this.initializeMapMarkers();
    }
  }

  @Input() set imagesUrls(value: string[]) {
    if (value) {
      this._imagesUrls = value;
      this.loadImages();
      this.processCoordinatesAndPhotos();
    }
  }

  @Output() mapClosed = new EventEmitter<void>();
  @ViewChild(AgmMap) agmMap: AgmMap;

  private readonly DEFAULT_ZOOM = 8;
  private readonly ZOOM_ON_COORDINATES = 22;

  _locations: VandalismTicketPopup.Location;
  _imagesUrls: string[] = [];
  centerMap = { lat: -23.5505199, lng: -46.6333094 };
  mapSettings = { zoom: this.DEFAULT_ZOOM, icons: inventoryIcons, config: mapConfig };
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000
  };
  isTable:boolean = true;

  groupedPhotos: { [key: string]: { lat: number, lng: number, images: string[] } } = {};
  coordinateGroups: { lat: number, lng: number, count: number }[] = [];
  photoGroups: { coordinates: string, photos: number[], status: string }[] = [];

  constructor() { }

  ngOnInit(): void {
    this.processCoordinatesAndPhotos();
  }

  private initializeMapMarkers() {
    this.centerMap = {
      lat: this._locations.lat,
      lng: this._locations.lng
    };
    this.mapSettings.zoom = 20;
  }

  private loadImages() {
    // console.log(this._imagesUrls);
  }

  centerMapOnCoordinates(coordinates: string) {
    const [lat, lng] = this.parseCoordinates(coordinates);
    if (lat !== null && lng !== null) {
      this.centerMap = { lat, lng };
      this.mapSettings.zoom = this.ZOOM_ON_COORDINATES;
    } else {
      // console.log('Coordenadas vazias ou inválidas.');
    }
  }

  private parseCoordinates(coordinates: string): [number | null, number | null] {
    const [lat, lng] = coordinates.split(',').map(Number);
    return (!isNaN(lat) && !isNaN(lng)) ? [lat, lng] : [null, null];
  }

  private processCoordinatesAndPhotos() {
    const coordinateMap: { [key: string]: { lat: number, lng: number, photos: number[], count: number } } = {};

    this._locations.photos.forEach((photo, index) => {
      const key = `${photo.lat},${photo.lng}`;
      if (!coordinateMap[key]) {
        coordinateMap[key] = { lat: photo.lat, lng: photo.lng, photos: [], count: 0 };
      }
      coordinateMap[key].photos.push(index + 1);
      coordinateMap[key].count += 1;
    });

    this.groupedPhotos = Object.keys(coordinateMap).reduce((acc, key) => {
      acc[key] = {
        lat: coordinateMap[key].lat,
        lng: coordinateMap[key].lng,
        images: this._imagesUrls.filter((_, i) => coordinateMap[key].photos.includes(i + 1))
      };
      return acc;
    }, {});

    this.coordinateGroups = Object.values(coordinateMap);
    this.photoGroups = Object.keys(coordinateMap).map(key => ({
      coordinates: key,
      photos: coordinateMap[key].photos,
      status: coordinateMap[key].count > 1 ? 'Mesmo Local' : 'Local Diferente'
    }));
  }

  closeMap() {
    this.mapClosed.emit();
  }

  closeTable(){
    this.isTable = !this.isTable;
  }
}
