import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationsTechniciansComponent } from '../locations-technicians.component';

@Component({
  selector: 'app-locations-technicians-proxy',
  template: `<div></div>`
})
export class LocationsTechniciansProxyComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.openMapModal()
  }

  openMapModal() {
    this.modalService.open(LocationsTechniciansComponent);
  }
}
