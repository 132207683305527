import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CardsProjectMonitoring, CriticalRoute, DataProjectMonitoring, Marker, Route, Settings } from "../maps/preventive-map/types/preventive.type";
import { environment } from "src/environment";

// INPUT MAPA PREVENTIVA
export interface InputPreventive {
  families?: string;
  classifications?: string;
  regionals?: string;
  clusters?: string;
  directors?: string;
  states?: string;
  segmentTypes?: string;
  stationTypes?: string;
  routeTypes?: string;
  stationType?: string;
  segments?: string;
  routeIds?: string;
  stationIds?: string;
  ownerships?: string;
  maintenances?: string;
}

// OUTPUT MAPA PREVENTIVA
export interface OutputPreventive {
  filters: {
    classification: string[];
    families: string[];
    states: string[];
    clusters: string[];
    type: string[];
    routeIds: string[];
    stationIds: string[];
  };
  markers: Marker[];
  routes: Route[];
  segments: Route[];
}

// INPUT CONTROLE DE TRECHOS
export interface InputCriticalSegment {
  families?: string;
  types?: string;
  status?: string;
  regionals?: string;
  directors?: string;
  states?: string;
  clusters?: string;
  segments?: string;
}

// OUTPUT CONTROLE DE TRECHOS
export interface OutputCriticalPreventive {
  filters: {
    status: string[];
    families: string[];
    type: string[];
    regionals: string[];
    directors: string[];
    states: string[];
  };
  markers: Marker[];
  data: CriticalRoute[];
}

// INPUT MONITORAMENTO DE PROJETOS
export interface InputProjectMonitoring {
  segments?: string;
  status?: string;  
  projects?: string;
  sortDirections?: string;
  sortBy?: string;  
  search?: string;  
}

// OUTPUT MONITORAMENTO DE PROJETOS
export interface OutputProjectMonitoring {
  filters: {
    projects: string[];    
    status: string[];
    segments: string[];
  };
  cards: CardsProjectMonitoring[];
  data: DataProjectMonitoring[];
  settings: Settings[];
  totalPages: number;
  currentPage: number;
}


// INPUT CRIAÇÃO DE ESTAÇÃO
export interface CreateStationRequest {
  data: {
    acronym: string;
    building: string;
    coordinate: {
      lat: number;
      lng: number;
    };
    type: string;
    classification: string;
    family: string;
  };
}

// INPUT CRIAÇÃO DE ROTA
export interface CreateRouteRequest {
  name: string;
  description: string;
  color: string;
  folderPathAtt: string;
  coordinates: { lat: number; lng: number; sequenceNumber: number }[];
}

export interface positions{
  Positions:[]
}

@Injectable({
  providedIn: "root",
})
export class PreventiveService {

  private readonly baseUrl; // Substitua pela URL base da sua API  

  constructor(private http: HttpClient) {    
    this.baseUrl = environment.baseUrl;
  }

  getPreventives(input: InputPreventive): Observable<any> {
    let params = new HttpParams();
    if (input.families) params = params.set("families", input.families);
    if (input.regionals) params = params.set("regionals", input.regionals);
    if (input.directors) params = params.set("directors", input.directors);
    if (input.clusters) params = params.set("clusters", input.clusters);
    if (input.states) params = params.set("states", input.states);
    if (input.segmentTypes) params = params.set("segmentTypes", input.segmentTypes);
    if (input.routeTypes) params = params.set("routeTypes", input.routeTypes);
    if (input.stationType) params = params.set("stationType", input.stationType);
    if (input.classifications) params = params.set("classifications", input.classifications);
    if (input.segments) params = params.set("segments", input.segments);
    if (input.ownerships) params = params.set("ownerships", input.ownerships);
    if (input.maintenances) params = params.set("maintenances", input.maintenances);
    if (input.routeIds) params = params.set("routeIds", input.routeIds);
    if (input.stationIds) params = params.set("stationIds", input.stationIds);
    return this.http.get<OutputPreventive>(`${this.baseUrl}/vandalism/preventives`, { params });
  }

  getCriticalSegments(
    input: InputCriticalSegment,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[],
    userId: any
  ): Observable<{
    filters: Record<string, Array<string>>;
    data: any;
    settings: any;
    totalPages: number;
    cards: any;
  }> {
    let params = new HttpParams()
      .set("userId", userId)
      .set("page", page)
      .set("size", size);
      
    if (search) params = params.set('search', search); 
    if (input.status) params = params.set('status', input.status);
    if (input.types) params = params.set('segmentTypes', input.types);
    if (input.families) params = params.set('families', input.families);
    if (input.regionals) params = params.set("regionals", input.regionals);
    if (input.directors) params = params.set("directors", input.directors);
    if (input.states) params = params.set("states", input.states);
    if (input.clusters) params = params.set("clusters", input.clusters);
    if (input.segments) params = params.set("segments", input.segments);
    if (sortBy) params = params.set('sortBy', sortBy.join(','));
    if (sortDirection) params = params.set('sortDirections', sortDirection.join(','));
    return this.http.get<any>(`${this.baseUrl}/vandalism/preventives/critical-segments`, { params });
  }

  getProjectMonitoring(
    input:InputProjectMonitoring,
    page: number,
    size: number,
    search: string,
    sortBy: string[],
    sortDirection: string[],
    userId: number,    
  ): Observable<{
    filters: Record<string, Array<string>>;
    data: any;
    settings: any;
    totalPages: number;
    cards: any;
  }> {
    let params = new HttpParams()
      .set("userId", userId)
      .set("page", page)
      .set("size", size);
    if (search) params = params.set('search', search); 
    if (input.status) params = params.set('status', input.status);    
    if (input.projects) params = params.set('projects', input.projects);    
    if (input.segments) params = params.set("segments", input.segments);    
    if (sortBy) params = params.set('sortBy', sortBy.join(','));
    if (sortDirection) params = params.set('sortDirections', sortDirection.join(','));
    return this.http.get<any>(`${this.baseUrl}/vandalism/preventives/project-monitoring`, { params });
    // return this.http.get<any>(`http://localhost:8769/vandalism/preventives/project-monitoring`, { params });
  }

  updateSegment(input: Record<string, string>[]) {
    return this.http.put<any>(`${this.baseUrl}/vandalism/preventives/segments`, { data: input})
  }

  
  createRoute(input:CreateRouteRequest): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/vandalism/preventives/routes`, input);
    // return this.http.post<any>(`http://localhost:8769/vandalism/preventives/routes`, input);
  }
  
  creatStation(body:any): Observable<any>{    
    return this.http.post<any>(`${this.baseUrl}/vandalism/preventives/stations`,body);
  }
}