<div>
  <div class="row">
    <div class="col-md-12 button-container">
      <button type="button" class="btn btn-primary" (click)="addTheme()">
        Adicionar
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 content-container">
      <div class="row themes-container">  
        <p class="section-title">Temas padrões:</p>
        <div 
          class="col-md-2" 
          *ngFor="let theme of themesDefault"
        >
          <app-card-theme [theme]="theme"></app-card-theme>
        </div>
      </div>

      <div class="row themes-container">
        <p class="section-title">Temas customizados:</p>
        <div
          class="col-md-2"
          *ngFor="let theme of themesCustom"
        >
          <app-card-theme [theme]="theme"></app-card-theme>
        </div>
      </div>
    </div>
  </div>
</div>
