import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartInput, IColors, connectionTypeView } from '../../interfaces';

@Component({
  selector: 'app-incident-doughnut-chart',
  templateUrl: './incident-doughnut-chart.component.html',
  styleUrls: []
})
export class IncidentDoughnutChartComponent implements OnInit, OnChanges {
  @Input() data: Record<connectionTypeView, any>;
  @Input() colors: IColors;

  views: connectionTypeView[];
  type: ChartType = 'doughnut';
  selectedViewData: ChartInput;
  backgroundColor;
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    tooltips:{      
      callbacks:{
        label: function(tooltipItem, data){            
          let label = data.labels[tooltipItem.index];
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + ':' + value;
        }
      },
    } ,    
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        formatter: (value) => `${value}%`,
        font: {
          weight: 'bold'
        }
      }
    }
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.views = ['familia', 'origem',  'rede'] as connectionTypeView [];    
    this.backgroundColor = Array(15).fill({
      backgroundColor: Object.values([
        this.colors.primaryColor,
        this.colors.secondaryColor
      ]),      
      hoverBackgroundColor: Object.values([this.colors.hoverPrimaryColor]),
    });
    this.setView('familia'); 
  }

  setView(view: string) {
    this.selectedViewData = this.data[view];
  }

  getColor(index: number) {
    return Object.values(this.colors)[index] || 'gray';
  }
}
