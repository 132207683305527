import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-wrapper',
  templateUrl: './request-wrapper.component.html',
  styleUrls: []
})
export class RequestWrapperComponent implements OnInit {

  @Input() isError: boolean;
  @Input() isLoading: boolean;
  @Input() class: string = "";
  @Input() isCustomContent: boolean = false;
  @Input() customContent: string = '';

  constructor() {}

  ngOnInit(): void {}
}
