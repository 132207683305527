import { Component, OnInit } from '@angular/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card-add-theme',
  templateUrl: './card-add-theme.component.html',
  styleUrls: []
})
export class CardAddThemeComponent implements OnInit {

  icon=faPlusCircle

  constructor() { }

  ngOnInit(): void {
  }

}
