import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { IEffectiveness } from 'src/app/interfaces/models/IEffectiveness';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: []  
})
export class TableComponent implements OnInit, OnChanges {  
  
  @Input() effectivenessData: IEffectiveness[] = []; 
  @Input() isLoading: boolean = false;  
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter(); // Emite eventos de mudança de página
  timeoutId: any;
  activeRow: number | null = null;
  selectedRowColor: string = "#ECF0F1"
  
  constructor() { }
  
  ngOnInit(): void {    
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['effectivenessData']) {
    }
  }
  
  
  toggleDropdown(index: number) {
    // Se a linha clicada for a mesma que já está ativa, feche-a; caso contrário, abra
    this.activeRow = this.activeRow === index ? null : index;
    this.selectedRowColor = "#c2c5c6"
    console.log(this.activeRow);
  }

  getPercentageValue(effectiveness: string | number): number {
    return typeof effectiveness === 'string' ? parseFloat(effectiveness) : effectiveness;
  }
  
  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.pageChange.emit(page);
    }
  }
   
  onPageChange(page: number) {    
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Define um timeout de 5 segundos antes de mudar de página
    this.timeoutId = setTimeout(() => {
      this.goToPage(page);
    }, 1000);
  }
  
  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }
  
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }
  
  goToPrevious10Pages() {
    this.currentPage = Math.max(1, this.currentPage - 10);
    this.pageChange.emit(this.currentPage);
  }
  
  goToNext10Pages() {
    this.currentPage = Math.min(this.totalPages, this.currentPage + 10);
    this.pageChange.emit(this.currentPage);
  }
  
  get paginationRange() {
    const range = [];
    const delta = 2;

    const start = Math.max(2, this.currentPage - delta);
    const end = Math.min(this.totalPages - 1, this.currentPage + delta);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  }
}
