import { MapTypeStyle } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CreateRouteRequest, CreateStationRequest, PreventiveService } from 'src/app/services/preventive.service';

@Component({
  selector: 'app-map-create-route-station',
  templateUrl: './map-create-route-station.component.html',
  styles: [
  ]
})
export class MapCreateRouteStationComponent implements OnInit {
  @Input() mode: 'station' | 'route' = 'station';
  @Output() mapClosed = new EventEmitter<void>();
  @Output() routeCreated = new EventEmitter<void>();

  selectedCoordinates: { lat: number; lng: number } | null = null;  
  routeCoordinates: { lat: number; lng: number }[] = [];
  polyline: google.maps.Polyline | null = null;
  marker: google.maps.Marker | null = null;
  backboneDefault: "/assets/maps/backbone.png"
  map: google.maps.Map | null = null;
  allowMarkerSelection: boolean = false;
  isDrawing = false;
  
  placeholders={
    nome:'nome da rota',
    type:'tipo',
    classification:'clasificação',    
    description:'descrição',
    family:'familia'
  };

  dataRoute:any = {
    name: '',
    description: '',
    color: '#FF5733',
    folderPathAtt: ''    
  };

  dataForm:any = {
    acronym: '',
    building: 'deafult',
    type: '',
    classification: '',
    family: '',    
    coordinate: null as { lat: number; lng: number } | null
  };

  @Input() mapConfig = {
    zoom: 8,
    maxZoom: 19,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
    optimized: false,
    center: { lat: -23.55052, lng: -46.633308 }, // Centro de São Paulo
    mapTypeControl: false,
    styles: <MapTypeStyle[]>[ // Define como MapTypeStyle[]
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ visibility: 'simplified' }]
      }
    ]
  };

  constructor(
    private preventiveService: PreventiveService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  // Ativa a seleção de coordenadas ao clicar no botão
  enableMarkerSelection(): void {
    this.allowMarkerSelection = true;

    if (this.map) {
      this.map.addListener('click', (event: google.maps.MouseEvent) => {
        if (this.allowMarkerSelection) {
          this.onStationClick(event);          
        }
      });
    }
  }
  
  // Captura a coordenada ao clicar no mapa
  onMapReady(map: google.maps.Map): void {   
    this.map = map;
    if(this.mode === 'route')
    {
      this.map.addListener('click', (event: google.maps.MouseEvent) => {
        if (this.isDrawing) {
          this.addCoordinate(event.latLng.lat(), event.latLng.lng());
        }
      });
    }
  }  

  // Adiciona uma coordenada ao array de coordenadas da rota
  private addCoordinate(lat: number, lng: number): void {
    const newCoordinate = { lat, lng };
    this.routeCoordinates.push(newCoordinate);
    console.log('Coordenada adicionada:', newCoordinate);

    this.updatePolyline(); // Atualiza a linha no mapa
  }
  
  // Atualiza a linha da rota no mapa
  private updatePolyline(): void {
    if (this.polyline) {
      this.polyline.setMap(null); // Remove a linha antiga
    }

    this.polyline = new google.maps.Polyline({
      path: this.routeCoordinates,
      geodesic: true,
      strokeColor: this.dataRoute.color,
      strokeOpacity: 1.0,
      strokeWeight: 4,
    });

    if (this.map) {
      this.polyline.setMap(this.map); // Exibe a nova linha
    }
  }  

  // Função separada para lidar com o clique no mapa e adicionar o marcador
  onStationClick(event: google.maps.MouseEvent): void {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    this.selectedCoordinates = latLng;
    this.dataForm.coordinate = latLng;    

    // Remove o marcador anterior, se existir
    if (this.marker) {
      this.marker.setMap(null);
    }

    // Adiciona um novo marcador no local clicado
    this.marker = new google.maps.Marker({
      position: latLng,
      map: this.map!,
      icon: {
        url: '/assets/maps/backbone.png',
        scaledSize: new google.maps.Size(32, 32)
      },
      title: 'Nova Estação'
    });
    
    this.allowMarkerSelection = false;
  }

  onRouteClick(event: any): void {
    const newCoordinate = { lat: event.coords.lat, lng: event.coords.lng };
    this.routeCoordinates.push(newCoordinate);
    console.log('Ponto adicionado:', newCoordinate);
  }

  create(): void {
    if (this.mode === 'station') {
      this.createStation();
    } else if (this.mode === 'route') {
      this.createRoute();
    }
  }

  // Envia a rota ao backend
  createRoute(): void {
    if (!this.dataRoute.name || this.routeCoordinates.length === 0) {
      this.openPopUpResponseReport('Por favor, preencha todos os campos e faça o desneho da rota.', false);
      return;
    }

    const body: CreateRouteRequest = {
      name: this.dataRoute.name,
      description: this.dataRoute.description,
      folderPathAtt: this.dataRoute.folderPathAtt,
      color: this.dataRoute.color,
      coordinates: this.routeCoordinates.map((coord, index) => ({
        lat: coord.lat,
        lng: coord.lng,
        sequenceNumber: index + 1
      }))
    };

    console.log('Enviando dados da rota:', body);
    // Inicia o estado de loading
    this.openPopUpResponseReport('Criando a rota...', false, true); 

    this.preventiveService.createRoute(body).subscribe({
      next: () => {
        this.openPopUpResponseReport('Rota criada com sucesso!', true);
        this.mapClosed.emit(); // Emite o evento de criação
      },
      error: (err) => {
        console.error('Erro ao criar a rota:', err);
        this.openPopUpResponseReport('Erro ao criar a rota.', false);
      }
    });
  }  

  // CRIAR ESTAÇÃO
  private createStation(): void {
    if (!this.dataForm.acronym || !this.dataForm.building || !this.dataForm.coordinate) {
      this.openPopUpResponseReport("Preencha todos os campos obrigatórios!", false);
      console.log(this.dataForm);
      return;
    }
    
    const body = {
      data: [
        {
          acronym: this.dataForm.acronym,
          building: this.dataForm.building,
          coordinate: this.dataForm.coordinate,
          type: this.dataForm.type,
          classification: this.dataForm.classification,
          family: this.dataForm.family
        }
      ]
    };       
    
    this.openPopUpResponseReport('Criando estação...', false, true); 
    
    this.preventiveService.creatStation(body).subscribe({
      next: () => {        
        this.openPopUpResponseReport("Estação criada com sucesso!", true);
        this.mapClosed.emit();
      },
      error: (err) => {
        // console.error('Erro ao criar estação:', err);        
        this.openPopUpResponseReport("Erro ao criar estação!", false);
      }
    });
  }  

  // Método para abrir notificações
  openPopUpResponseReport(message: string, isSuccess: boolean, isLoading: boolean = false): void {
    const panelClass = isLoading 
      ? 'loading-bar-container' 
      : (isSuccess ? 'success-bar-container' : 'failure-bar-container');
  
    const snackBarSettings: MatSnackBarConfig = {
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: panelClass,
      duration: isLoading ? undefined : 6000, // Sem duração fixa para o loading
    };
  
    this.snackBar.open(message, "Fechar", snackBarSettings);
  }

  toggleDrawingMode(): void {
    this.isDrawing = !this.isDrawing;
    this.changeMousePointer(this.isDrawing ? 'crosshair' : 'default');
  }

  private changeMousePointer(cursorStyle: string): void {
    if (this.map) {
      this.map.setOptions({ draggableCursor: cursorStyle });
    }
  }

  closeMap() {
    this.mapClosed.emit();
  }

  onCancel(): void {
    this.mapClosed.emit();
  }
}
