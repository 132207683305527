<div 
  class="widget-chart widget-chart2 text-start mb-1 card-top-border card-shadow-primary card"
>
  <div class="widget-chat-wrapper-outer">
    <div class="widget-chart-content">
      <div class="widget-numbers mb-0 w-100 icon-container">
        <span class="icon-wrapper">
          <fa-icon
            [icon]="icon"
            size="4x"
          >
          </fa-icon>
        </span>
      </div>
    </div>
  </div>
</div>
