<div>
  <div class="form-header">
    <fa-icon
      [icon]="faBrush"
      class="icon-brush"
      size="5x"
    >
    </fa-icon>

    <fa-icon
      [icon]="faTimesCircle"
      class="icon-close"
      size="2x"
      (click)="close()"
    >
    </fa-icon>
  </div>
  <div class="form-content">
    <form method="post" [formGroup]="form" (submit)="onSubmit()">   
      <div class="form-content-input">
        <span class="form-content-span">
          Título
        </span>
        <input
          id="title"
          name="Título"
          class="form-control"
          formControlName="title"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('title')?.errors && form.get('title')?.touched">
          Título é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Cor Primária
        </span>
        <input
          type="color"
          id="primaryColor"
          class="form-control"
          formControlName="primaryColor"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('primaryColor')?.errors && form.get('primaryColor')?.touched">
          Cor Primária é obrigatória
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Cor Secundária
        </span>
        <input
          type="color"
          id="secondaryColor"
          class="form-control"
          formControlName="secondaryColor"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('secondaryColor')?.errors && form.get('secondaryColor')?.touched">
          Cor Secundária é obrigatória
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Logo
        </span>
        <input
          type="file"
          accept="image/*"
          class="form-control"
          (change)="onFileChange($event)"
        >
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('logo')?.errors && form.get('logo')?.touched">
          Logo é obrigatória
        </p>
      </div>

      <div  
        *ngIf="logoPreview"
        class="image-preview"
        style="display: flex; align-items: center; justify-content: center;"
      >
        <img  
          [src]="logoPreview"
          alt="Preview logo"
          style="max-width: 200px; max-height: 200px;"
        >
      </div>

      <div class="form-content-submit">
        <button 
          type="submit"
          class="btn btn-primary mx-auto col-lg-5 col-md-5 col-5"
          [disabled]="!form.valid"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</div>