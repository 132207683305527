<div class="section-margin">
  
  <div class="row">

    <div *ngIf="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  
    <div *ngIf="error" class="alert alert-danger" role="alert">
      Ocorreu um erro ao carregar os usuários.
    </div>
    
    <div *ngIf="!loading && !error" class="custom-card" style="height: 78vh">
      <div class="custom-card-header">
        <h5 class="header-title">Listagem de acessos por usuário</h5>
        <input
          type="text"
          class="form-control custom-search-input"
          placeholder="Buscar usuário"
          [(ngModel)]="searchTerm"
          (ngModelChange)="applyFilter()"
        />
      </div>

      <div class="custom-card-body table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
          <thead>
          <tr>
              <th class="text-center text-nowrap">Nome</th>
              <th class="text-center text-nowrap">Role</th>
              <th class="text-center text-nowrap">Permissões</th>
              <th class="text-center text-nowrap">E-mail</th>
              <th class="text-center text-nowrap">Última visita</th>
              <th class="text-center text-nowrap">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of (filteredData | async)">
              <td class="text-center row-table-text">{{ user.fullName }}</td>
              <td class="text-center row-table-text">{{ user.role }}</td>
              <td class="text-center row-table-text">
                <span 
                  *ngFor="let permission of treatPermission(user.permissions); let i = index" 
                  class="custom-badge success"
                  [attr.data-index]="i">
                  {{ permission }}
                </span>
              </td>                      
              <td class="text-center row-table-text">{{ user.email }}</td>
              <td class="text-center row-table-text">{{ user.lastSignIn | date:'dd/MM/yyyy HH:mm:ss' }}</td>
              <td class="text-center row-table-text" style="display: flex; justify-content: space-around">
                <button class="btn" (click)="onEdit(user.id)">
                  <fa-icon [icon]="icons.userEdit"></fa-icon>
                </button>
                <button class="btn" (click)="onDelete(user.id)">
                  <fa-icon [icon]="icons.trash"></fa-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
