<div class="modal-error">
  <div class="modal-content">
    <div class="header-modal">
      <div class="icon-box">
        <i class="material-icons fa fa-times"></i>
      </div>				
      <h4 class="modal-title">Tente Novamente</h4>	
    </div>
    <div class="modal-body">
      <p>{{ messages[statusCode] }}</p>
    </div>
    <div class="footer-modal">
      <button class="btn btn-danger btn-block" 
      (click)="close('Save click')" >OK</button>
    </div>
  </div>
</div>