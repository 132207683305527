import { Component, OnInit, Input } from '@angular/core';
import { LegendItem, LegendType } from 'src/app/interfaces/models/ILegendItem';

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
  styleUrls: []
})
export class LegendItemComponent<T> implements OnInit {

  @Input() legend: LegendItem<T>;

  private mappedType: Record<LegendType, string> = {
    [LegendType.CIRCLE]: 'circle-symbol-legend',
    [LegendType.TRIANGLE_DEFAULT]: 'triangle-symbol-legend',
    [LegendType.TRIANGLE_LIGHT]: 'triangle-light-symbol-legend',
    [LegendType.TRIANGLE_YELLOW]: 'triangle-yellow-symbol-legend'
  }

  private mappedColor: Record<string, string> = {
    'red': 'red-background-color',
    'black': 'black-background-color',
    'green': 'green-background-color',
    'light-green': 'light-green-background-color',
    'yellow': 'yellow-background-color'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
