<div 
  class="row mx-auto pb-4"
>
  <!-- style="margin-bottom: 2vh;" -->
  <div class="col-12">
    <app-analytical-bar-line-stacked 
      [data]="data.value"
      [loading]="loading"
      [error]="error"
      [colors]="colors" 
      [width]="85"
      [barColors]="barColors"
      (filterClick)="onChange($event)"
    >
    </app-analytical-bar-line-stacked>
  </div>
</div>

<div class="row mx-auto pb-4">
  <!-- style="margin-bottom: 2vh;" -->
  <div class="col-12">
    <div class="row">
      <div class="col-7">
        <app-analytic-bar-horizontal
          key="group"
          [data]="data.value.analyticalOffendersByGroup"
          [activeFilters]="activeFilters"
          [loading]="loading"
          [error]="error"
          [colors]="colors"          
          [isLabelUppercase]="true"
          [height]="18"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>

      <div class="col-5">
        <app-analytic-bar-horizontal
          key="state"
          [data]="data.value.analyticalOffendersByState"
          [loading]="loading"
          [error]="error"
          [colors]="colors"     
          [isLabelUppercase]="true"
          [height]="18"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>      
    </div>
  </div> 
</div>

<div class="row mx-auto pb-4">
  <!-- style="margin-bottom: 2vh;" -->
  <div class="col-12">
    <div class="row">

      <div class="col-5">
        <app-analytic-bar-horizontal
          key="city"
          [data]="data.value.analyticalOffendersByCity"
          [loading]="loading"
          [error]="error"
          [colors]="colors"       
          [isLabelUppercase]="true" 
          [format]="formatCityName"
          [height]="18"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>

      <div class="col-7">
        <app-analytic-bar-horizontal
          key="district"
          [data]="data.value.analyticalOffendersByDistrict"
          [loading]="loading"
          [error]="error"
          [colors]="colors"       
          [isLabelUppercase]="true"           
          [height]="18"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>
    </div>
  </div> 
</div>

<div
  class="row mx-auto pb-4"
>
  <!-- style="margin-bottom: 2vh;" -->
  <div class="col-12">
   <app-analytical-cascate-bar
      [data]="data.value.analyticalProjectionCurrentMonth"
      [loading]="loading"
      [error]="error"
      [colors]="colors"
      [width]="160"
      (filterClick)="onChange($event)"
    >
    </app-analytical-cascate-bar>
  </div>
</div>
