<div 
  class="widget-chart widget-chart2 text-start mb-1 card-top-border card-shadow-primary border-primary card"
>
  <div class="widget-chat-wrapper-outer">
    <div class="widget-chart-content">
      <div class="title-card text-uppercase title-card-style">
        <!-- Título do Tema -->
        <span style="width: 90%; margin-left: 0.5vw; margin-right: 0.5vw;">{{ theme.title }}</span>
        
        <!-- Botão de rádio para seleção -->
        <input 
          style="width: 10%; align-self: center;" 
          type="radio" 
          [checked]="theme.isSelected" 
          (click)="updateTheme(theme)"
        />
      </div>
      <div class="widget-numbers mb-0 w-100 icon-container">
        <span class="icon-wrapper">
          <fa-icon
            [icon]="faBrush"
            [ngStyle]="{ color: theme.primaryColor }"
            size="5x"
          >
          </fa-icon>
        </span>
      </div>
      <div class="update-info">
        <!-- Informação da última atualização -->
        <span class="update-text">
          Última atualização: {{ theme.lastChange }}
        </span>
        <!-- Botão para apagar o tema -->
        <button 
          type="button" 
          class="btn-delete-theme" 
          (click)="deleteTheme(theme)"
        >
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
