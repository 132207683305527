import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { ChartInput, IColors } from 'src/app/interfaces';

@Component({
  selector: 'app-call-comparision-bar-line-chart',
  templateUrl: './call-comparision-bar-line-chart.component.html',
  styleUrls: []
})
export class CallComparisionBarLineChartComponent implements OnInit, OnChanges {

  title="ABERTURA DE CHAMADOS vs QTDE PEAD'S"
  
  @Input() data: ChartInput;
  @Input() colors: IColors;

  barChartData; 
  type: ChartType = 'bar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,    
    layout: {
      padding: {
        top: 0,    // Margem superior
        left: -5,   // Margem esquerda
        right: -5,  // Margem direita
        bottom: 5 // Margem inferior
      },      
    },
    scales: 
    { 
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
        }
      }], 
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false
          },
        },
        {
          id: 'y1',
          type: 'linear',
          position: 'right',
          ticks: {
            display: false,
          },
          gridLines: {
            display: false
          }
        }
      ] 
    },    
    tooltips:{ 
      mode: 'index',
      intersect: false,     
      callbacks:{
        label: function(tooltipItem, data){                      
          let labelsArray = [];
          let dataset1 = data.datasets[0];
          let dataset2 = data.datasets[1];
        
          if (tooltipItem.datasetIndex === 0) {
            // Quando o mouse está sobre o dataset 1, mostra a quantidade de chamados
            labelsArray.push(`qtde de chamados: ${dataset1.data[tooltipItem.index]}`);
          } else {
            // Quando o mouse está sobre o dataset 2, mostra a quantidade de pad's
            labelsArray.push(`qtde de pead's: ${dataset2.data[tooltipItem.index]}`);
          }
          
          return labelsArray  
        }
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        padding: {
          top: -5,  // Reduzir ou remover o padding pode ajudar a posicionar melhor as labels
          bottom: 0
        },
        color: 'black',  // Mudar para uma cor que contraste melhor, se necessário
        font: {
          weight: 'bold',
          size: 11  // Aumentar um pouco para melhorar a legibilidade
        },
      }
    }
  }; 

  constructor() {
  }

  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.createBarLineChart();
  }

  createBarLineChart() {
    this.barChartData = {
      datasets: [
        {
          label: 'qtde de chamados',
          data: this.data.dataset[0] ?? [],
          type: 'bar',
          order: 2,
          borderColor: this.colors.primaryColor,
          backgroundColor: 'black'
        },
        {
          label: 'qtde de peads',
          data: this.data.dataset[1] ?? [],
          borderColor: this.colors.secondaryColor,
          type: 'line',
          yAxisID: 'y1',
          tension: 0,
          order: 1,
          fill: this.colors.secondaryColor,
          backgroundColor: 'red'
      }],
      labels: this.data.labels
    }
  };
}
