<div class="modal-error">
  <div class="modal-content">
    <div class="header-modal">
      <div class="icon-box">
        <i class="material-icons fa fa-times"></i>
      </div>				
      <h4 class="modal-title">Tente Novamente</h4>	
    </div>
    <div class="modal-body">
      <p>Oops, parece que a senha que você inseriu está incorreta. Por favor, verifique e tente novamente.</p>
    </div>
    <div class="footer-modal">
      <button class="btn btn-danger btn-block" 
      (click)="close('Save click')">OK</button>
    </div>
  </div>
</div>