import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-response-forgot-password-pop-up',
  templateUrl: './response-forgot-password-pop-up.component.html',
  styleUrls: []
})
export class ResponseForgotPasswordPopUpComponent implements OnInit {

  @Input() statusCode: string;

  constructor(public activeModal: NgbActiveModal) { }

  messages = {
    '404': 'Usuário não encontrado.',
    '500': 'Erro interno no servidor.',
  }

  ngOnInit(): void {
  }

  close(result: any) {
    this.activeModal.close(result);
  }
}
