import { Component, OnInit } from '@angular/core';
import { CreateUserModalComponent } from './components/create-user-modal/create-user-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { ImportUsersModalComponent } from './components/import-users-modal/import-users-modal.component';
import { BehaviorSubject } from 'rxjs';
import { IUser } from 'src/app/interfaces';
import { ExportUsersModalComponent } from './components/export-users-modal/export-users-modal.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  data: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  filteredData: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  loading: boolean = true;
  error: boolean = false;

  constructor(
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  addUser() {
    const modalRef = this.modalService.open(CreateUserModalComponent, { windowClass: 'custom-modal-size' });
    modalRef.result.then(
      (result) => {
        if (result === 'created') {
          this.fetchData();
        }
      }
    );
  }

  fetchData() {
    this.loading = true;
    return this.userService.getUsersAll()
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        this.userService.updateUsers(response);
        this.data.next(response);
        this.filteredData.next(response);
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  openImportUsersModal(event) {
    this.modalService.open(ImportUsersModalComponent);
  }

  openExportUsersModal(event) {
    this.modalService.open(ExportUsersModalComponent);
  }
}
