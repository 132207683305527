import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-zoom-image',
  templateUrl: './modal-zoom-image.component.html',
  styleUrls: []
})
export class ModalZoomImageComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() imageUrl: string = '';
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }
  
  close() {
    this.closeModal.emit();
  }
  ngOnInit(): void {
  }

}
