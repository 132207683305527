import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { IActivity } from '../interfaces/models/IActivity';
import { defaultActiveFilters } from '../interfaces';

interface ActivityResponse { headers: { 'Access-Control-Allow-Methods' }, body: IActivity };

export interface ResourcePosition {
  totalResults?: number,
  time?: number,
  activityId: number,
  activityStatus: string,
  lng: number,
  lat: number,
  s?: string;
}

export interface ActivityFilters {
  family: string,
  state: string,
  activityId?: string
}

interface FilterButton {
  show: string,
  value: string
}

export interface DropdownFilters {
  family: FilterButton[],
  net: FilterButton[],
  states: FilterButton[]
}

export const family = [
  { show: 'empresarial', value: 'EMPRESARIAL' },
  { show: 'residencial', value: 'RESIDENCIAL' },
]

export const states = [
  { show: 'AC', value: 'AC' },
  { show: 'AL', value: 'AL' },
  { show: 'AP', value: 'AP' },
  { show: 'AM', value: 'AM' },
  { show: 'BA', value: 'BA' },
  { show: 'CE', value: 'CE' },
  { show: 'DF', value: 'DF' },
  { show: 'ES', value: 'ES' },
  { show: 'GO', value: 'GO' },
  { show: 'MA', value: 'MA' },
  { show: 'MT', value: 'MT' },
  { show: 'MS', value: 'MS' },
  { show: 'MG', value: 'MG' },
  { show: 'PA', value: 'PA' },
  { show: 'PB', value: 'PB' },
  { show: 'PR', value: 'PR' },
  { show: 'PE', value: 'PE' },
  { show: 'PI', value: 'PI' },
  { show: 'RJ', value: 'RJ' },
  { show: 'RN', value: 'RN' },
  { show: 'RS', value: 'RS' },
  { show: 'RO', value: 'RO' },
  { show: 'RR', value: 'RR' },
  { show: 'SC', value: 'SC' },
  { show: 'SP', value: 'SP' },
  { show: 'SE', value: 'SE' },
  { show: 'TO', value: 'TO' }
];

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  private readonly baseUrl: string;
  private readonly baseJson: string;

  private activities: BehaviorSubject<IActivity[]> = new BehaviorSubject([]); // TODAS AS ATIVIDADES
  public filteredActivities: BehaviorSubject<IActivity[]> = new BehaviorSubject([]); // ATIVIDADES FILTRADAS

  private resourcePositions: BehaviorSubject<ResourcePosition[]> = new BehaviorSubject([]);
  public filteredResourcePositions: BehaviorSubject<ResourcePosition[]> = new BehaviorSubject([]);

  isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingError: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private activeFilters: ActivityFilters = this.loadFiltersFromStorage() || defaultActiveFilters;

  private markersMap: Map<number, ResourcePosition> = new Map();
  private tooltipsState: Map<number, boolean> = new Map(); // Mantém o estado dos tooltips

  constructor(private httpClient: HttpClient) {
    this.baseUrl = "https://www.virtsel-fidere-api-homol.com.br:8671" 
    // this.baseUrl = "http://localhost:8671"
    this.baseJson = "assets/data/activities.json" 
    this.init();
  }  

  loadActivities() {
    return this.httpClient.get<IActivity[]>(this.baseUrl + "/api/activities/atividades", { withCredentials: true })
      .pipe(
        tap((data) => {          
          // TODAS AS ATIVIDADES RECEBIDAS
          this.activities.next(data); 
          this.isLoaded.next(true); 
          this.loadingError.next(false);
          this.updateResourcePositions();
          this.applyStoredFilters();     
        }),
        catchError((error) => {
          this.isLoaded.next(false);
          this.loadingError.next(true);
          return []; // Handle error and continua
        })
      );
  }

  init() {    
    timer(0, 60000) // start immediately, repeat every 1 minute
      .pipe(switchMap(() => this.loadActivities()))
      .subscribe();
  }

   // APLICA OS FILTROS SALVOS
  applyStoredFilters() {
    const filters = this.loadFiltersFromStorage();
    console.log('Filtros encontrados antes da aplicação:', filters);
    if (filters) {
      this.filterActivities(filters);
    }    
  }

  filterActivities(activeFilters: ActivityFilters) {
    this.activeFilters = activeFilters;
    this.saveFiltersToStorage(activeFilters);

    if (!this.isLoaded.value) return;

    // console.log('Aplicando filtros:', activeFilters);

    if (!activeFilters.family && !activeFilters.state && !activeFilters.activityId) {
      // console.log('Nenhum filtro aplicado. Exibindo todas as atividades.');
      this.showAllActivities();
      return;
    }

    const familyReference = activeFilters.family === '' ? family.map((f) => f.value).join(',') : activeFilters.family;
    const stateReference = activeFilters.state === '' ? states.map((f) => f.value).join(',') : activeFilters.state;

    const filteredActivities = this.activities.value.filter((activity) => {
      const stateValid = stateReference.split(',').includes((activity.stateProvince ?? 'N/A').toUpperCase());
      const familyValid = familyReference.includes((activity.xaProdCat1 ?? 'N/A').toUpperCase());
      const idValid = !activeFilters.activityId || String(activity.activityId).includes(activeFilters.activityId);
      return stateValid && familyValid && idValid;
    });

    // console.log('Atividades filtradas:', filteredActivities.length);
    this.filteredActivities.next(filteredActivities);
  }

  private saveFiltersToStorage(filters: ActivityFilters) {
    localStorage.setItem('activeFilters', JSON.stringify(filters));
  }

  loadFiltersFromStorage(): ActivityFilters | null {
    const storedFilters = localStorage.getItem('activeFilters');
    return storedFilters ? JSON.parse(storedFilters) : null;
  }

  updateResourcePositions() {
    const activities = this.getActivities();
    const newResourcePositions = Array.isArray(activities) ? activities.map((activity) => {
      return {
        activityId: activity.activityId,
        activityStatus: activity.status,
        lng: activity?.position?.longitude || 0,
        lat: activity?.position?.latitude || 0
      };
    }) : [];

    const updatedPositions: ResourcePosition[] = [];
    const newMarkersMap: Map<number, ResourcePosition> = new Map();

    newResourcePositions.forEach((newPos) => {
      const existingPos = this.markersMap.get(newPos.activityId);
      if (!existingPos || existingPos.lng !== newPos.lng || existingPos.lat !== newPos.lat) {
        updatedPositions.push(newPos);
      }
      newMarkersMap.set(newPos.activityId, newPos);
    });

    this.resourcePositions.next(updatedPositions);
    this.markersMap = newMarkersMap;
  }

  getPositions(resourceId: string): Observable<{ items: ResourcePosition[] }> {
    return this.httpClient.get<{ items: ResourcePosition[] }>(`${this.baseUrl}/api/positions/position-history/${resourceId}`);
  }

  searchActivityById(idActivities: string): void {
    const activitiesFilteredIndex = this.activities.value.findIndex(act => String(act.activityId) === String(idActivities));
    if (activitiesFilteredIndex !== -1) {
      const activitiesFiltered = [this.activities.value[activitiesFilteredIndex]];
      this.filteredActivities.next(activitiesFiltered);
      console.log(activitiesFilteredIndex);
    }
  }

  showAllActivities(): void {
    this.filteredActivities.next(this.activities.value);
  }

  isActivitiesLoaded() {
    return this.isLoaded.value;
  }

  isLoadingError() {
    return this.loadingError.value;
  }

  getActivities() {
    return this.activities.value;
  }

  getResourcePositions() {
    return this.resourcePositions.value;
  }
}
