<div class="card-chart mb-3 card" style="height: 100%;">
  <div class="card-chart-header">
    <app-title-default [content]="title">
    </app-title-default>
  </div>

  <div class="card-body" style="height: 100%;">
    <div class="scroll-area" style="height: 100%;">
      <div class="scrollbar-container">
        <perfect-scrollbar [autoPropagation]="true">
          <div
            class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column"
          >
            <app-notification-line 
              *ngFor="let notification of notifications"
              [notification]="notification"
            >
            </app-notification-line>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>