<div class="p-0">
  <label
    [for]="title"
    class="title-label"
  >
    {{ title }}
    <ng-multiselect-dropdown
      #dropdown
      [id]="title"
      class="multi-select"
      style="font-size: 10px"
      placeholder="Selecione"
      [settings]="dropdownSettings"
      [data]="dropdownList"
      [(ngModel)]="selectedItems"
      [disabled]="isDisabled"
      (onSelect)="onChange()"
      (onDeSelect)="onChange()"
    >
    </ng-multiselect-dropdown>
  </label>
</div>
